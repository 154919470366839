import { AxiosResponse } from "axios";
import { ChargerConfiguration } from "../queries/models/ev-configuration/charger-configuration.model";
import { CreateCharger } from "../queries/models/ev-configuration/create-charger.model";
import { UpdateCharger } from "../queries/models/ev-configuration/update-charger.model";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class EvConfigurationService extends BaseService {
  async getChargers(installationId: number): Promise<ChargerConfiguration[]> {
    const url = `${this.endpointPath}/${installationId}/chargers`;

    return this.http.get<ChargerConfiguration[]>(url).then((res) => res.data);
  }

  async addCharger(
    installationId: number,
    model: CreateCharger
  ): Promise<ChargerConfiguration> {
    const url = `${this.endpointPath}/${installationId}/chargers`;

    return this.http
      .post<CreateCharger, AxiosResponse<ChargerConfiguration>>(url, model)
      .then((res) => res.data);
  }

  async updateCharger(
    installationId: number,
    model: UpdateCharger
  ): Promise<ChargerConfiguration> {
    const url = `${this.endpointPath}/${installationId}/chargers/${model.id}`;

    return this.http
      .put<UpdateCharger, AxiosResponse<ChargerConfiguration>>(url, model)
      .then((res) => res.data);
  }

  async deleteCharger(installationId: number, id: number): Promise<void> {
    const url = `${this.endpointPath}/${installationId}/chargers/${id}`;

    return this.http.delete(url).then((res) => res.data);
  }
}

export const evConfigurationService = new EvConfigurationService(
  API_ENDPOINTS.EV_CONFIGURATION
);
