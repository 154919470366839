import React from "react";

type Props = { children: React.ReactNode };
export function ControlCard({ children }: Props) {
  return (
    <div className="shadow-md m-3 p-2 rounded-3xl grow basis-0 min-w-fit sm:min-w-[300px]">
      {children}
    </div>
  );
}
