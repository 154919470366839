import classNames from "classnames";
import { Button } from "primereact/button";

export interface RoundedButtonProps {
  label?: string;
  icon?: string;
  highlighted?: boolean;
  disabled?: boolean;
  className?: string;
  onClick: () => void;
}
export function RoundedButton({
  label,
  icon,
  highlighted = false,
  disabled = false,
  className,
  onClick,
}: RoundedButtonProps) {
  return (
    <Button
      type="button"
      disabled={disabled}
      className={classNames(
        "p-button-rounded !mr-1",
        highlighted ? "" : "p-button-outlined",
        className
      )}
      onClick={() => onClick()}
      label={label}
      icon={icon}
    ></Button>
  );
}
