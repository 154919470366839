import { useTranslation } from "react-i18next";
import { ContentWithLabel } from "../components/ui/ContentWithLabel";
import { useInstallationSelector } from "../components/ui/InstallationContext";
import {
  useUserInstallationSettingsMutation,
  useUserInstallationSettingsQuery,
} from "../queries/user-installation-settings.query";
import { InputSwitch } from "primereact/inputswitch";
import { UserInstallationSettings } from "../queries/models/settings/user-installation-settings";
import { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useWindowSize } from "../hooks/use-window-size";
import { useToast } from "../components/ui/ToastContext";

export function Settings() {
  const { selectedInstallationId } = useInstallationSelector();

  const userInstallationSettingsQuery = useUserInstallationSettingsQuery(
    selectedInstallationId
  );

  const userInstallationSettingsMutation = useUserInstallationSettingsMutation(
    selectedInstallationId
  );

  const [settings, setSettings] = useState<UserInstallationSettings>();

  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const toast = useToast();

  useEffect(() => {
    if (userInstallationSettingsQuery.isSuccess) {
      setSettings(userInstallationSettingsQuery.data);
    }
  }, [
    userInstallationSettingsQuery.isSuccess,
    userInstallationSettingsQuery.data,
  ]);

  function setNotificationsEnabled(notificationsEnabled: boolean) {
    setSettings({
      ...settings,
      notificationsEnabled: notificationsEnabled,
    });
  }

  async function saveChanges() {
    if (settings) {
      try {
        await userInstallationSettingsMutation.mutateAsync(settings);
        toast.current?.show({
          severity: "success",
          detail: t("common.saveSuccessful"),
        });
      } catch (error: any) {
        console.error(error);
        toast.current?.show({
          severity: "error",
          detail: t(error.response?.data) ?? error.message,
        });
      }
    }
  }

  return (
    <div className="p-2">
      {settings && (
        <div>
          <div>
            <ContentWithLabel label={t("settings.notificationsEnabled") + ":"}>
              <div className="flex flex-row align-items-center">
                <span>{t("common.no")}</span>
                <InputSwitch
                  checked={settings.notificationsEnabled}
                  className="mx-2"
                  onChange={(e) => setNotificationsEnabled(Boolean(e.value))}
                ></InputSwitch>
                <span>{t("common.yes")}</span>
              </div>
            </ContentWithLabel>
          </div>
          <div>
            <Button
              className={classNames("p-button", !windowSize.sm && "shadow-md")}
              onClick={saveChanges}
            >
              {t("common.saveChanges")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
