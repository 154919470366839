import { useQuery } from "react-query";
import { overviewService } from "../services/overview.service";
import { OverviewData } from "./models/overview/overview-data.model";

async function getOverviewData(
  installationId: number,
  from: Date | null,
  to: Date | null
) {
  return await overviewService.getOverviewData(
    installationId,
    from?.toISOString() ?? null,
    to?.toISOString() ?? null
  );
}

export function useOverviewQuery(
  installationId: number,
  from: Date | null,
  to: Date | null
) {
  return useQuery<OverviewData>(["overview", installationId, from, to], () => {
    return getOverviewData(installationId, from, to);
  });
}
