import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { SolarPanelsConfiguration } from "../../../queries/models/configuration/solar-panels-configuration";
import { ContentWithLabel } from "../../ui/ContentWithLabel";
import { DeviceAttributeSelector } from "./DeviceAttributeSelector";

interface SolarPanelsConfigurationProps {
  installationId: number;
  configuration: SolarPanelsConfiguration;
  showName: boolean;
  onChange: (v: SolarPanelsConfiguration) => void;
}

export function SolarPanelsConfigurationForm({
  installationId,
  configuration,
  showName = false,
  onChange,
}: SolarPanelsConfigurationProps) {
  const { t } = useTranslation();
  const setConfigurationProperty: <T extends keyof SolarPanelsConfiguration>(
    property: T,
    value: SolarPanelsConfiguration[T]
  ) => void = (property, value) => {
    onChange({
      ...configuration!,
      [property]: value,
    });
  };

  return (
    <>
      {showName && (
        <ContentWithLabel label={t("common.name") + ":"}>
          <div className="p-inputgroup mb-3">
            <InputText
              value={configuration.name}
              onChange={(e) => setConfigurationProperty("name", e.target.value)}
            />
          </div>
        </ContentWithLabel>
      )}
      <ContentWithLabel label={t("configuration.coordinates") + ":"}>
        <div className="p-inputgroup mb-3">
          <InputNumber
            value={configuration.latitude}
            mode="decimal"
            maxFractionDigits={15}
            defaultValue={0}
            onChange={(e) => setConfigurationProperty("latitude", e.value!)}
          />
          <span className="p-inputgroup-addon">°N</span>
        </div>
        <div className="p-inputgroup mb-3">
          <InputNumber
            value={configuration.longitude}
            mode="decimal"
            maxFractionDigits={15}
            defaultValue={0}
            onChange={(e) => setConfigurationProperty("longitude", e.value!)}
          />
          <span className="p-inputgroup-addon">°E</span>
        </div>
      </ContentWithLabel>

      <ContentWithLabel
        label={`${t("configuration.azimuth")}  (0°-360° ${t(
          "common.clockwise"
        )}):`}
      >
        <div className="p-inputgroup mb-3">
          <InputNumber
            value={configuration.azimuth}
            min={0}
            max={360}
            defaultValue={0}
            onChange={(e) => setConfigurationProperty("azimuth", e.value!)}
          />
          <span className="p-inputgroup-addon">°</span>
        </div>
      </ContentWithLabel>

      <ContentWithLabel label={t("configuration.declination") + ":"}>
        <div className="p-inputgroup mb-3">
          <InputNumber
            value={configuration.declination}
            defaultValue={0}
            onChange={(e) => setConfigurationProperty("declination", e.value!)}
          />
          <span className="p-inputgroup-addon">°</span>
        </div>
      </ContentWithLabel>
      <ContentWithLabel label={t("configuration.nominalPower") + ":"}>
        <div className="p-inputgroup mb-3">
          <InputNumber
            value={configuration.nominalPower}
            defaultValue={0}
            onChange={(e) => setConfigurationProperty("nominalPower", e.value!)}
          />
          <span className="p-inputgroup-addon">W</span>
        </div>
      </ContentWithLabel>
      <ContentWithLabel
        label={t("configuration.solarPanelsForecastDeviceAttribute") + ":"}
      >
        <DeviceAttributeSelector
          installationId={installationId}
          selectedDeviceAttributeId={configuration.forecastDeviceAttributeId}
          onDeviceAttributeIdChange={(v) =>
            setConfigurationProperty("forecastDeviceAttributeId", v)
          }
        />
      </ContentWithLabel>

      <ContentWithLabel
        label={
          t("configuration.solarPanelsAdjustedForecastDeviceAttribute") + ":"
        }
      >
        <DeviceAttributeSelector
          installationId={installationId}
          selectedDeviceAttributeId={
            configuration.adjustedForecastDeviceAttributeId
          }
          onDeviceAttributeIdChange={(v) =>
            setConfigurationProperty("adjustedForecastDeviceAttributeId", v)
          }
          allowEmpty
        />
      </ContentWithLabel>
      <ContentWithLabel
        label={
          t("configuration.solarPanelsRealProductionDeviceAttribute") + ":"
        }
      >
        <DeviceAttributeSelector
          installationId={installationId}
          selectedDeviceAttributeId={configuration.productionDeviceAttributeId}
          onDeviceAttributeIdChange={(v) =>
            setConfigurationProperty("productionDeviceAttributeId", v)
          }
          allowEmpty
        />
      </ContentWithLabel>

      <ContentWithLabel label={t("configuration.maxDcLoaderCapacity") + ":"}>
        <div className="p-inputgroup mb-3">
          <InputNumber
            value={configuration.maxDcLoaderCapacityW}
            defaultValue={0}
            onChange={(e) =>
              setConfigurationProperty("maxDcLoaderCapacityW", e.value!)
            }
          />
          <span className="p-inputgroup-addon">W</span>
        </div>
      </ContentWithLabel>
      <ContentWithLabel label={t("configuration.dcPercentage") + ":"}>
        <div className="p-inputgroup mb-3">
          <InputNumber
            value={configuration.dcPercentage}
            defaultValue={0}
            onChange={(e) => setConfigurationProperty("dcPercentage", e.value!)}
          />
          <span className="p-inputgroup-addon">%</span>
        </div>
      </ContentWithLabel>
    </>
  );
}
