import { DataTable } from "primereact/datatable";
import { useInstallationSelector } from "../ui/InstallationContext";
import { Loader } from "../ui/Loader";
import { Column } from "primereact/column";
import { useUsersQuery } from "../../queries/charging-admin.query";

export function Users() {
  const { selectedInstallationId } = useInstallationSelector();
  const { data, isLoading } = useUsersQuery(selectedInstallationId);

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Loader spinner />
      </div>
    );
  }

  return (
    <div>
      {data && (
        <DataTable value={data}>
          <Column field="id" header="Id"></Column>
          <Column field="email" header="Email"></Column>
        </DataTable>
      )}
    </div>
  );
}
