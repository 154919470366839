import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Installation } from "../../queries/models/configuration/installation";
import { JsonInputTextarea } from "../ui/JsonInputTextarea";
import { RoundedButtonSelector } from "../ui/RoundedButtonSelector";
import { MqttBrokerConfiguration } from "../../queries/models/configuration/mqtt-broker-configuration";
import { MqttBrokerConfigurationForm } from "./MqttBrokerConfigurationForm";
import { Separator } from "../ui/Separator";
import { RoundedButton } from "../ui/RoundedButton";
import { MqttBrokerType } from "../../queries/models/configuration/mqtt-broker-type";
import { Button } from "primereact/button";

export interface InstallationEditFormProps {
  value: Installation;
  onSave: (v: Installation) => void;
}

export function InstallationEditForm({
  value,
  onSave,
}: InstallationEditFormProps) {
  const { t } = useTranslation();
  const [name, setName] = useState(value.name);

  const [batteryApproximation, setBatteryApproximation] = useState(
    value.batteryApproximation
  );
  const [mqttBrokerConfigurations, setMqttBrokerConfigurations] = useState<
    MqttBrokerConfiguration[]
  >([...value.mqttBrokerConfigurations]);
  const [launchDate, setLaunchDate] = useState<Date | null>(
    value.launchDate ? new Date(value.launchDate) : null
  );
  const [isEnabled, setIsEnabled] = useState(value.isEnabled);
  const [isErrorTrackingEnabled, setIsErrorTrackingEnabled] = useState(
    value.isErrorTrackingEnabled
  );
  const [enirisUsername, setEnirisUsername] = useState(value.enirisUsername);
  const [enirisPassword, setEnirisPassword] = useState(value.enirisPassword);
  const [enirisControllerId, setEnirisControllerId] = useState(
    value.enirisControllerId
  );

  const [
    selectedMqttBrokerConfigurationIndex,
    setSelectedMqttBrokerConfigurationIndex,
  ] = useState(value.mqttBrokerConfigurations.length > 0 ? 0 : -1);

  function setSelectedMqttBrokerConfiguration(
    configuration: MqttBrokerConfiguration,
    index: number
  ) {
    mqttBrokerConfigurations[index] = configuration;
    setMqttBrokerConfigurations([...mqttBrokerConfigurations]);
    setSelectedMqttBrokerConfigurationIndex(index);
  }

  function addMqttBrokerConfiguration() {
    setSelectedMqttBrokerConfigurationIndex(mqttBrokerConfigurations.length);

    setMqttBrokerConfigurations([
      ...mqttBrokerConfigurations,
      {
        type: MqttBrokerType.Custom,
        gatewayStaticHost: "",
        gatewayId: "",
        gatewayUsername: "",
        gatewayPassword: "",
      } as MqttBrokerConfiguration,
    ]);
  }

  function deleteMqttBrokerConfiguration(index: number) {
    mqttBrokerConfigurations.splice(index, 1);
    setMqttBrokerConfigurations([...mqttBrokerConfigurations]);

    setSelectedMqttBrokerConfigurationIndex((x) => x - 1);
  }

  return (
    <div className="flex flex-col grey-inputs" style={{ width: 600 }}>
      <label className="mt-2">{t("common.name")}</label>
      <InputText value={name} onChange={(v) => setName(v.target.value)} />

      <label className="mt-2">Eniris username</label>
      <InputText
        value={enirisUsername}
        onChange={(v) => setEnirisUsername(v.target.value)}
      />

      <label className="mt-2">Eniris password</label>
      <InputText
        type="password"
        value={enirisPassword}
        onChange={(v) => setEnirisPassword(v.target.value)}
      />

      <label className="mt-2">Eniris controller ID</label>
      <InputText
        value={enirisControllerId}
        onChange={(v) => setEnirisControllerId(v.target.value)}
      />

      <div className="flex justify-center text-primary font-bold text-xl mt-3">
        {t("configuration.mqttBrokerConfigurations")}
      </div>

      <div className="flex justify-center my-2">
        <RoundedButtonSelector
          selectedIndex={selectedMqttBrokerConfigurationIndex}
          options={mqttBrokerConfigurations}
          onSelected={(i) => setSelectedMqttBrokerConfigurationIndex(i)}
          onAddClicked={addMqttBrokerConfiguration}
        />
      </div>

      {selectedMqttBrokerConfigurationIndex >= 0 && (
        <MqttBrokerConfigurationForm
          configuration={
            mqttBrokerConfigurations[selectedMqttBrokerConfigurationIndex]
          }
          onChange={(e) => {
            setSelectedMqttBrokerConfiguration(
              e,
              selectedMqttBrokerConfigurationIndex
            );
          }}
        />
      )}

      {mqttBrokerConfigurations.length > 0 && (
        <div className="flex justify-center">
          <RoundedButton
            icon="pi pi-trash"
            className="!my-2"
            onClick={() =>
              deleteMqttBrokerConfiguration(
                selectedMqttBrokerConfigurationIndex
              )
            }
          />
        </div>
      )}

      <div className="my-2">
        <Separator />
      </div>
      <label className="mt-2">{t("configuration.batteryApproximation")}</label>
      <JsonInputTextarea
        rows={10}
        value={batteryApproximation}
        className="!text-left pl-2"
        onChange={(v) => setBatteryApproximation(v.target.value)}
      />

      <label className="mt-2">{t("configuration.launchDate")}</label>
      <Calendar
        showTime
        value={launchDate ?? null}
        selectionMode="single"
        onChange={(v) => setLaunchDate((v.target.value as Date) ?? null)}
      />

      <label className="mt-2">{t("common.enabled")}</label>
      <InputSwitch
        checked={isEnabled}
        onChange={(v) => setIsEnabled(Boolean(v.target.value))}
      />

      <label className="mt-2">{t("common.errorTracking")}</label>
      <InputSwitch
        checked={isErrorTrackingEnabled}
        onChange={(v) => setIsErrorTrackingEnabled(Boolean(v.target.value))}
      />

      <Button
        label={t("common.save")}
        className="p-button p-button-success !mt-2"
        onClick={() => {
          onSave({
            ...value,
            name,
            batteryApproximation,
            mqttBrokerConfigurations,
            isEnabled,
            isErrorTrackingEnabled,
            launchDate,
            enirisUsername,
            enirisPassword,
            enirisControllerId,
          });
        }}
      />
    </div>
  );
}
