import { useCallback, useEffect, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import {
  EvChargingSessionPlan,
  EvChargingSessionPlanNew,
} from "../../queries/models/ev/ev-charging-session-plan";
import { EvChargingSession } from "../../queries/models/ev/ev-charging-session";
import { ChargingPlanTypes, EvChargingPlanForm } from "./EvChargingPlanForm";
import {
  useCreateEvChargingSessionPlanMutation,
  useDeleteEvChargingSessionPlanMutation,
  useUpdateEvChargingSessionPlanMutation,
} from "../../queries/ev-charging.query";
import { useToast } from "../ui/ToastContext";
import { AxiosError } from "axios";
import { useQueryClient } from "react-query";

export type EvChargingSessionEvent =
  | EvChargingSession
  | EvChargingSessionPlan
  | EvChargingSessionPlanNew;

function isSessionInfo(
  event?: EvChargingSessionEvent
): event is EvChargingSession {
  return (event as EvChargingSession)?.currentKwh !== undefined;
}

export interface EvChargingPlanDialogProps {
  installationId: number;
  event?: EvChargingSessionEvent;
  isVisible: boolean;
  onHide(): void;
}

export function EvChargingPlanDialog({
  installationId,
  event,
  isVisible,
  onHide,
}: EvChargingPlanDialogProps) {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const createMutation = useCreateEvChargingSessionPlanMutation(installationId);
  const updateMutation = useUpdateEvChargingSessionPlanMutation(installationId);
  const deleteMutation = useDeleteEvChargingSessionPlanMutation(installationId);
  const toast = useToast();
  const onSuccess = useCallback(() => {
    queryClient.invalidateQueries("ev-charging-session-plans");
    onHide();
  }, [onHide, queryClient]);
  const onFail = useCallback(
    (err: AxiosError) => {
      toast.current?.show({
        severity: "error",
        detail: (err.response?.data as string) ?? err.message,
      });
    },
    [toast]
  );

  const [newData, setNewData] = useState(event);
  useEffect(() => setNewData(event), [event]);

  // only 1 of the following 4 consts is true - indicates type of dialog, asserts type for "newData"
  const thisIsSessionInfo = isSessionInfo(newData);
  const thisIsNewPlan = !thisIsSessionInfo && newData?.id === undefined;
  const thisIsEditPrediction =
    !thisIsNewPlan && !thisIsSessionInfo && newData.predicted;
  const thisIsEditPlan =
    !thisIsNewPlan && !thisIsSessionInfo && !newData.predicted;

  const headerName = {
    [+thisIsSessionInfo]: "ev.sessionInfo",
    [+thisIsNewPlan]: "ev.newSessionPlan",
    [+thisIsEditPrediction]: "ev.editSessionPrediction",
    [+thisIsEditPlan]: "ev.editSessionPlan",
  }[+true];

  if (!event || !installationId) return <></>;

  return (
    <Dialog
      visible={isVisible}
      header={t(headerName)}
      className="max-w-[550px] w-full absolute !max-h-full"
      contentClassName="!p-0"
      onHide={onHide}
    >
      {newData && (
        <div className="grid">
          <div className="mb-2 transform duration-150">
            {thisIsSessionInfo ? (
              <EvChargingPlanForm
                kind={ChargingPlanTypes.sessionInfo}
                data={newData}
              />
            ) : (
              <EvChargingPlanForm
                kind={ChargingPlanTypes.sessionPlan}
                data={newData}
                setData={setNewData}
              />
            )}
          </div>

          <div className="grid grid-flow-col auto-rows-max gap-2 mt-6 mb-6 mx-6">
            <Button
              label={t("common.cancel")}
              className="p-button-outlined"
              onClick={onHide}
            />
            {!thisIsSessionInfo && (
              <>
                {!thisIsNewPlan && (
                  <Button
                    label={t("common.delete")}
                    className="p-button-danger"
                    onClick={() => {
                      deleteMutation
                        .mutateAsync(newData.id)
                        .then(onSuccess, onFail);
                    }}
                  />
                )}
                <Button
                  label={t("common.save")}
                  onClick={() => {
                    if (thisIsNewPlan) {
                      createMutation
                        .mutateAsync(newData)
                        .then(onSuccess, onFail);
                    } else {
                      // predictions updated by user are no longer predictions
                      updateMutation
                        .mutateAsync({ ...newData, predicted: false })
                        .then(onSuccess, onFail);
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
      )}
    </Dialog>
  );
}
