import {
  useLanguageTranslationsQuery,
  useSetTranslationMutation,
} from "../../queries/translations.query";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { useMemo, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { TranslationKeyValue } from "../../queries/models/translations/translation-key-value.model";
import { TranslationsStatistics } from "./TranslationsStatistics";
import { TranslationKeyType } from "../../queries/models/translations/translation-key-type.enum";
import { SelectButton } from "primereact/selectbutton";
import { SelectItem } from "primereact/selectitem";
import { InputSwitch } from "primereact/inputswitch";
import { SetTranslation } from "../../queries/models/translations/set-translation.model";
import { useWindowSize } from "../../hooks/use-window-size";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { BulkTranslationsEditor } from "./BulkTranslationsEditor";
import { Language } from "../../queries/models/translations/language.model";
import { ShowFor } from "../ui/ShowFor";

const translationTypes: SelectItem[] = [
  { label: "Web", value: TranslationKeyType.Web },
  { label: "Backend", value: TranslationKeyType.Backend },
];

export interface TranslationsEditorProps {
  language: Language;
}

export function TranslationsEditor({ language }: TranslationsEditorProps) {
  const { t } = useTranslation();
  const windowsize = useWindowSize();

  const translationsQuery = useLanguageTranslationsQuery(language.id);
  const translationMutation = useSetTranslationMutation();

  const queryClient = useQueryClient();

  const [displayDialog, setDisplayDialog] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<TranslationKeyValue>();
  const [selectedTypes, setSelectedTypes] = useState<number[]>([]);
  const [showOnlyMissing, setShowOnlyMissing] = useState<boolean>(false);
  const [showBulkUpdateEditor, setShowBulkUpdateEditor] =
    useState<boolean>(false);

  const translations: TranslationKeyValue[] = useMemo(() => {
    let result: TranslationKeyValue[] = [];
    if (translationsQuery.data === undefined) return result;

    if (!showOnlyMissing) result = translationsQuery.data;
    else result = translationsQuery.data.filter((x) => x.value === null);

    if (selectedTypes.length > 0) {
      result = result.filter((x) => selectedTypes.some((y) => y === x.type));
    }

    return result;
  }, [translationsQuery.data, showOnlyMissing, selectedTypes]);

  function onEdit() {
    if (dialogData === undefined) return;
    if (!dialogData.value) return;

    translationMutation
      .mutateAsync({
        languageId: language.id,
        translationKeyId: dialogData.translationKeyId,
        value: dialogData.value,
      } as SetTranslation)
      .then(() => {
        const row = translations.find(
          (x) => x.translationKeyId === dialogData.translationKeyId
        );
        if (row) {
          row.previousValue = row.value;
          row.value = dialogData.value;
        }
        setDisplayDialog(false);
        queryClient.invalidateQueries("translations");
      });
  }

  return (
    <>
      {translationsQuery.isFetched && (
        <div className="grid grid-cols-2 p-2 justify-items-center align-items-center">
          <TranslationsStatistics translations={translations} />
          <div className="grid grid-rows-3">
            <ShowFor permission="TranslationsEdit">
              <div className="grid self-center justify-items-center">
                <Button
                  className="p-button-help"
                  label={t("translations.bulkUpdate")}
                  onClick={() => setShowBulkUpdateEditor(true)}
                />
                <Dialog
                  header={t("translations.bulkUpdate")}
                  visible={showBulkUpdateEditor}
                  onHide={() => setShowBulkUpdateEditor(false)}
                >
                  {translationsQuery.data && (
                    <BulkTranslationsEditor
                      language={language}
                      languageTranslations={translationsQuery.data}
                    />
                  )}
                </Dialog>
              </div>
            </ShowFor>
            <div className="grid self-end justify-items-center">
              <div className="text-center">{t("common.filterByType")}</div>
              <SelectButton
                value={selectedTypes}
                options={translationTypes}
                onChange={(e) => {
                  setSelectedTypes(e.value);
                }}
                multiple
              />
            </div>
            <div className="grid self-top justify-items-center mt-4">
              <div className="text-center">
                {t("translations.showOnlyMissing")}
                <br />
                <InputSwitch
                  checked={showOnlyMissing}
                  onChange={(e) => {
                    setShowOnlyMissing(Boolean(e.value));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={classNames(
          "transition duration-150 lg:col-span-2",
          translationsQuery.isLoading && "blur-sm"
        )}
      >
        <div>
          <DataTable
            value={translations}
            dataKey="id"
            showGridlines
            virtualScrollerOptions={{
              itemSize: 50,
            }}
            scrollable
            scrollHeight={
              windowsize.lg ? "calc(100vh - 350px)" : "calc(100vh - 150px)"
            }
            cellClassName={() => "overflow-hidden"}
            filterDisplay="row"
            size="small"
            onRowClick={(e) => {
              setDialogData(e.data as TranslationKeyValue);
              setDisplayDialog(true);
            }}
            rowClassName={(x: TranslationKeyValue) => {
              return (
                classNames(
                  "cursor-pointer",
                  x.value !== null && "hover:!bg-gray-100",
                  x.value === null && "!bg-red-100 hover:!bg-red-200"
                ) ?? ""
              );
            }}
          >
            <Column
              header={t("translations.key")}
              field="translationKey"
              filter
              sortable
            />
            <Column
              header={t("translations.englishValue")}
              field="englishValue"
              filter
              sortable
            />
            <Column
              header={t("translations.previousValue")}
              field="previousValue"
              filter
              sortable
            />
            <Column header={t("common.value")} field="value" filter sortable />
          </DataTable>

          <Dialog
            header={t("common.edit")}
            visible={displayDialog}
            footer={
              <>
                <Button
                  className="p-button-outlined"
                  label={t("common.cancel")}
                  onClick={() => setDisplayDialog(false)}
                />
                <ShowFor permission="TranslationsEdit">
                  <Button label={t("common.edit")} onClick={onEdit} />
                </ShowFor>
              </>
            }
            onHide={() => setDisplayDialog(false)}
          >
            <div className="text-lg	leading-relaxed">
              <div className="mb-3">
                <b>{t("translations.key")}:</b> <br />
                {dialogData?.translationKey}
              </div>
              <div className="mb-3">
                <b>{t("translations.englishValue")}:</b> <br />
                {dialogData?.englishValue ?? "-"}
              </div>
              <div className="mb-3">
                <b>{t("translations.previousValue")}:</b> <br />
                {dialogData?.previousValue ?? "-"}
              </div>
              <div>
                <b>{t("common.value")}:</b>
                <InputText
                  className="shadow-sm w-full"
                  value={dialogData?.value ?? ""}
                  onInput={(e) => {
                    setDialogData({
                      ...dialogData!,
                      value: e.currentTarget.value,
                    });
                  }}
                  onKeyDown={(e) => {
                    if (e.code === "Enter") onEdit();
                  }}
                  autoFocus={true}
                />
              </div>
            </div>
          </Dialog>
        </div>
      </div>
    </>
  );
}
