import { AxiosResponse } from "axios";
import { Language } from "../queries/models/translations/language.model";
import { SetTranslationWithKey } from "../queries/models/translations/set-translation-with-key.model";
import { SetTranslation } from "../queries/models/translations/set-translation.model";
import { TranslationKeyValue } from "../queries/models/translations/translation-key-value.model";
import { TranslationKey } from "../queries/models/translations/translation-key.model";
import { TranslationValue } from "../queries/models/translations/translation-value.model";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class TranslationsService extends BaseService {
  async getAllLanguages(): Promise<Language[]> {
    const url = `${this.endpointPath}/languages`;
    return this.http.get<Language[]>(url).then((res) => res.data);
  }

  async getSelectedLanguages(): Promise<Language[]> {
    const url = `${this.endpointPath}/languages/selected`;
    return this.http.get<Language[]>(url).then((res) => res.data);
  }

  async getPublishedLanguages(): Promise<Language[]> {
    const url = `${this.endpointPath}/languages/published`;
    return this.http.get<Language[]>(url).then((res) => res.data);
  }

  async selectSingleLanguage(id: number): Promise<AxiosResponse> {
    const url = `${this.endpointPath}/languages/${id}/select`;
    return this.http.get<void>(url);
  }

  async unselectSingleLanguage(id: number): Promise<AxiosResponse> {
    const url = `${this.endpointPath}/languages/${id}/unselect`;
    return this.http.get<void>(url);
  }

  async publishSingleLanguage(id: number): Promise<AxiosResponse> {
    const url = `${this.endpointPath}/languages/${id}/publish`;
    return this.http.get<void>(url);
  }

  async unpublishSingleLanguage(id: number): Promise<AxiosResponse> {
    const url = `${this.endpointPath}/languages/${id}/unpublish`;
    return this.http.get<void>(url);
  }

  async getAllTranslationKeys(): Promise<TranslationKey[]> {
    const url = `${this.endpointPath}/keys`;
    return this.http.get<TranslationKey[]>(url).then((res) => res.data);
  }

  async getAllTranslations(): Promise<TranslationValue[]> {
    const url = `${this.endpointPath}/translations`;
    return this.http.get<TranslationValue[]>(url).then((res) => res.data);
  }

  async getLanguageTranslations(
    languageId: number
  ): Promise<TranslationKeyValue[]> {
    const url = `${this.endpointPath}/translations/${languageId}`;
    return this.http.get<TranslationKeyValue[]>(url).then((res) => res.data);
  }

  async getCultureTranslations(
    culture: string
  ): Promise<TranslationKeyValue[]> {
    const url = `${this.endpointPath}/translations/${culture}`;
    return this.http.get<TranslationKeyValue[]>(url).then((res) => res.data);
  }

  async getCultureTranslationsJson(culture: string): Promise<string> {
    const url = `${this.endpointPath}/translations/json/${culture}`;
    return this.http.get<string>(url).then((res) => res.data);
  }

  async setTranslation(translation: SetTranslation): Promise<AxiosResponse> {
    const url = `${this.endpointPath}/translations`;
    return this.http.post<void>(url, translation);
  }

  async setTranslationWithKey(
    translationWithKey: SetTranslationWithKey
  ): Promise<AxiosResponse> {
    const url = `${this.endpointPath}/translations/with-key`;
    return this.http.post<void>(url, translationWithKey);
  }
}

export const translationsService = new TranslationsService(
  API_ENDPOINTS.TRANSLATIONS
);
