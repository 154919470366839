import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/number-utils";
import { OverviewCard } from "./OverviewCard";
import { TooltipButton } from "../ui/TooltipButton";

interface OverviewExpertPeakShavingProps {
  costOfGridPower: number;
  costOfConsumption: number;
}

export default function OverviewExpertPeakShaving({
  costOfGridPower,
  costOfConsumption,
}: OverviewExpertPeakShavingProps) {
  const { t } = useTranslation();
  return (
    <>
      <OverviewCard>
        <div className="flex flex-col h-full relative">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col items-start pr-3">
              <h2 className="text-lg font-semibold text-gray-600">
                {t("overview.peakShavingSavings")}
              </h2>
              <p className="font-semibold pr-2 text-2xl text-price-green">
                {formatNumber(costOfConsumption - costOfGridPower, 2, 2)} €
              </p>
            </div>

            <div className="flex flex-col items-start">
              <h2 className="text-lg font-semibold text-gray-600">
                {t("overview.capacityTariffCost")}
              </h2>
              <div>
                <h2 className="text-base text-gray-600">
                  {t("overview.withoutInstallation")}
                </h2>
                <p className="font-semibold pr-2 text-2xl text-price-red">
                  {formatNumber(costOfConsumption, 2, 2)} €
                </p>
              </div>
              <div className="pt-2">
                <h2 className="text-base text-gray-600">
                  {t("overview.withInstallation")}
                </h2>
                <p className="font-semibold pr-2 text-2xl text-price-green">
                  {formatNumber(costOfGridPower, 2, 2)} €
                </p>
              </div>
              <TooltipButton
                tooltipsMessage={t("overview.peakShavingTooltip")}
              ></TooltipButton>
            </div>
          </div>
        </div>
      </OverviewCard>
    </>
  );
}
