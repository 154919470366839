export function commaSeparate(value: any[], property?: string): string {
  if (!value) {
    return "";
  } else {
    if (!property) {
      return value.join(", ");
    } else {
      return commaSeparate(value.map((v) => v[property]));
    }
  }
}
