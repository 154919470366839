import { useTranslation } from "react-i18next";
import {
  EvChargingSessionPlan,
  EvChargingSessionPlanNew,
} from "../../queries/models/ev/ev-charging-session-plan";
import { EvChargingSession } from "../../queries/models/ev/ev-charging-session";
import { InputNumber } from "primereact/inputnumber";
import { Calendar, CalendarChangeEvent } from "primereact/calendar";
import { useWindowSize } from "../../hooks/use-window-size";
import { ContentWithLabel } from "../ui/ContentWithLabel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBatteryQuarter,
  faGaugeHigh,
} from "@fortawesome/free-solid-svg-icons";
import { getCarName, getChargerName } from "../../utils/ev-utils";
import { useIOConfigurationQuery } from "../../queries/configuration.query";
import { useInstallationSelector } from "../ui/InstallationContext";
import { Dropdown } from "primereact/dropdown";
import { useMemo } from "react";
import { useChargersQuery } from "../../queries/ev-configuration.query";

export enum ChargingPlanTypes {
  sessionInfo = "sessionInfo",
  sessionPlan = "sessionPlan",
}

export type EvChargingPlanFormSessionInfoProps = {
  kind: ChargingPlanTypes.sessionInfo;
  data: EvChargingSession;
  setData?: undefined;
};

export type EvChargingPlanFormSessionPlanProps = {
  kind: ChargingPlanTypes.sessionPlan;
  data: EvChargingSessionPlan | EvChargingSessionPlanNew;
  setData(data: EvChargingSessionPlan | EvChargingSessionPlanNew): void;
};

export type EvChargingPlanFormProps =
  | EvChargingPlanFormSessionInfoProps
  | EvChargingPlanFormSessionPlanProps;

function calendarEventToDate(e: CalendarChangeEvent): Date | undefined {
  if (!e.value || Array.isArray(e.value)) return undefined;
  if (typeof e.value === "string") return new Date(e.value);
  return e.value;
}

export function EvChargingPlanForm({
  kind,
  data,
  setData,
}: EvChargingPlanFormProps) {
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const { selectedInstallationId } = useInstallationSelector();

  const configurationQuery = useIOConfigurationQuery(selectedInstallationId);
  const chargersQuery = useChargersQuery(selectedInstallationId);

  const carOptions = useMemo(() => {
    if (!configurationQuery.data) return [];
    return configurationQuery.data.evConfigurations.map((x) => ({
      label: x.name,
      value: x.id,
    }));
  }, [configurationQuery.data]);

  const chargerOptions = useMemo(() => {
    if (!chargersQuery.data) return [];
    return chargersQuery.data
      .filter((x) => x.isEnabled === true)
      .map((x) => ({
        label: x.name,
        value: x.id,
      }));
  }, [chargersQuery.data]);

  return (
    <>
      {configurationQuery.data!.evConfigurations.length > 1 && (
        <div className="min-w-min mt-4 mx-6 flex grid grid-cols-1 items-center">
          <ContentWithLabel label={t("ev.car")}>
            <>
              {kind === ChargingPlanTypes.sessionInfo && (
                <div className="p-inputgroup mb-2">
                  <span>
                    {getCarName(
                      data.carId,
                      configurationQuery.data!.evConfigurations
                    )}
                  </span>
                </div>
              )}

              {kind === ChargingPlanTypes.sessionPlan && (
                <div className="p-inputgroup mb-2">
                  <Dropdown
                    value={data.carId}
                    options={carOptions}
                    onChange={(e) =>
                      setData({
                        ...data,
                        carId: e.value,
                      })
                    }
                  />
                </div>
              )}
            </>
          </ContentWithLabel>
          <ContentWithLabel label={t("ev.charger")}>
            <>
              {kind === ChargingPlanTypes.sessionInfo && (
                <div className="p-inputgroup mb-2">
                  <span>
                    {getChargerName(data.chargerId, chargersQuery.data!)}
                  </span>
                </div>
              )}

              {kind === ChargingPlanTypes.sessionPlan && (
                <div className="p-inputgroup mb-2">
                  <Dropdown
                    value={data.chargerId}
                    options={chargerOptions}
                    onChange={(e) =>
                      setData({
                        ...data,
                        chargerId: e.value,
                      })
                    }
                  />
                </div>
              )}
            </>
          </ContentWithLabel>
        </div>
      )}
      <div className="min-w-min mt-4 mx-6 flex grid grid-cols-2 items-center gap-2">
        <div className="grow basis-0 min-w-fit">
          <ContentWithLabel label={t("common.start")}>
            <div className="field col-12 md:col-12">
              <Calendar
                className="w-full pb-2"
                touchUI={windowSize.lg ? false : true}
                stepMinute={15}
                value={
                  kind === ChargingPlanTypes.sessionPlan
                    ? data.start
                    : data.startDate
                }
                onChange={(e) => {
                  const date = calendarEventToDate(e);
                  if (setData && date) {
                    setData({
                      ...data,
                      start: date,
                    });
                  }
                }}
                showTime
                disabled={kind === ChargingPlanTypes.sessionInfo}
              />
            </div>
          </ContentWithLabel>
        </div>

        <div className="grow basis-0 min-w-fit">
          <ContentWithLabel label={t("common.end")}>
            <div className="field col-12 md:col-12">
              <Calendar
                className="w-full pb-2"
                touchUI={windowSize.lg ? false : true}
                stepMinute={15}
                value={
                  kind === ChargingPlanTypes.sessionPlan
                    ? data.end
                    : data.endDate
                }
                onChange={(e) => {
                  const date = calendarEventToDate(e);
                  if (setData && date) {
                    setData({
                      ...data,
                      end: date,
                    });
                  }
                }}
                showTime
                disabled={kind === ChargingPlanTypes.sessionInfo}
              />
            </div>
          </ContentWithLabel>
        </div>
        <div className="grow basis-0 min-w-fit">
          <ContentWithLabel label={t("ev.currentSoc")}>
            <div className="p-inputgroup mb-2">
              <span className="p-inputgroup-addon">
                <FontAwesomeIcon
                  icon={faBatteryQuarter}
                  className="text-lg lg:text-lg"
                />
              </span>
              <InputNumber
                useGrouping={false}
                min={0}
                max={100}
                value={data.startChargePercent}
                onValueChange={(v) => {
                  if (kind === ChargingPlanTypes.sessionPlan) {
                    setData({ ...data, startChargePercent: v.value ?? 0 });
                  }
                }}
                disabled={kind === ChargingPlanTypes.sessionInfo}
              />
              <span className="p-inputgroup-addon !font-medium !bg-gray-200 !text-gray-500">
                %
              </span>
            </div>
          </ContentWithLabel>
        </div>

        <div className="grow basis-0 min-w-fit">
          <ContentWithLabel label={t("ev.minSoc")}>
            <div className="p-inputgroup mb-2">
              <span className="p-inputgroup-addon">
                <FontAwesomeIcon
                  icon={faGaugeHigh}
                  className="text-lg lg:text-lg"
                />
              </span>
              <InputNumber
                useGrouping={false}
                min={0}
                max={100}
                value={data.minChargePercent}
                onValueChange={(v) => {
                  if (kind === ChargingPlanTypes.sessionPlan) {
                    setData({ ...data, minChargePercent: v.value ?? 0 });
                  }
                }}
                disabled={kind === ChargingPlanTypes.sessionInfo}
              />
              <span className="p-inputgroup-addon !font-medium !bg-gray-200 !text-gray-500">
                %
              </span>
            </div>
          </ContentWithLabel>
        </div>
        {kind === ChargingPlanTypes.sessionInfo && (
          <div className="grow basis-0 min-w-fit col-span-2">
            <ContentWithLabel label={t("ev.energyConsumed")}>
              <div className="p-inputgroup mb-2">
                <span className="p-inputgroup-addon">
                  <FontAwesomeIcon
                    icon={faGaugeHigh}
                    className="text-lg lg:text-lg"
                  />
                </span>
                <InputNumber
                  useGrouping={false}
                  value={data.currentKwh - data.startKwh}
                  disabled
                />
                <span className="p-inputgroup-addon !font-medium !bg-gray-200 !text-gray-500">
                  kwh
                </span>
              </div>
            </ContentWithLabel>
          </div>
        )}
      </div>
    </>
  );
}
