import { RoundedButton } from "./RoundedButton";

export interface RoundedButtonSelectorProps<T> {
  selectedIndex: number;
  options: T[];
  onSelected: (index: number) => void;
  onAddClicked?: () => void;
}
export function RoundedButtonSelector<T>({
  selectedIndex,
  options,
  onSelected,
  onAddClicked,
}: RoundedButtonSelectorProps<T>) {
  return (
    <div>
      {options &&
        options.map((o, i) => (
          <RoundedButton
            key={i}
            label={`${i + 1}`}
            highlighted={selectedIndex === i}
            disabled={false}
            onClick={() => onSelected(i)}
          />
        ))}
      {onAddClicked && (
        <RoundedButton
          label="+"
          highlighted={false}
          disabled={false}
          onClick={() => onAddClicked()}
        />
      )}
    </div>
  );
}
