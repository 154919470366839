import { ProgressBar } from "primereact/progressbar";
import { ProgressSpinner } from "primereact/progressspinner";

export interface LoaderProps {
  value?: number;
  spinner?: boolean;
}

export function Loader(props: LoaderProps) {
  return (
    <>
      {props.value !== undefined && <ProgressBar value={~~props.value} />}
      {props.spinner && (
        <ProgressSpinner className="flex custom-spinner" strokeWidth="4" />
      )}
      {!props.spinner && props.value === undefined && (
        <ProgressBar mode="indeterminate" />
      )}
    </>
  );
}
