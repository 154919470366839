import { useMutation, useQuery } from "react-query";
import { ChargerConfiguration } from "./models/ev-configuration/charger-configuration.model";
import { evConfigurationService } from "../services/ev-configuration.service";
import { CreateCharger } from "./models/ev-configuration/create-charger.model";
import { UpdateCharger } from "./models/ev-configuration/update-charger.model";

export function useChargersQuery(installationId: number) {
  return useQuery<ChargerConfiguration[]>(
    ["configuration-chargers", installationId],
    () => evConfigurationService.getChargers(installationId),
    { enabled: Boolean(installationId) }
  );
}

export function useCreateChargerMutation(installationId?: number) {
  return useMutation((data: CreateCharger) =>
    evConfigurationService.addCharger(installationId ?? 0, data)
  );
}

export function useUpdateChargerMutation(installationId?: number) {
  return useMutation((data: UpdateCharger) =>
    evConfigurationService.updateCharger(installationId ?? 0, data)
  );
}

export function useDeleteChargerMutation(installationId?: number) {
  return useMutation((id: number) =>
    evConfigurationService.deleteCharger(installationId ?? 0, id)
  );
}
