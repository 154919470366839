import React from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/number-utils";
import { OverviewCard } from "./OverviewCard";

interface EVChargingProps {
  EvSavingsEur: number;
  EvSavingsKwh: number;
}

export default function EVCharging({
  EvSavingsEur,
  EvSavingsKwh,
}: EVChargingProps) {
  const { t } = useTranslation();
  return (
    <>
      <OverviewCard>
        <div className="flex flex-col justify-center h-full">
          <div className="flex flex-row items-center">
            <div>
              <img className="w-24 p-1" src="/images/ev.jpeg" alt="img" />
            </div>
            <div className="flex flex-row items-center w-full">
              <div>
                <p className="text-xl text-gray-600 ">
                  {t("overview.evCarSavings")}
                </p>
              </div>
              <div className="pl-2 ml-auto">
                <h2 className="text-primary text-xl font-semibold">
                  {formatNumber(EvSavingsEur, 2, 2)} €
                </h2>
                <h3 className="text-gray-500 text-lg font-normal">
                  {formatNumber(EvSavingsKwh, 2, 2)} kWh
                </h3>
              </div>
            </div>
          </div>
        </div>
      </OverviewCard>
    </>
  );
}
