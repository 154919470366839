import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/number-utils";
import { OverviewCard } from "./OverviewCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { useMemo } from "react";

interface OverviewTotalProps {
  householdSavingsEur: number;
  evSavings: number | null;
  allTimeSavings: number;
  allTimeSavingsComparedToStaticPricesEur: number | null;
  totalSavingsComparedToStaticPricesEur: number | null;
  householdSavingsComparedToStaticPricesEur: number | null;
}

export default function OverviewTotal({
  evSavings,
  householdSavingsEur,
  allTimeSavings,
  allTimeSavingsComparedToStaticPricesEur,
  totalSavingsComparedToStaticPricesEur,
  householdSavingsComparedToStaticPricesEur,
}: OverviewTotalProps) {
  const { t } = useTranslation();

  const totalSavings = useMemo(() => {
    return householdSavingsEur + (evSavings ?? 0);
  }, [householdSavingsEur, evSavings]);

  return (
    <>
      <OverviewCard>
        <div className="flex flex-col justify-center h-full">
          <div className="flex flex-row items-center">
            <div>
              <img
                className="w-24 p-1"
                src="/images/total_savings.png"
                alt="img"
              />
            </div>
            <div className="flex flex-row items-center w-full">
              <div>
                {evSavings !== null && (
                  <>
                    <p className="text-xl text-gray-600 ">
                      {t("overview.totalSavings")}
                    </p>
                    {totalSavingsComparedToStaticPricesEur !== null && (
                      <p className="text-base text-gray-500 pr-8 relative">
                        {t("overview.totalSavingsWithoutStaticPrices")}
                      </p>
                    )}
                  </>
                )}
                <p className="text-xl text-gray-600 pt-4">
                  {t("overview.householdSavings")}
                </p>
                {householdSavingsComparedToStaticPricesEur !== null && (
                  <p className="text-base text-gray-500 pr-8 relative">
                    {t("overview.householdSavingsComparedToStaticPrices")}
                  </p>
                )}
                {evSavings !== null && (
                  <p className="text-xl text-gray-600 pt-4">
                    {t("overview.evCarSavings")}
                  </p>
                )}
                <p className="text-xl text-gray-600 pt-4">
                  {t("overview.lifetimeSavings")}
                </p>
                {allTimeSavingsComparedToStaticPricesEur !== null && (
                  <p className="text-base text-gray-500 pr-8 relative">
                    {t("overview.lifetimeSavingsComparedToStaticPrices")}
                  </p>
                )}
              </div>
              <div className="pl-2 ml-auto">
                {evSavings !== null && (
                  <>
                    <h2 className="text-primary text-xl font-semibold">
                      {formatNumber(totalSavings, 2, 2)} €
                    </h2>
                    {totalSavingsComparedToStaticPricesEur !== null && (
                      <h2 className="text-gray-500 text-base">
                        {formatNumber(
                          totalSavingsComparedToStaticPricesEur,
                          2,
                          2
                        )}{" "}
                        €
                      </h2>
                    )}
                  </>
                )}
                <h2 className="text-primary text-xl font-semibold pt-4">
                  {formatNumber(householdSavingsEur, 2, 2)} €
                </h2>
                {householdSavingsComparedToStaticPricesEur !== null && (
                  <h2 className="text-gray-500 text-base">
                    {formatNumber(
                      householdSavingsComparedToStaticPricesEur,
                      2,
                      2
                    )}{" "}
                    €
                  </h2>
                )}
                {evSavings !== null && (
                  <h2 className="text-primary text-xl font-semibold pt-4">
                    {formatNumber(evSavings, 2, 2)} €
                  </h2>
                )}
                <h2 className="text-primary text-xl font-semibold pt-4">
                  {formatNumber(allTimeSavings, 2, 2)} €
                </h2>
                {allTimeSavingsComparedToStaticPricesEur !== null && (
                  <h2 className="text-gray-500 text-base">
                    {formatNumber(
                      allTimeSavingsComparedToStaticPricesEur,
                      2,
                      2
                    )}{" "}
                    €
                  </h2>
                )}
              </div>
            </div>
          </div>
          <div className="self-end mt-2 text-gray-400 hover:text-primary">
            <a
              target="_blank"
              rel="noreferrer"
              className="underline text-sm"
              href="https://dashboard.vreg.be/report/DMR_Prijzen_elektriciteit.html"
            >
              {t("overview.VREGinfo")}
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                className="pl-1 text-xs"
              />
            </a>
          </div>
        </div>
      </OverviewCard>
    </>
  );
}
