import { MultiSelect } from "primereact/multiselect";
import {
  useDeviceAttributesQuery,
  useFavoritesMutation,
  useFavoritesQuery,
} from "../../queries/data-analysis.query";
import { useEffect, useMemo, useState } from "react";
import { ToggleButton } from "primereact/togglebutton";
import { useToast } from "../ui/ToastContext";
import { CenteredLoader } from "../ui/CenteredLoader";
import { useTranslation } from "react-i18next";
import { Button } from "primereact/button";

export interface DevicesMultiSelectProps {
  installationId: number;
  selectedDataSources: number[];
  onSelectedDataSourceChange: (dataSourcesIds: number[]) => void;
}

interface GroupedDropdownOptions {
  label: string;
  items: { label: string; value: number }[];
}

export function DevicesMultiSelect({
  installationId,
  selectedDataSources,
  onSelectedDataSourceChange,
}: DevicesMultiSelectProps) {
  const { t } = useTranslation();
  const deviceAttributesQuery = useDeviceAttributesQuery(installationId);
  const favoritesQuery = useFavoritesQuery(installationId);
  const favoritesMutation = useFavoritesMutation();
  const toastContext = useToast();
  const [alteredFavorites, setAlteredFavorites] = useState<number[]>();

  function setNewFavorites(newIds: number[]) {
    favoritesMutation
      .mutateAsync({
        installationId: installationId,
        newIds: newIds,
      })
      .then(
        () => {
          toastContext.current?.show({
            severity: "success",
            summary:
              newIds.length > 0
                ? t("dataCharts.favoritesSaved")
                : t("dataCharts.favoritesDeleted"),
          });
          setAlteredFavorites(newIds);
        },
        (reason) => {
          toastContext.current?.show({
            severity: "error",
            summary: t("common.errorOccurred"),
            detail: reason?.message,
          });
        }
      );
  }

  const favorites = useMemo(() => {
    if (!alteredFavorites) return favoritesQuery.data;
    return alteredFavorites;
  }, [favoritesQuery, alteredFavorites]);

  const fillHeart = useMemo(
    () => favorites?.sort().join(",") === selectedDataSources.sort().join(","),
    [favorites, selectedDataSources]
  );

  const deviceAttributes = useMemo(
    () =>
      deviceAttributesQuery.data?.map((x) => {
        return { value: x.dataSourceId, label: x.name, groupName: x.groupName };
      }),
    [deviceAttributesQuery.data]
  );
  const groupedDropdownOptions: GroupedDropdownOptions[] | undefined = useMemo(
    () =>
      deviceAttributes
        ?.map((x) => x.groupName)
        .filter((x, i, a) => a.indexOf(x) === i)
        .map(
          (x, i, a) =>
            ({
              label: x,
              items: deviceAttributes?.filter((y) => y.groupName === x),
            } as GroupedDropdownOptions)
        ),
    [deviceAttributes]
  );

  useEffect(() => {
    onSelectedDataSourceChange([]);
  }, [installationId, onSelectedDataSourceChange]);

  useEffect(() => {
    if (favorites && favorites.length > 0)
      onSelectedDataSourceChange(favorites);
  }, [favorites, onSelectedDataSourceChange]);

  if (favoritesQuery.isLoading) return <CenteredLoader spinner />;

  return (
    <div className="flex mx-2 items-center">
      <div className="!leading-10">
        <ToggleButton
          className="!bg-white h-12 w-12 !border-gray-300 border-circle mb-0"
          checked={fillHeart}
          onIcon="!text-xl !text-red-500 pi pi-heart-fill !leading-10"
          onLabel=""
          offIcon="!text-xl !text-red-500 pi pi-heart !leading-10"
          offLabel=""
          onChange={(e) => {
            if (e.target.value) setNewFavorites(selectedDataSources.sort());
            else setNewFavorites([]);
          }}
        />
      </div>

      <div className="p-0 min-w-0 mr-3">
        <MultiSelect
          value={selectedDataSources}
          options={groupedDropdownOptions}
          className="text-overflow-ellipsis max-w-full m-2"
          onChange={(e) => {
            onSelectedDataSourceChange(e.target.value.sort());
          }}
          placeholder={t("dataCharts.selectDataSources")}
          optionLabel="label"
          optionGroupChildren="items"
          optionGroupLabel="label"
          filter
          showSelectAll={false}
          scrollHeight="50vh"
          panelFooterTemplate={
            <>
              <hr />
              <div className="flex justify-start p-1 py-1 bg-neutral-100">
                <Button
                  className="p-button-outlined p-button-text p-button-secondary"
                  icon="pi pi-times"
                  label={t("common.clearSelection")}
                  onClick={(e) => onSelectedDataSourceChange([])}
                />
              </div>
            </>
          }
        />
      </div>
    </div>
  );
}
