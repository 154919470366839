import { ConsumptionPlanData } from "../queries/models/consumption-plans/consumption-plans.model";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class ConsumptionPlansService extends BaseService {
  async createConsumptionPlan(
    installationId: number,
    consumptionPlan: ConsumptionPlanData
  ): Promise<number> {
    const url = `${this.endpointPath}/${installationId}`;

    return this.http.post<number>(url, consumptionPlan).then((res) => res.data);
  }

  async getConsumptionPlans(
    installationId: number,
    from: string,
    to: string
  ): Promise<ConsumptionPlanData[]> {
    const url = `${this.endpointPath}/${installationId}?from=${from}&to=${to}`;

    return this.http.get<ConsumptionPlanData[]>(url).then((res) => res.data);
  }

  async getNonoverlappingConsumptionPlans(
    installationId: number,
    from: string,
    to: string
  ): Promise<ConsumptionPlanData[]> {
    const url = `${this.endpointPath}/${installationId}/nonoverlapping?from=${from}&to=${to}`;

    return this.http.get<ConsumptionPlanData[]>(url).then((res) => res.data);
  }

  async updateConsumptionPlan(
    installationId: number,
    consumptionPlanId: number,
    consumptionPlan: ConsumptionPlanData
  ): Promise<ConsumptionPlanData> {
    const url = `${this.endpointPath}/${installationId}/${consumptionPlanId}`;

    return this.http
      .put<ConsumptionPlanData>(url, consumptionPlan)
      .then((res) => res.data);
  }

  async deleteConsumptionPlan(
    installationId: number,
    consumptionPlanId: number
  ): Promise<ConsumptionPlanData> {
    const url = `${this.endpointPath}/${installationId}/${consumptionPlanId}`;
    return this.http.delete(url).then((res) => res.data);
  }
}

export const consumptionPlansService = new ConsumptionPlansService(
  API_ENDPOINTS.CONSUMPTION_PLANS
);
