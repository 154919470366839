import { toUnit } from "../../utils/units";

export function NodeTitleAndValue({
  value,
  unit,
  name,
  charge,
}: {
  value: number;
  unit: string;
  name: string;
  charge?: number;
}) {
  return (
    <>
      {name && (
        <span className="flex justify-center pt-3 text-primary font-semibold">
          {name}
        </span>
      )}
      {value != null && (
        <div
          className="flex justify-center text-price-green font-bold"
          style={
            unit === "€"
              ? {
                  color: (value ?? 0) > 0 ? "darkgreen" : "darkred",
                  fontWeight: "semi-bold",
                }
              : undefined
          }
        >
          {toUnit(value, unit, "k")}
          {charge !== undefined && (
            <>
              <br />
              {charge + " %"}
            </>
          )}
        </div>
      )}
    </>
  );
}
