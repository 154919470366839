import { ReactNode } from "react";

interface OverviewCardProps {
  children: ReactNode;
}

export function OverviewCard({ children }: OverviewCardProps) {
  return (
    <>
      <div className="shadow-md mt-3 p-5 md:p-7 mb-4 rounded-3xl grow basis-0 sm:min-w-[400px] min-w-[300px]">
        {children}
      </div>
    </>
  );
}
