import { EditUser } from "../queries/models/users/edit-user";
import { Installation } from "../queries/models/users/installation";
import { User } from "../queries/models/users/user";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class UsersService extends BaseService {
  async getAllInstallations(): Promise<Installation[]> {
    const url = `${API_ENDPOINTS.AUTHENTICATION}/installations`;
    return this.http.get<Installation[]>(url).then((res) => res.data);
  }

  async getAllUsers(): Promise<User[]> {
    const url = `${this.endpointPath}`;
    return this.http.get<User[]>(url).then((res) => res.data);
  }

  async addUser(user: EditUser): Promise<User> {
    const url = `${this.endpointPath}`;
    return this.http.post<User>(url, user).then((res) => res.data);
  }

  async editUser(id: number, user: EditUser): Promise<User> {
    const url = `${this.endpointPath}/${id}`;
    return this.http.put<User>(url, user).then((res) => res.data);
  }

  async deleteUser(id: number): Promise<unknown> {
    const url = `${this.endpointPath}/${id}`;
    return this.http.delete(url).then((res) => res.data);
  }
}

export const usersService = new UsersService(API_ENDPOINTS.USERS);
