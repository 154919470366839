import {
  DependencyList,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  resetAnimation,
  useDelayFromVariableDuration,
} from "./useDelayFromVariableDuration";

export interface EnergyFlowLineProps {
  flow: number;
  maxFlow: number;
  startPointElement?: HTMLElement;
  startColor?: string;
  endPointElement?: HTMLElement;
  endColor?: string;
  deps?: DependencyList;
}

function getAnimationDuration(flow: number, maxFlow: number): number {
  const maxDuration = 2;
  const minDuration = 0.08;
  let duration = 0;

  if (flow > maxFlow) flow = maxFlow;
  if (flow < -maxFlow) flow = -maxFlow;
  let clampedFlow = Math.abs(flow) < 10 ? 0 : Math.abs(flow);
  let speed =
    clampedFlow === 0
      ? 0
      : Math.log2(clampedFlow) / Math.log2(Math.abs(maxFlow));
  if (speed !== 0) {
    duration = Math.max(minDuration, maxDuration * (1 - Math.abs(speed)));
  }
  if (flow < 0) duration *= -1;
  return duration;
}

function getLengthAndDirection(
  x1: number,
  y1: number,
  x2: number,
  y2: number
): [number, number] {
  const length = Math.hypot(y2 - y1, x2 - x1);
  const deg = (Math.atan2(y2 - y1, x2 - x1) * 180) / Math.PI;
  return [length, deg];
}

function getElementCenter(element: HTMLElement): [number, number] {
  let rect = element.getBoundingClientRect();
  const x = rect.left + window.scrollX + element.offsetWidth / 2;
  const y = rect.top + window.scrollY + element.offsetHeight / 2;
  return [x, y];
}

interface PositionParameters {
  xStart: number;
  yStart: number;
  length: number;
  deg: number;
}

function setLines(
  startElement: HTMLElement | undefined,
  endElement: HTMLElement | undefined,
  setPositionParameters: (obj: PositionParameters) => void
) {
  if (startElement && endElement) {
    const [x1, y1] = getElementCenter(startElement);
    const [x2, y2] = getElementCenter(endElement);
    const [length, deg] = getLengthAndDirection(x1, y1, x2, y2);

    setPositionParameters({
      xStart: x1,
      yStart: y1,
      length: length,
      deg: deg,
    });
  }
}

export function EnergyFlowLine({
  flow,
  maxFlow,
  startColor,
  startPointElement,
  endColor,
  endPointElement,
  deps = [],
}: EnergyFlowLineProps) {
  const [positionParameters, setPositionParameters] =
    useState<PositionParameters>();

  const animationDuration = useMemo(
    () => getAnimationDuration(flow, maxFlow),
    [flow, maxFlow]
  );
  const animationDelay = useDelayFromVariableDuration(animationDuration);
  const animated = useRef(null);
  if (animated.current) resetAnimation(animated.current, "particles");

  const animationToEnd = animationDuration > 0;
  const animationToStart = animationDuration < 0;

  const handleResize = useCallback(() => {
    setLines(startPointElement, endPointElement, setPositionParameters);
  }, [startPointElement, endPointElement]);

  useEffect(() => {
    const startElement = startPointElement;
    const endElement = endPointElement;
    const parent1 = startPointElement?.parentElement;
    const parent2 = endPointElement?.parentElement;
    if (!startElement || !endElement || !parent1 || !parent2) return;

    const resizeObserver = new ResizeObserver(handleResize);
    resizeObserver.observe(parent1);
    resizeObserver.observe(parent2);

    handleResize();

    return () => {
      resizeObserver.unobserve(parent1);
      resizeObserver.unobserve(parent2);
    };
  }, [startPointElement, endPointElement, handleResize]);

  useEffect(() => {
    handleResize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return (
    <div
      className="absolute max-h-min z-10"
      style={
        positionParameters && {
          left: positionParameters.xStart,
          top: positionParameters.yStart,
          width: positionParameters.length,
          transformOrigin: 0,
          transform: `rotate(${positionParameters.deg}deg)`,
        }
      }
    >
      <div className="whitespace-nowrap overflow-hidden scale-125">
        <div
          ref={animated}
          className={`particles particles${
            (animationToEnd && "-right") || (animationToStart && "-left")
          }`}
          style={{
            animationDuration: `${Math.abs(animationDuration)}s`,
            animationDelay: `${animationDelay}s`,
            backgroundImage: `conic-gradient(
              from ${animationToStart ? 67.5 : 247.5}deg,
              ${
                (animationToEnd && startColor) ||
                (animationToStart && endColor) ||
                (!animationToStart && !animationToEnd && "lightgray") ||
                "var(--primary-color)"
              } 45deg,
              rgba(0, 0, 0, 0) 45deg
            )`,
          }}
        />
      </div>
    </div>
  );
}
