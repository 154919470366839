import { useMutation, useQuery } from "react-query";
import { ConsumptionPlanData } from "./models/consumption-plans/consumption-plans.model";
import { consumptionPlansService } from "../services/consumption-plans.service";

export function useGetConsumptionPlanQuery(
  installationId: number,
  from: Date,
  to: Date
) {
  return useQuery<ConsumptionPlanData[]>(
    ["consumption-plans", installationId, from, to],
    () =>
      consumptionPlansService.getConsumptionPlans(
        installationId,
        from.toISOString(),
        to.toISOString()
      )
  );
}

export function useGetNonoverlappingConsumptionPlanQuery(
  installationId: number,
  from: Date,
  to: Date
) {
  return useQuery<ConsumptionPlanData[]>(
    ["consumption-plans-NO", installationId, from, to],
    () =>
      consumptionPlansService.getNonoverlappingConsumptionPlans(
        installationId,
        from.toISOString(),
        to.toISOString()
      )
  );
}

export function useCreateConsumptionPlanMutation() {
  return useMutation(
    (data: { installationId: number; consumptionPlan: ConsumptionPlanData }) =>
      consumptionPlansService.createConsumptionPlan(
        data.installationId,
        data.consumptionPlan
      )
  );
}

export function useUpdateConsumptionPlanMutation() {
  return useMutation(
    (data: {
      installationId: number;
      consumptionPlanId: number;
      consumptionPlan: ConsumptionPlanData;
    }) =>
      consumptionPlansService.updateConsumptionPlan(
        data.installationId,
        data.consumptionPlanId,
        data.consumptionPlan
      )
  );
}

export function useDeleteConsumptionPlanMutation() {
  return useMutation(
    (data: { installationId: number; consumptionPlanId: number }) =>
      consumptionPlansService.deleteConsumptionPlan(
        data.installationId,
        data.consumptionPlanId
      )
  );
}
