import { useState } from "react";
import { LanguagesEditor } from "../components/translations/LanguagesEditor";
import { TranslationsEditor } from "../components/translations/TranslationsEditor";
import { Language } from "../queries/models/translations/language.model";

export function Translations() {
  const [selectedLanguage, setSelectedLanguage] = useState<Language>();

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2">
      <div className="max-h-[280px]">
        <LanguagesEditor onLanguageSelect={setSelectedLanguage} />
      </div>
      {selectedLanguage && <TranslationsEditor language={selectedLanguage} />}
    </div>
  );
}
