export enum TimeSchedule {
  None = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 4,
  Thursday = 8,
  Friday = 16,
  Saturday = 32,
  Sunday = 64,
  Holidays = 128,
  Workdays = Monday | Tuesday | Wednesday | Thursday | Friday,
  Everyday = Monday |
    Tuesday |
    Wednesday |
    Thursday |
    Friday |
    Saturday |
    Sunday,
}
