import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { usePublishedLanguagesQuery } from "../../queries/translations.query";
import { useEffect } from "react";
import { classNames } from "primereact/utils";
import i18n from "i18next";

import {
  LocalStorageService,
  localStorageService,
} from "../../services/local-storage.service";
import { useWindowSize } from "../../hooks/use-window-size";

export function LanguageDropdown() {
  const windowSize = useWindowSize();
  const languagesQuery = usePublishedLanguagesQuery();

  useEffect(() => {
    let language = localStorageService.load(
      LocalStorageService.PREFERENCES_LANGUAGE
    );
    if (!language && languagesQuery.data) {
      language = navigator.languages.find((x) =>
        languagesQuery.data.some((y) => y.culture === x)
      );
    }
    if (language) i18n.changeLanguage(language);
  }, [languagesQuery.data]);

  return (
    <>
      {windowSize.sm && (
        <div className="hover-menu-parent hover:bg-green-500 mx-2">
          <FontAwesomeIcon
            icon={faLanguage}
            className="text-white text-3xl p-3"
          />
          <div className="hover-menu-child min-w-[10rem]">
            {languagesQuery.isFetched &&
              languagesQuery.data?.map((x, i) => (
                <div
                  key={i}
                  className={classNames(
                    "p-4 border hover:bg-green-500 text-center rounded-lg",
                    x.culture === i18n.language
                      ? "bg-primary text-white"
                      : "bg-gray-50 text-gray-700 hover:text-white"
                  )}
                  onClick={() => {
                    i18n.changeLanguage(x.culture);
                    localStorageService.store(
                      LocalStorageService.PREFERENCES_LANGUAGE,
                      x.culture
                    );
                  }}
                >
                  {x.name}
                </div>
              ))}
          </div>
        </div>
      )}
      {!windowSize.sm && (
        <div className="">
          {languagesQuery.isFetched &&
            languagesQuery.data?.map((x, i) => (
              <div
                key={i}
                className={classNames(
                  "p-2  mt-3 text-center rounded-lg border",
                  x.culture === i18n.language
                    ? "bg-primary text-white"
                    : "bg-white text-gray-800"
                )}
                onClick={() => {
                  i18n.changeLanguage(x.culture);
                  localStorageService.store(
                    LocalStorageService.PREFERENCES_LANGUAGE,
                    x.culture
                  );
                }}
              >
                {x.name}
              </div>
            ))}
        </div>
      )}
    </>
  );
}
