import { useMutation, useQuery } from "react-query";
import { UserInstallationSettings } from "./models/settings/user-installation-settings";
import { settingsService } from "../services/settings.service";

export function useUserInstallationSettingsQuery(installationId: number) {
  return useQuery<UserInstallationSettings>(
    ["settings"],
    () => settingsService.getSettings(installationId),
    {
      enabled: Boolean(installationId),
    }
  );
}

export function useUserInstallationSettingsMutation(installationId: number) {
  return useMutation((data: UserInstallationSettings) =>
    settingsService.setSettings(installationId, data)
  );
}
