import { useAuth } from "oidc-react";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";

export function NoInstallationsInfo() {
  const { t } = useTranslation();
  const auth = useAuth();

  return (
    <div className="flex justify-center h-screen items-center">
      <div className="w-50 flex flex-col items-center">
        <span>{t("users.noInstallationsAssignedError")}</span>
        <div>
          <Button onClick={() => auth.signOutRedirect()} label="Logout" />
        </div>
      </div>
    </div>
  );
}
