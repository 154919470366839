import React from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/number-utils";
import { OverviewCard } from "./OverviewCard";
import { TooltipButton } from "../ui/TooltipButton";

interface OverviewExpertFromToGridProps {
  toGridEuro: number;
  toGridKWH: number;
  formGridEuro: number;
  formGridKWH: number;
}

export default function OverviewExpertFromToGrid({
  toGridEuro,
  toGridKWH,
  formGridEuro,
  formGridKWH,
}: OverviewExpertFromToGridProps) {
  const { t } = useTranslation();
  return (
    <>
      <OverviewCard>
        <div className="flex flex-col h-full relative">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row">
              <div className="flex flex-col">
                <h2 className="text-lg font-semibold text-price-green">
                  {t("overview.soldToGrid")}
                </h2>
                <div className="text-base text-gray-600 flex flex-col">
                  <p className="font-semibold pr-2 text-2xl">
                    {formatNumber(toGridEuro, 2, 2)} €
                  </p>
                  <p className="pr-2">{formatNumber(toGridKWH)} kWh</p>
                </div>
              </div>
              <img
                src="./images/sold_plus.png"
                alt="sold_plus.png"
                className="w-16 h-16 p-1 self-center ml-4"
              />
            </div>

            <div className="flex flex-row">
              <img
                src="./images/bought_minus.png"
                alt="sold_plus.png"
                className="w-16 h-16 p-1 self-center mr-4"
              />
              <div className="flex flex-col">
                <h2 className="text-lg font-semibold text-price-red">
                  {t("overview.takenFromGrid")}
                </h2>
                <div className="text-base text-gray-600 flex flex-col">
                  <p className="font-semibold pr-2 text-2xl">
                    {formatNumber(formGridEuro)} €
                  </p>
                  <p className="pr-2">{formatNumber(formGridKWH)} kWh</p>
                </div>
              </div>
            </div>
            <TooltipButton
              tooltipsMessage={t("overview.formToGridTooltip")}
            ></TooltipButton>
          </div>
        </div>
      </OverviewCard>
    </>
  );
}
