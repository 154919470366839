import React from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/number-utils";
import { OverviewCard } from "./OverviewCard";

interface OverviewPeakShavingProps {
  costOfConsumption: number;
  costOfGridPower: number;
}

export default function OverviewPeakShaving({
  costOfConsumption,
  costOfGridPower,
}: OverviewPeakShavingProps) {
  const { t } = useTranslation();
  return (
    <>
      <OverviewCard>
        <div className="flex flex-row items-center">
          <div>
            <img
              className="w-24 p-1"
              src="/images/peak_shaving.png"
              alt="img"
            />
          </div>
          <div className="flex flex-row items-center w-full">
            <div>
              <h2 className="text-xl font-semibold text-primary">
                {t("overview.peakShavingSavings")}
              </h2>
              <h2 className="text-base text-gray-600 flex">
                <p className="font-semibold pr-2">
                  {formatNumber(costOfConsumption - costOfGridPower, 2, 2)} €
                </p>
                {t("overview.peakShavingAvoidedToPay")}
              </h2>
            </div>
          </div>
        </div>
      </OverviewCard>
    </>
  );
}
