import { EnergyFlowNode } from "./EnergyFlowNode";
import { EnergyFlowLine } from "./EnergyFlowLine";
import { useEffect, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { DashboardChart } from "./DashboardChart";
import {
  useHistoryDashboardQuery,
  useLatestDashboardQuery,
} from "../../queries/dashboard.query";
import { DataPoint } from "../../queries/models/data-analysis/data-point.model";
import { CenteredLoader } from "../ui/CenteredLoader";
import { useTranslation } from "react-i18next";
import { EnergyFlowCenterNode } from "./EnergyFlowCenterNode";
import { DashboardLatestData } from "../../queries/models/dashboard/dashboard-latest-data";
import { DashboardHistoryData } from "../../queries/models/dashboard/dashboard-history-data";

export interface EnergyFlowProps {
  installationId: number;
}

export interface EnergyFlowNodeDefinition {
  name?: string;
  icon?: string;
  power?: number;
  setpoint?: number;
  charge?: number;
  color?: string;
  historyUnit?: string;
  historyCategory?: string;
  revertFlow?: boolean;
  remove?: boolean;
  disconnect?: boolean;
  isConnected?: boolean;
  isEV?: number;
  fillFromLatestData?(data: DashboardLatestData): void;
  historySelector?(data: DashboardHistoryData): DataPoint[];
}

export const energyFlowNodesDefinition: EnergyFlowNodeDefinition[] = [
  {
    icon: "images/battery.png",
    name: "common.battery",
    color: "#24D38C",
    historyUnit: "%",
    historyCategory: "common.percentage",
    revertFlow: true,
    fillFromLatestData(data: DashboardLatestData) {
      this.power = data.batteryPower ?? undefined;
      this.charge = data.batteryCharge ?? undefined;
      this.remove = data.batteryPower === null;
    },
    historySelector: (data) => data.batteryChargeHistory,
  },
  {
    icon: "images/grid.png",
    name: "common.grid",
    color: "#0ea5e9",
    historyUnit: "W",
    historyCategory: "common.power",
    fillFromLatestData(data: DashboardLatestData) {
      this.power = data.gridPower;
      this.setpoint = data.setpoint;
    },
    historySelector: (data) => data.gridHistory,
  },

  {
    icon: "images/PV_panel.png",
    name: "common.solarPanels",
    color: "#ffbf00",
    historyUnit: "W",
    historyCategory: "common.power",
    fillFromLatestData(data: DashboardLatestData) {
      this.power = data.solarPanelsPower ?? undefined;
      this.remove = data.solarPanelsPower === null;
    },
    historySelector: (data) => data.solarPanelsHistory,
  },
  {
    icon: "images/house_equipped.png",
    name: "common.consumption",
    color: "#00A99D",
    historyUnit: "W",
    historyCategory: "common.power",
    revertFlow: true,
    fillFromLatestData(data: DashboardLatestData) {
      this.power = data.consumptionPower;
    },
    historySelector: (data) => data.consumptionHistory,
  },
  {
    icon: "images/ev.jpeg",
    name: "common.electricVehicle",
    color: "#EA3050",
    historyUnit: "W",
    historyCategory: "common.power",
    revertFlow: true,
    fillFromLatestData(data: DashboardLatestData) {
      if ([data.evPower, data.evPluggedIn, data.evSetpoint].includes(null)) {
        this.remove = true;
        return;
      }
      this.isConnected = !data.evPluggedIn;
      this.power = data.evPower ?? 0;
      this.disconnect = !data.evPluggedIn;
      this.setpoint = data.evSetpoint ?? 0;
      this.isEV = data.evSetpoint ?? 0;
    },
    historySelector: (data) => data.evConsumptionHistory,
  },
];

export const mainEnergyFlowNode: EnergyFlowNodeDefinition = {
  icon: "images/bolt.png",
};

export function EnergyFlow({ installationId }: EnergyFlowProps) {
  const { t } = useTranslation();
  const latestDashboardQuery = useLatestDashboardQuery(installationId, 5000);
  useHistoryDashboardQuery(
    installationId,
    undefined,
    latestDashboardQuery.isSuccess
  );

  const [componentDidMount, setComponentDidMount] = useState(false);
  useEffect(() => {
    setComponentDidMount(true);
  }, []);
  const [nodeDialog, setNodeDialog] = useState<EnergyFlowNodeDefinition>();
  const [energyFlowNodes, setEnergyFlowNodes] = useState(
    energyFlowNodesDefinition
  );

  let nodeRefs = useRef<(HTMLElement | null)[]>([null, null, null, null]);
  let mainNodeRef = useRef<HTMLDivElement>(null);

  const numberOfGroups = 4;
  function getNodesGroup(group: 0 | 1 | 2 | 3) {
    return energyFlowNodes
      .filter((x, i) => i % numberOfGroups === group)
      .map((x, i) => (
        <div
          key={i}
          ref={(el) => (nodeRefs.current[group + i * numberOfGroups] = el)}
          className="flex justify-center"
        >
          <EnergyFlowNode
            onSelect={() => setNodeDialog(x)}
            value={x.power}
            icon={x.icon}
            name={t(x.name ?? "").toUpperCase()}
            setpoint={x.setpoint}
            charge={x.charge}
            color={x.color}
            isConnected={x.isConnected}
            isEV={x.isEV}
          />
        </div>
      ));
  }

  useEffect(() => {
    if (latestDashboardQuery.data) {
      let newNodes = energyFlowNodesDefinition.map((x) => ({ ...x }));
      newNodes.forEach((node) => {
        if (node.fillFromLatestData)
          node.fillFromLatestData(latestDashboardQuery.data);
      });
      setEnergyFlowNodes(newNodes.filter((x) => !x.remove));
    }
  }, [latestDashboardQuery.data]);

  if (
    latestDashboardQuery.isLoading ||
    (latestDashboardQuery.isFetched && latestDashboardQuery.isError)
  ) {
    return <CenteredLoader spinner />;
  }

  return (
    <>
      <div className="flex-wrap flex justify-center min-w-min m-0 mt-6 lg:mt-4">
        <div className="w-full flex justify-around min-w-max flex-wrap mb-6 lg:mb-12">
          <div className="basis-0 grow" />
          {getNodesGroup(0)}
          <div className="basis-0 grow" />
        </div>

        <div className="w-full flex justify-around min-w-max flex-wrap">
          <div className="flex basis-0 grow  justify-center">
            {getNodesGroup(1)}
          </div>
          <div
            ref={mainNodeRef}
            className="flex content-center items-center px-0 justify-center"
          >
            <EnergyFlowCenterNode
              icon={"images/bolt.png"}
              onSelect={() => setNodeDialog(mainEnergyFlowNode)}
            ></EnergyFlowCenterNode>
          </div>
          <div className="flex basis-0 grow justify-center">
            {getNodesGroup(2)}
          </div>
        </div>

        <div className="w-full flex justify-around min-w-max flex-wrap mt-4 lg:mt-12">
          <div className="basis-0 grow" />
          {getNodesGroup(3)}
          <div className="basis-0 grow" />
        </div>
      </div>

      {componentDidMount &&
        energyFlowNodes
          .filter((x) => !x.disconnect)
          .map((x, i) => (
            <EnergyFlowLine
              key={i}
              maxFlow={latestDashboardQuery.data!.maxGridPower}
              flow={(x.power ?? 0) * (x.revertFlow ? -1 : 1)}
              startPointElement={nodeRefs.current[i]!}
              startColor={x.color}
              endPointElement={mainNodeRef.current!}
              deps={[energyFlowNodes]}
            />
          ))}
      <Dialog
        className="portrait-to-landscape-absolute portrait-fullscreen-rotated-absolute"
        header={t("common.history")}
        visible={nodeDialog ? true : false}
        style={{ width: "90vw", height: "70vh" }}
        onHide={() => setNodeDialog(undefined)}
      >
        {nodeDialog && (
          <DashboardChart
            installationId={installationId}
            nodes={
              nodeDialog === mainEnergyFlowNode ? energyFlowNodes : [nodeDialog]
            }
          />
        )}
      </Dialog>
    </>
  );
}
