import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface ConditionalCheckmarkProps {
  show: boolean;
}

export function ConditionalCheckmark({ show }: ConditionalCheckmarkProps) {
  return (
    <div>
      {show && <FontAwesomeIcon icon={faCheck} className="text-green-400" />}
    </div>
  );
}
