import ReactEcharts from "echarts-for-react";
import { List } from "linqts";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { roundUpToNearestLog } from "../../utils/number-utils";
import { Unit, toUnit } from "../../utils/units";

export interface ChartStyles {
  category: string;
  opacity: number;
  interval: number | undefined;
}

const defaultUnitChartStyle: ChartStyles = {
  category: "common.value",
  opacity: 1,
  interval: undefined,
};

export const unitChartStyles: { [name in Unit]: ChartStyles } = {
  [Unit.none]: {
    ...defaultUnitChartStyle,
  },
  [Unit.euro]: {
    ...defaultUnitChartStyle,
    category: "common.price",
    opacity: 0.3,
  },
  [Unit.watt]: {
    ...defaultUnitChartStyle,
    category: "common.power",
  },
  [Unit.amper]: {
    ...defaultUnitChartStyle,
    category: "common.current",
  },
  [Unit.volt]: {
    ...defaultUnitChartStyle,
    category: "common.voltage",
  },
  [Unit.wattHour]: {
    ...defaultUnitChartStyle,
    category: "common.energy",
  },
  [Unit.percent]: {
    ...defaultUnitChartStyle,
    category: "common.percentage",
  },
  [Unit.state]: {
    ...defaultUnitChartStyle,
    category: "common.state",
    interval: 1,
  },
  [Unit.celsius]: {
    ...defaultUnitChartStyle,
    category: "common.temperature",
  },
};

export function getUnitStyles(unit: Unit | string | undefined): ChartStyles {
  return unitChartStyles[unit as Unit] || unitChartStyles[Unit.none];
}

function yAxisFormatter(value: number, unit: string | undefined): string {
  if (unit === Unit.state) {
    return Boolean(value).toString();
  }

  return toUnit(value, unit, "?", {
    flexibleFractionDigits: true,
    fractionDigits: 1,
  }).toString();
}

export interface TimeChartPoint {
  date: Date;
  value: number;
}

export interface TimeChartData {
  color?: string;
  unit?: Unit | string;
  name?: string;
  points: TimeChartPoint[];
  step?: boolean | "start" | "middle" | "end";
  type?: "line" | "bar";
  showBackground?: boolean;
}

export interface TimeChartProps {
  data: TimeChartData[];
}

export function TimeChart({ data }: TimeChartProps) {
  const { t } = useTranslation();

  const seriesBounds = useMemo(() => {
    // get  data ranges for each datasource using each unit
    const rangesByUnitAndSerie = data.map((d) => ({
      unit: d.unit,
      min:
        d.points.length === 0
          ? 0
          : d.points
              .map((p) => p.value)
              .reduce((prev, curr) => Math.min(prev, curr)),
      max:
        d.points.length === 0
          ? 0
          : d.points
              .map((p) => p.value)
              .reduce((prev, curr) => Math.max(prev, curr)),
    }));
    const rangesByUnitGrouped = new List(rangesByUnitAndSerie).GroupBy(
      (r) => r.unit ?? "",
      (e) => e
    );

    // determine value ranges for each unit
    const rangesByUnit = [];
    for (const key in rangesByUnitGrouped) {
      const unitRange = {
        unit: key,
        min: Math.min(
          roundUpToNearestLog(
            new List(rangesByUnitGrouped[key]).Min((r) => r.min)
          ),
          -1
        ),
        max: Math.max(
          roundUpToNearestLog(
            new List(rangesByUnitGrouped[key]).Max((r) => r.max)
          ),
          1
        ),
      };

      // if min is much smaller than max (or other way around) then we need to increase it
      // to avoid "squashing" the chart on the smaller side of the chart
      // this is done by making sure min/max are separated by no more than 1 order of magnitude
      const orderDifference =
        Math.log10(Math.abs(unitRange.min)) -
        Math.log10(Math.abs(unitRange.max));

      if (orderDifference > 1) {
        unitRange.max =
          unitRange.max * Math.pow(10, Math.floor(orderDifference));
      } else if (orderDifference < -1) {
        unitRange.min =
          unitRange.min * Math.pow(10, Math.floor(-orderDifference));
      }

      if (key === Unit.state) {
        unitRange.min = +false;
        unitRange.max = +true;
      }

      rangesByUnit.push(unitRange);
    }

    // get ratios between min and max values
    const ratios = new List(rangesByUnit)
      .Select((rbu) => (rbu.min === 0 ? 0 : rbu.max / rbu.min))
      .ToArray();

    // we need to find which unit has zero most centered
    const minDistanceToOne = new List(ratios)
      .Where((r) => r! < 0)
      .Min((r) => Math.abs(r - 1));

    // get ratio for this unit so we can normalize others
    const minRatio = ratios.filter(
      (r) => Math.abs(r - 1) === minDistanceToOne
    )[0];

    // normalize all units to same ratio between min and max,
    // causing zero to be at the same place on the chart
    rangesByUnit
      .filter((x) => x.unit !== Unit.state)
      .forEach((r) => {
        if (minRatio !== undefined) {
          r.min = r.max / minRatio;
        }
      });

    return rangesByUnit;
  }, [data]);

  const yAxis = useMemo(
    () =>
      data
        .filter((x, i, a) => a.map((y) => y.unit).indexOf(x.unit) === i)
        .map((x, i) => {
          const unitStyles = getUnitStyles(x.unit);
          return {
            name: t(unitStyles.category),
            type: "value",
            max: seriesBounds.filter((s) => s.unit === x.unit)[0]?.max,
            min: seriesBounds.filter((s) => s.unit === x.unit)[0]?.min,
            axisLabel: {
              formatter: (value: number) => yAxisFormatter(value, x.unit),
            },
            axisLine: {
              show: true,
            },
            splitLine: {
              show: false,
            },
            interval: unitStyles.interval,
            axisTick: {
              show: true,
              inside: true,
            },
            showGrid: false,
            position: i % 2 ? "right" : "left",
            offset: 80 * ~~(i / 2),
          };
        }),
    [data, t, seriesBounds]
  );

  const series = useMemo(
    () =>
      data.map((x, i) => {
        const unitStyles = getUnitStyles(x.unit);
        return {
          name: `${x.name ?? i} ${
            (x.unit as Unit) && x.unit !== Unit.state ? `[${x.unit}]` : ""
          }`,
          data: x.points.map((point) => [
            point.date,
            x.unit !== Unit.state ? point.value : Boolean(point.value),
          ]),
          color: x.color,
          itemStyle: {
            opacity: unitStyles.opacity,
          },
          step: x.step,
          type: x.type ?? "line",
          areaStyle: {
            color: x.showBackground ? x.color : "transparent",
            opacity: 0.3,
          },
          yAxisIndex: yAxis.map((y) => y.name).indexOf(t(unitStyles.category)),
          showSymbol: false,
          zlevel: 9,
          z: 9,
        };
      }),
    [data, yAxis, t]
  );

  const option = useMemo(
    () => ({
      tooltip: {
        trigger: "axis",
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      dataZoom: [
        {
          type: "inside",
        },
        {
          show: true,
        },
      ],
      axisPointer: {
        link: [
          {
            xAxisIndex: "all",
          },
        ],
      },
      legend: {
        show: true,
        width: "85%",
        type: "scroll",
        pageIconColor: "#24d38c",
      },
      xAxis: {
        type: "time",
        axisLabel: {
          hideOverlap: true,
        },
        axisTick: {
          show: true,
        },
        splitLine: {
          show: true,
        },
        boundaryGap: false,
      },
      yAxis: yAxis,
      series: series,
      grid: {
        left: `${~~((yAxis!.length + 1) / 2) * 80 || 20}px`,
        right: `${~~(yAxis!.length / 2) * 80 || 20}px`,
      },
    }),
    [series, yAxis]
  );

  return (
    <ReactEcharts
      option={option}
      notMerge
      className="!h-full"
    />
  );
}
