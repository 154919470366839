import { EvConfiguration } from "../queries/models/configuration/ev-configuration";
import { ChargerConfiguration } from "../queries/models/ev-configuration/charger-configuration.model";

export function getCarName(carId: string, configurations: EvConfiguration[]) {
  const car = configurations.find((c) => c.id === carId);
  if (car === undefined) return undefined;
  return car.name;
}

export function getChargerName(
  chargerId: number,
  chargers: ChargerConfiguration[]
) {
  const charger = chargers.find((c) => c.id === chargerId);
  if (charger === undefined) return undefined;
  return charger.name;
}
