import { useTranslation } from "react-i18next";
import { MqttBrokerConfiguration } from "../../queries/models/configuration/mqtt-broker-configuration";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { enumToDropdownOptions } from "../../utils/enum-utils";
import { MqttBrokerType } from "../../queries/models/configuration/mqtt-broker-type";
import { useEffect } from "react";

export interface MqttBrokerConfigurationProps {
  configuration: MqttBrokerConfiguration;
  onChange: (v: MqttBrokerConfiguration) => void;
}

export function MqttBrokerConfigurationForm({
  configuration,
  onChange,
}: MqttBrokerConfigurationProps) {
  const { t } = useTranslation();

  const setConfigurationProperty: <T extends keyof MqttBrokerConfiguration>(
    property: T,
    value: MqttBrokerConfiguration[T]
  ) => void = (property, value) => {
    onChange({
      ...configuration!,
      [property]: value,
    });
  };

  useEffect(() => {
    if (configuration.type === MqttBrokerType.Victron) {
      setConfigurationProperty("gatewayStaticHost", "");
    }
  }, [configuration.type]);

  const dropdownOptions = enumToDropdownOptions(MqttBrokerType);

  return (
    <>
      <label className="mt-2">{t("configuration.brokerType")}</label>
      <Dropdown
        value={configuration.type}
        options={dropdownOptions}
        onChange={(v) => setConfigurationProperty("type", v.value)}
      />
      {configuration.type === MqttBrokerType.Custom && (
        <>
          <label className="mt-2">{t("configuration.gatewayStaticHost")}</label>
          <InputText
            value={configuration.gatewayStaticHost}
            onChange={(v) =>
              setConfigurationProperty("gatewayStaticHost", v.target.value)
            }
          />
        </>
      )}

      <label className="mt-2">{t("configuration.gatewayId")}</label>
      <InputText
        value={configuration.gatewayId}
        onChange={(v) => setConfigurationProperty("gatewayId", v.target.value)}
      />

      <label className="mt-2">{t("configuration.gatewayUsername")}</label>
      <InputText
        value={configuration.gatewayUsername}
        onChange={(v) =>
          setConfigurationProperty("gatewayUsername", v.target.value)
        }
      />

      <label className="mt-2">{t("configuration.gatewayPassword")}</label>
      <InputText
        type="password"
        value={configuration.gatewayPassword}
        onChange={(v) =>
          setConfigurationProperty("gatewayPassword", v.target.value)
        }
      />
    </>
  );
}
