import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useSetTranslationMutation,
  useSetTranslationWithKeyMutation,
} from "../../queries/translations.query";
import { SetTranslation } from "../../queries/models/translations/set-translation.model";
import { useToast } from "../ui/ToastContext";
import { Loader } from "../ui/Loader";
import { FileUpload } from "primereact/fileupload";
import { Button } from "primereact/button";
import { exportToJson } from "../../utils/export-to-json";
import { TranslationKeyValue } from "../../queries/models/translations/translation-key-value.model";
import { TranslationKeyType } from "../../queries/models/translations/translation-key-type.enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { confirmDialog } from "primereact/confirmdialog";
import { SetTranslationWithKey } from "../../queries/models/translations/set-translation-with-key.model";
import { useQueryClient } from "react-query";
import { Language } from "../../queries/models/translations/language.model";

export interface BulkTranslationsEditorProps {
  language: Language;
  languageTranslations: TranslationKeyValue[];
}

export type TranslationsJsonTemplate = {
  [type in TranslationKeyType]?: { [key: string]: string };
};

export function isTranslationsJson(
  obj: TranslationsJsonTemplate
): obj is TranslationsJsonTemplate {
  const enumValues: number[] = Object.values(TranslationKeyType).filter(
    (value) => typeof value === "number"
  ) as number[];
  if (Array.isArray(obj)) return false;
  if (Object.keys(obj).some((x) => !enumValues.includes(+x))) return false;
  if (
    Object.values(obj).some(
      (x) =>
        Array.isArray(x) || Object.values(x).some((y) => typeof y !== "string")
    )
  )
    return false;
  return true;
}

export function translationsToTemplate(translations: TranslationKeyValue[]) {
  const finalObj: TranslationsJsonTemplate = {};
  for (const x of translations) {
    if (!x.value) continue;
    if (finalObj[x.type] === undefined) finalObj[x.type] = {};
    finalObj[x.type]![x.translationKey] = x.value;
  }
  return finalObj;
}

export function BulkTranslationsEditor({
  language,
  languageTranslations,
}: BulkTranslationsEditorProps) {
  const { t } = useTranslation();
  const setTranslationMutation = useSetTranslationMutation();
  const setTranslationWithKeyMutation = useSetTranslationWithKeyMutation();
  const queryClient = useQueryClient();
  const [loaderValue, setLoaderValue] = useState<number>();
  const toastContext = useToast();

  const checkSyntax = useCallback(
    (text: string) => {
      try {
        const obj: TranslationsJsonTemplate = JSON.parse(text);
        if (!isTranslationsJson(obj)) {
          throw new Error(t("common.unknownDataArrangement"));
        }
        return obj;
      } catch (err) {
        const message = (err as Error).message;
        toastContext.current?.show({
          severity: "error",
          summary: t("common.invalidSyntax"),
          detail: message,
        });
        return undefined;
      }
    },
    [t, toastContext]
  );

  const checkChanges = useCallback(
    function (
      newTranslations: TranslationsJsonTemplate
    ): [SetTranslationWithKey[], SetTranslation[]] {
      const newValues: SetTranslation[] = [];
      const newKeys: SetTranslationWithKey[] = [];
      const types: TranslationKeyType[] = Object.keys(newTranslations).map(
        (x) => +x
      );
      for (const type of types) {
        const entries = Object.entries<string>(newTranslations[type]!);
        for (const [key, value] of entries) {
          const old = languageTranslations.find(
            (x) => x.translationKey === key
          );
          if (old) {
            if (old.value === value) continue;
            newValues.push({
              translationKeyId: old.translationKeyId,
              languageId: language.id,
              value: value,
            });
          } else {
            newKeys.push({
              translationKey: key,
              keyType: type,
              languageId: language.id,
              value: value,
            });
          }
        }
      }
      return [newKeys, newValues];
    },
    [language, languageTranslations]
  );

  const applyChanges = useCallback(
    async function (
      newKeys: SetTranslationWithKey[],
      newValues: SetTranslation[]
    ) {
      const allChangesCount = newKeys.length + newValues.length;
      let changeIndex = 0;

      for (const newValue of newValues) {
        setLoaderValue((changeIndex++ / allChangesCount) * 100);
        await setTranslationMutation.mutateAsync(newValue);
      }

      for (const newKey of newKeys) {
        setLoaderValue((changeIndex++ / allChangesCount) * 100);
        await setTranslationWithKeyMutation.mutateAsync(newKey);
      }
      setLoaderValue(undefined);
    },
    [setTranslationMutation, setTranslationWithKeyMutation]
  );

  const onUpload = useCallback(
    function (file: File) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const resultObj = checkSyntax(e.target?.result as string);
        if (!resultObj) return;
        const [newKeys, newValues] = checkChanges(resultObj);
        confirmDialog({
          message: `${t("translations.newKeys")}: ${newKeys.length}, ${t(
            "translations.newValues"
          )}: ${newValues.length}`,
          header: t("common.confirmation"),
          icon: "pi pi-exclamation-triangle text-yellow-600",
          accept: () => {
            applyChanges(newKeys, newValues).then(() => {
              queryClient.invalidateQueries("translations");
              toastContext.current?.show({
                severity: "success",
                summary: t("translations.bulkUpdateCompleted"),
              });
            });
          },
        });
      };
      fileReader.readAsText(file);
    },
    [applyChanges, checkChanges, checkSyntax, queryClient, t, toastContext]
  );

  return (
    <div className="p-2">
      <FileUpload
        accept=".json"
        emptyTemplate={
          <div className="text-center text-6xl text-gray-400">
            <FontAwesomeIcon icon={faFileCirclePlus} />
          </div>
        }
        customUpload={true}
        uploadHandler={(e) => {
          const file = e.files[0];
          if (!file) return;
          onUpload(file);
        }}
        disabled={loaderValue !== undefined}
      />
      <div className="h-[30px]">
        {loaderValue !== undefined && <Loader value={loaderValue} />}
      </div>

      <Button
        className="w-full"
        icon="pi pi-download"
        label={t("translations.exportCurrentTranslations")}
        onClick={() => {
          exportToJson(
            translationsToTemplate(languageTranslations),
            `${language.name} ${
              window.location.hostname
            } ${new Date().toISOString()}`
          );
        }}
      />
    </div>
  );
}
