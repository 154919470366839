import { ToggleButton } from "primereact/togglebutton";
import { classNames } from "primereact/utils";
import { QueryKey, useIsFetching, useQueryClient } from "react-query";

export interface RefreshButtonProps {
  queryKey: QueryKey;
  className?: string;
}

export function RefreshButton({ queryKey, className }: RefreshButtonProps) {
  const queryClient = useQueryClient();
  const isFetching = useIsFetching(queryKey);

  return (
    <>
      <ToggleButton
        className={classNames(
          "!bg-white !border-gray-300 border-circle h-12 w-12",
          className
        )}
        checked={isFetching > 0}
        onIcon="!font-bold !text-blue-700 pi pi-spin pi-sync"
        onLabel=""
        offIcon="!font-bold !text-blue-700 pi pi-sync"
        offLabel=""
        onChange={(e) => {
          if (isFetching) return;
          queryClient.invalidateQueries(queryKey);
        }}
      />
    </>
  );
}
