import { ControlledDeviceAttribute } from "../queries/models/control/controlled-device-attribute";
import { EvCharger } from "../queries/models/control/ev-charger";
import { OptimizationResult } from "../queries/models/control/optimization-result";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class ControlService extends BaseService {
  async getOptimizationResult(
    installationId: number
  ): Promise<OptimizationResult> {
    const url = `${this.endpointPath}/optimization-result/${installationId}`;

    return this.http.get<OptimizationResult>(url).then((res) => res.data);
  }

  async getSimulatedOptimizationResult(
    installationId: number,
    startDate: Date
  ): Promise<OptimizationResult> {
    const url = `${
      this.endpointPath
    }/optimization-result/${installationId}?startTime=${startDate.toISOString()}`;

    return this.http.get<OptimizationResult>(url).then((res) => res.data);
  }

  async getChargers(installationId: number): Promise<EvCharger[]> {
    const url = `${this.endpointPath}/${installationId}/ev-chargers`;

    return this.http.get<EvCharger[]>(url).then((res) => res.data);
  }

  async getSetpoints(
    installationId: number
  ): Promise<ControlledDeviceAttribute[]> {
    const url = `${this.endpointPath}/${installationId}/controlled-device-attributes`;

    return this.http
      .get<ControlledDeviceAttribute[]>(url)
      .then((res) => res.data);
  }

  async setCustomSetpoint(
    installationId: number,
    deviceAttributeId: number,
    setpoint: number
  ): Promise<number> {
    const url = `${this.endpointPath}/${installationId}/custom-setpoint`;

    return this.http
      .post<number>(url, {
        deviceAttributeId: deviceAttributeId,
        value: setpoint,
      })
      .then((res) => res.data);
  }
}

export const controlService = new ControlService(API_ENDPOINTS.CONTROL);
