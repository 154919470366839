import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";
import { ShowFor } from "../ui/ShowFor";
import { useTranslation } from "react-i18next";

export interface SetPointProps {
  setpoint: number | undefined;
  onClickSetSetpoint(setpoint: number): void;
  onChangeSetpoint(setpoint: number): void;
}

export function SetpointPanel({
  setpoint,
  onClickSetSetpoint,
  onChangeSetpoint,
}: SetPointProps) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-wrap w-full justify-center m-0 items-center">
      <div>
        <InputNumber
          value={setpoint}
          onValueChange={(e) => onChangeSetpoint(e.value as number)}
          className="w-full"
          inputClassName="w-36 !text-2xl text-center font-bold border-none"
        />
      </div>
      <ShowFor permission="SetSetpoint">
        <div className="ml-3 sm:ml-3">
          <Button
            onClick={() =>
              setpoint !== undefined && onClickSetSetpoint(setpoint)
            }
            label={t("control.setSetpoint")}
            className="button-rounded w-max flex text-center shadow-sm"
          />
        </div>
      </ShowFor>
    </div>
  );
}
