import { useTranslation } from "react-i18next";
import { toUnit } from "../../utils/units";

export function NodeSetpoint({
  setpoint,
  unit,
  isConnected,
  isEV,
}: {
  setpoint: number;
  unit: string;
  isConnected?: boolean;
  isEV?: number;
}) {
  const { t } = useTranslation();
  return (
    <div className="absolute z-20 lg:-mt-6 -mt-4 rounded-xl lg:py-1 pt-0 px-4 lg:text-base text-sm bg-gray-200 shadow-sm">
      {(() => {
        // if EV is not plugged in
        if (isConnected === !false) {
          return t("common.evNotConnected");
        }
        // if EV is plugged in but not charging
        if (isConnected === !true && isEV !== undefined && isEV >= 0) {
          return t("common.evConnected");
        } else {
          return `${t("common.setpoint")}: ${toUnit(setpoint, unit, "k")}`;
        }
      })()}
    </div>
  );
}
