import { createContext, useContext, useEffect, useState } from "react";
import { useInstallationsQuery } from "../../queries/data-analysis.query";
import { CenteredLoader } from "./CenteredLoader";
import { NoInstallationsInfo } from "./NoInstallationsInfo";

export interface InstallationContextProviderProps {
  children: React.ReactNode;
}

export interface InstallationContextProps {
  selectedInstallationId: number;
  setSelectedInstallationId: (v: number) => void;
}

const InstallationContext = createContext<InstallationContextProps>({
  selectedInstallationId: 0,
  setSelectedInstallationId: (v) => {},
});

export function InstallationContextProvider({
  children,
}: InstallationContextProviderProps) {
  const installationsQuery = useInstallationsQuery();
  const [selectedInstallationId, setSelectedInstallationId] =
    useState<number>(0);

  useEffect(() => {
    if (installationsQuery.data) {
      if (
        !selectedInstallationId ||
        !installationsQuery.data.find((d) => d.id === selectedInstallationId)
      ) {
        setSelectedInstallationId(installationsQuery.data[0].id);
      }
    }
  }, [
    installationsQuery.data,
    selectedInstallationId,
    setSelectedInstallationId,
  ]);

  if (!installationsQuery.data && installationsQuery.isLoading) {
    return <CenteredLoader />;
  }

  if (!installationsQuery.data) return <></>;

  if (installationsQuery.data!.length === 0) {
    return <NoInstallationsInfo />;
  }

  return (
    <InstallationContext.Provider
      value={{ selectedInstallationId, setSelectedInstallationId }}
    >
      {children}
    </InstallationContext.Provider>
  );
}

export function useInstallationSelector() {
  return useContext(InstallationContext);
}
