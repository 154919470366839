import { Button } from "primereact/button";
import { useDataQuery } from "../../queries/data-analysis.query";
import { useDeviceAttributesQuery } from "../../queries/data-analysis.query";
import * as XLSX from "xlsx";
import { uniqueSort } from "../../utils/uniqueSort";
import { useMemo, useState } from "react";
import { TimeChart, TimeChartData } from "../ui/TimeChart";
import { addDays, getDay, startOfDay } from "date-fns";
import { Card } from "primereact/card";
import { CenteredLoader } from "../ui/CenteredLoader";
import { useTranslation } from "react-i18next";
import { RefreshButton } from "../ui/RefreshButton";
import { API_ENDPOINTS } from "../../utils/endpoints";
import { ConsolidationMethod } from "../../queries/models/configuration/consolidation-method";
import { SummaryDataPoint } from "../../queries/models/data-analysis/summary-data-point";
import { List } from "linqts";
import { toUnit, Unit } from "../../utils/units";
import { SelectButton } from "primereact/selectbutton";
import { SelectItem } from "primereact/selectitem/selectitem";

export interface DataAnalysisComponentProps {
  dataSourceIds: number[];
  from: Date;
  to: Date;
  installationId: number;
}

enum WattConversions {
  "W",
  "Wh",
  "Σ Wh",
}

const wattConversionOptions: SelectItem[] = (
  Object.values(WattConversions).filter(
    (value) => typeof value === "number"
  ) as number[]
).map((x) => ({
  label: WattConversions[x],
  value: x,
}));

/**
 * Modifies points' values to match conversion target
 * @param points points to modify
 * @param conversion target
 */
function ConvertWatts(
  points: { date: Date; value: number }[],
  conversion: WattConversions
) {
  //assume data is split in constant intervals
  const hourInterval =
    points[1]?.date && points[0]?.date
      ? (+points[1].date - +points[0].date) / 3600000
      : 1;

  if (conversion === WattConversions.Wh) {
    points.forEach((x) => {
      x.value = x.value * hourInterval;
    });
  } else if (conversion === WattConversions["Σ Wh"]) {
    points.forEach((x, i, a) => {
      //reset sum for different day
      const newInterval = i === 0 || getDay(a[i - 1].date) !== getDay(x.date);
      const previousValue = newInterval ? 0 : a[i - 1].value;
      x.value = x.value * hourInterval + previousValue;
    });
  }
  points.forEach((x) => (x.value = +x.value.toFixed(2)));
}

export function DataAnalysisComponent({
  dataSourceIds,
  from,
  to,
  installationId,
}: DataAnalysisComponentProps) {
  const data = useDataQuery(
    dataSourceIds,
    startOfDay(from),
    addDays(startOfDay(to), 1)
  );
  const { t } = useTranslation();
  const [wattConversion, setWattConversion] = useState(WattConversions.W);
  const chartData = data.data;

  const devicesDataQuery = useDeviceAttributesQuery(installationId);
  const devices = devicesDataQuery.data;

  const today = new Date();

  function onExportData() {
    if (!timeChartData) return;

    let jsonData: { [cols: string]: any }[] = [];
    let allDates: Date[] = timeChartData.flatMap((serie) =>
      serie.points.map((p) => p.date)
    );
    allDates = uniqueSort(allDates);

    for (const date of allDates) {
      let row: { [cols: string]: any } = {};
      row[t("logging.date")] = new Date(date);
      for (let serie of timeChartData) {
        let value = serie.points.find((d) => +d.date === +date)?.value;
        if (value !== undefined && serie.name !== undefined) {
          row[serie.name] = value;
        }
      }
      jsonData.push(row);
    }
    let ws = XLSX.utils.json_to_sheet(jsonData, {
      dateNF: 'dd"."MM"."yyyy" "HH":"mm',
    });
    let wb: XLSX.WorkBook = { Sheets: { data: ws }, SheetNames: ["data"] };
    XLSX.writeFile(wb, today.toISOString() + ".xlsx");
  }

  const timeChartData: TimeChartData[] | undefined = useMemo(
    () =>
      chartData?.map((dataSerie) => {
        let unit = devices?.find(
          (d) => d.dataSourceId === dataSerie.dataSourceId
        )?.unit!;

        let points = dataSerie.values.map((dataPoint) => ({
          date: dataPoint.date,
          value: dataPoint.value,
        }));

        if (
          unit === Unit.watt &&
          [WattConversions.Wh, WattConversions["Σ Wh"]].includes(wattConversion)
        ) {
          unit = Unit.wattHour;
          ConvertWatts(points, wattConversion);
        }

        return {
          name: devices?.find((d) => d.dataSourceId === dataSerie.dataSourceId)
            ?.name!,
          points: points,
          unit: unit,
          type: unit === Unit.wattHour ? "bar" : "line",
          step: unit === Unit.state,
          showBackground: unit === Unit.state,
        };
      }),
    [chartData, devices, wattConversion]
  );

  const summary = useMemo(() => {
    const summaryData = [] as SummaryDataPoint[];
    if (timeChartData && devices) {
      for (let dataSourceData of timeChartData) {
        const dataList = new List(dataSourceData.points);

        const withSum =
          dataSourceData.unit === Unit.wattHour &&
          wattConversion === WattConversions.Wh;
        const withLast =
          dataSourceData.unit === Unit.state ||
          (dataSourceData.unit === Unit.wattHour &&
            wattConversion === WattConversions["Σ Wh"]);
        const withAverage = !withLast || dataSourceData.unit === Unit.state;

        summaryData.push({
          name: dataSourceData.name,
          unit: dataSourceData.unit,
          aggregates: [
            withAverage && {
              consolidationMethod: ConsolidationMethod.Average,
              value: dataList.Select((d) => d.value).Average() || 0,
            },
            withSum && {
              consolidationMethod: ConsolidationMethod.Sum,
              value: dataList.Select((d) => d.value).Sum(),
            },
            withLast && {
              consolidationMethod: ConsolidationMethod.Last,
              value: dataList.Select((d) => d.value).LastOrDefault() || 0,
            },
          ],
        } as SummaryDataPoint);
      }
    }
    return summaryData;
  }, [timeChartData, devices, wattConversion]);

  return (
    <div>
      {data.isLoading && <CenteredLoader spinner />}
      {data.data && (
        <div className="flex flex-column flex-wrap flex-row-reverse items-center">
          <div className="mr-2">
            <RefreshButton queryKey={API_ENDPOINTS.DATA_ANALYSIS} />
          </div>
          <Button
            className="shadow-sm !m-2 align-self-end"
            label={t("monitoring.excelExport")}
            onClick={onExportData}
          />
          <SelectButton
            value={wattConversion}
            onChange={(e) => {
              if (e.value === null) return;
              setWattConversion(e.value);
            }}
            options={wattConversionOptions}
          />
          {timeChartData && (
            <>
              <Card className="m-2 !shadow-md w-full">
                <div className="portrait-to-landscape portrait-fullscreen-rotated min-h-[30vw] h-[60vh]">
                  <TimeChart data={timeChartData} />
                </div>
                <div className="portrait-fullscreen-dummy" />
              </Card>
              <Card className="m-2 !shadow-md w-full">
                <h2 className="font-bold text-xl">Summary</h2>
                <div
                  className={`flex flex-row flex-wrap ${
                    summary.length === 2 ? "justify-evenly" : "justify-between"
                  }`}
                >
                  {summary.map((s, i) => (
                    <div key={i}>
                      <h3 className="font-bold">{s.name}</h3>
                      {s.aggregates.map(
                        (x, j) =>
                          x && (
                            <div
                              key={j}
                              className="flex md:flex-row sm:flex-col"
                            >
                              <div className="mr-2">
                                {ConsolidationMethod[
                                  x.consolidationMethod
                                ].toString()}
                                :
                              </div>
                              <div>
                                {s.unit === Unit.state
                                  ? x.consolidationMethod ===
                                    ConsolidationMethod.Average
                                    ? toUnit(x.value * 100, "%", "-")
                                    : Boolean(x.value).toString()
                                  : toUnit(x.value, s.unit, "-M")}
                              </div>
                            </div>
                          )
                      )}
                    </div>
                  ))}
                </div>
              </Card>
            </>
          )}
        </div>
      )}
    </div>
  );
}
