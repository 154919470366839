import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { useIoDevicesQuery } from "../../../queries/configuration.query";
import { Device } from "../../../queries/models/configuration/device";

export interface DeviceAttributeSelectorProps {
  installationId: number | undefined;
  selectedDeviceAttributeId: number | undefined;
  allowEmpty?: boolean;
  onDeviceAttributeIdChange: (deviceAttributeId: number) => void;
}
export function DeviceAttributeSelector({
  installationId,
  selectedDeviceAttributeId,
  allowEmpty = false,
  onDeviceAttributeIdChange,
}: DeviceAttributeSelectorProps) {
  const { data } = useIoDevicesQuery(installationId);

  const [devices, setDevices] = useState<Device[]>();

  useEffect(() => {
    if (data) {
      setDevices(
        data.map((d) => {
          d.attributes = d.attributes.filter((a) => !a.isHidden);
          return d;
        })
      );
    } else {
      setDevices([]);
    }
  }, [data]);

  useEffect(() => {
    if (
      !allowEmpty &&
      !selectedDeviceAttributeId &&
      devices &&
      devices.length > 0 &&
      devices[0].attributes.length > 0
    ) {
      onDeviceAttributeIdChange(devices[0].attributes[0]?.id);
    }
  }, [
    selectedDeviceAttributeId,
    devices,
    allowEmpty,
    onDeviceAttributeIdChange,
  ]);

  return (
    <div>
      {devices && (
        <Dropdown
          options={devices}
          onChange={(e) => {
            onDeviceAttributeIdChange(e.target.value);
          }}
          value={selectedDeviceAttributeId}
          showClear={allowEmpty}
          optionLabel="name"
          optionValue="id"
          optionGroupLabel="name"
          optionGroupChildren="attributes"
          filter
          className="mb-3"
        />
      )}
    </div>
  );
}
