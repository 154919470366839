import { Knob } from "primereact/knob";
import { TranslationKeyValue } from "../../queries/models/translations/translation-key-value.model";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
export interface TranslationsStatisticsProps {
  translations: TranslationKeyValue[];
}

export function TranslationsStatistics({
  translations,
}: TranslationsStatisticsProps) {
  const { t } = useTranslation();
  const allTranslations = useMemo(() => translations.length, [translations]);
  const filledTranslations = useMemo(() => {
    if (translations.length === 0) return 0;
    else
      return translations
        .map((x) => (x.value === null ? 0 : 1) as number)
        .reduce((a, b) => a + b);
  }, [translations]);

  return (
    <div className="p-2">
      <div className="w-full">
        <Knob
          size={200}
          min={0}
          max={allTranslations === 0 ? 1 : allTranslations}
          value={filledTranslations}
          valueTemplate={`${filledTranslations}/${allTranslations}`}
          strokeWidth={5}
          readOnly
        />
        <div className="text-center">
          {t("translations.filledTranslations")}
        </div>
      </div>
    </div>
  );
}
