import { faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function NodeBtnMoreInfo() {
  return (
    <div className="flex justify-center text-gray-500 group-hover:text-price-green last-of-type:mt-auto">
      <FontAwesomeIcon
        icon={faChartSimple}
        className="text-sm lg:text-lg pr-2"
      />
      <span className="text-sm lg:text-base">More info</span>
    </div>
  );
}
