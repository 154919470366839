import { useMutation, useQuery } from "react-query";
import { usersService } from "../services/users.service";
import { EditUser } from "./models/users/edit-user";
import { Installation } from "./models/users/installation";
import { User } from "./models/users/user";

export function useInstallationsQuery() {
  return useQuery<Installation[]>(["installations"], () =>
    usersService.getAllInstallations()
  );
}

export function useUsersQuery() {
  return useQuery<User[]>(["users"], () => usersService.getAllUsers());
}

export function useAddUserMutation() {
  return useMutation((user: EditUser) => usersService.addUser(user));
}

export function useEditUserMutation() {
  return useMutation((data: { id: number; user: EditUser }) =>
    usersService.editUser(data.id, data.user)
  );
}

export function useDeleteUserMutation() {
  return useMutation((id: number) => usersService.deleteUser(id));
}
