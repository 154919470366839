import { useMutation, useQuery } from "react-query";
import { dataAnalysisService } from "../services/data-analysis.service";
import { API_ENDPOINTS } from "../utils/endpoints";
import { DataSeries } from "./models/data-analysis/data-series";
import { DeviceAttribute } from "./models/data-analysis/device-attribute.model";
import { Installation } from "./models/data-analysis/installation.model";

async function getData(dataSourceIds: number[], from: Date, to: Date) {
  return await dataAnalysisService.getDataSourcesData(
    dataSourceIds,
    from?.toISOString(),
    to?.toISOString()
  );
}

async function getInstallations() {
  return await dataAnalysisService.getInstallations();
}

async function getDeviceAttributes(installationId: number) {
  return await dataAnalysisService.getDeviceAttributes(installationId);
}

async function getFavoriteDataSources(installationId: number) {
  return await dataAnalysisService.getFavoriteDataSources(installationId);
}

export function useDataQuery(dataSourceIds: number[], from: Date, to: Date) {
  return useQuery<DataSeries[]>(
    [API_ENDPOINTS.DATA_ANALYSIS, "data", dataSourceIds, from, to],
    () => getData(dataSourceIds, from, to)
  );
}

export function useInstallationsQuery() {
  return useQuery<Installation[]>(["installations"], () => getInstallations());
}

export function useDeviceAttributesQuery(installatonId: number | undefined) {
  return useQuery<DeviceAttribute[]>(
    ["device-attributes", installatonId],
    () => getDeviceAttributes(installatonId!),
    { enabled: Boolean(installatonId) }
  );
}

export function useFavoritesQuery(installationId: number) {
  return useQuery<number[]>(["favourites", installationId], () =>
    getFavoriteDataSources(installationId)
  );
}

export function useFavoritesMutation() {
  return useMutation((data: { installationId: number; newIds: number[] }) =>
    dataAnalysisService.setFavoriteDataSources(data.installationId, data.newIds)
  );
}
