import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import {
  useCreateInstallationMutation,
  useInstallationsQuery,
  useUpdateInstallationMutation,
} from "../queries/configuration.query";
import { Column } from "primereact/column";
import { Installation } from "../queries/models/configuration/installation";
import { ConditionalCheckmark } from "../components/ui/ConditionalCheckmark";
import { InstallationEditForm } from "../components/configuration/InstallationEditForm";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useToast } from "../components/ui/ToastContext";
import { MqttBrokerConfiguration } from "../queries/models/configuration/mqtt-broker-configuration";
import { CenteredLoader } from "../components/ui/CenteredLoader";
import { InstallationAddForm } from "../components/configuration/InstallationAddForm";
import { InstallationToAdd } from "../queries/models/configuration/installation-to-add";

export function Installations() {
  const [selectedInstallation, setSelectedInstallation] =
    useState<Installation>();
  const [installationToAdd, setInstallationToAdd] =
    useState<InstallationToAdd>();

  const [editInstallationDialogVisible, setEditInstallationModalVisible] =
    useState(false);
  const [addInstallationDialogVisible, setAddInstallationModalVisible] =
    useState(false);

  const installationsQuery = useInstallationsQuery();
  const updateInstallationMutation = useUpdateInstallationMutation();
  const createInstallationMutation = useCreateInstallationMutation();

  const { t } = useTranslation();

  const queryClient = useQueryClient();

  const toast = useToast();

  function showInstallationDialog(installation: Installation) {
    setSelectedInstallation(installation);
    setEditInstallationModalVisible(true);
  }

  function showInstallationAddDialog() {
    setInstallationToAdd({
      name: "",
      batteryApproximation: "",
      mqttBrokerConfigurations: [] as MqttBrokerConfiguration[],
      launchDate: new Date(),
      isEnabled: false,
      isErrorTrackingEnabled: true,
      hasEvCharger: false,
      hasPvInstallation: false,
      hasBattery: false,
      isThreePhase: false,
      solarInstallationsNumber: 0,
      enirisUsername: "",
      enirisPassword: "",
      enirisControllerId: "",
    } as InstallationToAdd);
    setAddInstallationModalVisible(true);
  }

  function hideEditInstallationDialog() {
    setEditInstallationModalVisible(false);
    setSelectedInstallation(undefined);
  }

  function hideAddInstallationDialog() {
    setAddInstallationModalVisible(false);
    setInstallationToAdd(undefined);
  }

  async function saveInstallation(installation: Installation) {
    updateInstallationMutation.mutate(installation, {
      onSuccess: async () => {
        await queryClient.invalidateQueries("installations");
        await queryClient.invalidateQueries("configuration-installations");
        hideEditInstallationDialog();
        toast.current?.show({
          severity: "success",
          detail: t("common.saveSuccessful"),
        });
      },
      onError: (error: any) => {
        console.info("Error!", error);
        toast.current?.show({
          severity: "error",
          detail: t(error.response?.data) ?? error.message,
        });
      },
    });
  }

  async function createInstallation(installation: InstallationToAdd) {
    await createInstallationMutation.mutateAsync(installation);
    await queryClient.invalidateQueries("installations");
    await queryClient.invalidateQueries("configuration-installations");
    hideAddInstallationDialog();
    toast.current?.show({
      severity: "success",
      detail: t("common.saveSuccessful"),
    });
  }

  if (!installationsQuery.data && installationsQuery.isLoading) {
    return (
      <div>
        <CenteredLoader spinner />
      </div>
    );
  }

  return (
    <div>
      <div className="flex mb-4 mt-3 justify-end">
        <Button
          className="p-button-success mb-4"
          label={t("configuration.addInstallation")}
          onClick={showInstallationAddDialog}
        />
      </div>
      <DataTable
        value={installationsQuery.data}
        selectionMode="single"
        selection={selectedInstallation}
        onRowClick={(e) => showInstallationDialog(e.data as Installation)}
      >
        <Column header="ID" field="id" />
        <Column header={t("common.name")} field="name" />
        <Column
          header={t("common.enabled")}
          body={(data: Installation) => (
            <ConditionalCheckmark show={data.isEnabled} />
          )}
        />
        <Column
          header={t("common.errorTracking")}
          body={(data: Installation) => (
            <ConditionalCheckmark show={data.isErrorTrackingEnabled} />
          )}
        />
      </DataTable>

      <Dialog
        visible={editInstallationDialogVisible}
        header={t("configuration.installation")}
        onHide={() => hideEditInstallationDialog()}
      >
        {selectedInstallation && (
          <div className="flex flex-col">
            <InstallationEditForm
              value={selectedInstallation}
              onSave={saveInstallation}
            />
          </div>
        )}
      </Dialog>

      <Dialog
        visible={addInstallationDialogVisible}
        header={t("configuration.addInstallation")}
        onHide={() => hideAddInstallationDialog()}
      >
        {installationToAdd && (
          <div className="flex flex-col">
            <InstallationAddForm
              value={installationToAdd}
              onSave={createInstallation}
            />
          </div>
        )}
      </Dialog>
    </div>
  );
}
