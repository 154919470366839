import { useRef } from "react";

/**
 * Gets delay for animation to preserve it's current state while changing the duration.
 * Animation has to be reset for this delay to work, use `resetAnimation` for this purpose.
 * @param duration new duration of animation
 * @returns delay for animation
 */
export function useDelayFromVariableDuration(duration: number) {
  const angle = useRef(0);
  const lastDuration = useRef(0);
  const lastTime = useRef(Date.now() / 1000);

  const currentTime = Date.now() / 1000;
  const diffTime = currentTime - lastTime.current;

  if (lastDuration.current)
    angle.current += (diffTime % lastDuration.current) / lastDuration.current;

  angle.current -= angle.current | 0; //use fractional part only

  lastTime.current = currentTime;
  lastDuration.current = Math.abs(duration);

  return -Math.abs(duration) * angle.current;
}

/**
 * Resets animation to run from beggining
 * @param element animated element
 * @param className class name with animation
 */
export function resetAnimation(element: HTMLElement, className: string) {
  element.classList.remove(className);
  void element.offsetWidth;
  element.classList.add(className);
}
