import { endOfDay, startOfDay } from "date-fns";
import { useOverviewQuery } from "../../queries/overview.query";
import { CenteredLoader } from "../ui/CenteredLoader";
import { Button } from "primereact/button";
import { OverviewDetails } from "./OverviewDetails";
import { useState } from "react";
import { OverviewSummary } from "./OverviewSummary";
import { useTranslation } from "react-i18next";

export interface OverviewCardsProps {
  installationId: number;
  from: Date | null;
  to: Date | null;
}

export function OverviewCards({
  installationId,
  from,
  to,
}: OverviewCardsProps) {
  const [showExpertMode, setShowExpertMode] = useState<boolean>(false);
  const { t } = useTranslation();

  const overviewQuery = useOverviewQuery(
    installationId,
    from === null ? null : startOfDay(from),
    to === null ? null : endOfDay(to)
  );

  if (overviewQuery.isLoading) return <CenteredLoader spinner />;

  return (
    <>
      {overviewQuery.data && (
        <div className="mb-2 flex flex-col">
          <OverviewSummary data={overviewQuery.data.summary}></OverviewSummary>
          {!showExpertMode && (
            <div className="flex flex-wrap self-center my-4">
              <Button
                label={t("overview.expertMode")}
                className="p-button"
                onClick={() => setShowExpertMode(true)}
              />
            </div>
          )}

          {showExpertMode && (
            <OverviewDetails
              data={overviewQuery.data.details}
            ></OverviewDetails>
          )}
        </div>
      )}
    </>
  );
}
