import React, { useEffect, useMemo, useRef } from "react";
import ReactEcharts from "echarts-for-react";
import { formatNumber } from "../../utils/number-utils";

interface PieChartProps {
  title?: string;
  legend?: "bottom" | "arrows";
  unit?: string;
  data: { value: number; name: string }[];
}

export function PieChart({
  data,
  title,
  legend = "arrows",
  unit = "",
}: PieChartProps) {
  const normalRadius = "70%";
  const donutRadius = ["30%", "70%"];

  const gradient = {
    type: "linear",
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [
      {
        offset: 0,
        color: "#24D38C", // color at 0%
      },
      {
        offset: 1,
        color: "#0ea5e9", // color at 100%
      },
    ],
  };

  const solid = "#24D38C";

  const option = useMemo(() => {
    const radius = legend === "bottom" ? donutRadius : normalRadius;
    const gradientPieChartColor = legend === "bottom" ? solid : gradient;

    const options = {
      tooltip: {
        trigger: "item",
        formatter: (params: any) => {
          const { name, value } = params;
          return `${name}: ${value} ${unit}`;
        },
        extraCssText: "z-index: 39;",
      },
      color: [
        "#E0E0E0",
        gradientPieChartColor,
        "#fac858",
        "#ee6666",
        "#73c0de",
        "#3ba272",
      ],
      legend: {
        type: "scroll",
        orient: "horizontal",
        bottom: 10,
        data: data.map((item) => item.name),
        show: legend === "bottom",
      },
      series: [
        {
          name: "Electricity usage",
          type: "pie",
          radius: radius,
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.2)",
            },
          },
          label: {
            overflow: "break",
            fontSize: "14px",
            lineHeight: 18,
            formatter: (params: any) => {
              return legend === "arrows"
                ? `${params.name}\n${formatNumber(params.value)} ${unit}`
                : `${params.percent.toFixed(0)}%`;
            },
            minMargin: 5,
            rich: {
              time: {
                fontSize: 12,
                color: "#374151",
                fontWeight: 600,
                width: 30,
              },
            },
            labelLine: {
              length: 15,
              length2: 0,
              maxSurfaceAngle: 80,
            },
            position: legend === "arrows" ? "outside" : "inside",
          },
        },
      ],
    };

    return options;
  }, [data, legend, unit]);

  return (
    <div className="!h-full flex flex-col justify-content-center">
      {title && (
        <div className="text-center mt-2 text-2xl text-gray-700">{title}</div>
      )}
      <ReactEcharts
        option={option}
        notMerge
        className="!h-full min-h-[500px]"
      />
    </div>
  );
}
