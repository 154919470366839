import { useMemo, useState } from "react";
import { addDays } from "date-fns";
import { useInstallationSelector } from "../components/ui/InstallationContext";
import CalendarPicker from "../components/ui/CalendarPicker";
import { SelectButton } from "primereact/selectbutton";
import { OverviewCards } from "../components/overview/OverviewCards";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../hooks/use-window-size";

interface Option {
  label: string;
}

interface PeriodOption {
  id: number;
  label: string;
  period: PeriodOptionValue;
}

interface PeriodOptionValue {
  from: Date | null;
  to: Date | null;
  custom: boolean;
}

export function Overview() {
  const { t } = useTranslation();
  const today = new Date();
  const { selectedInstallationId } = useInstallationSelector();
  const [dates, setDates] = useState<(Date | null)[]>([
    addDays(today, -30),
    addDays(today, -1),
  ]);

  const [selectedPeriod, setSelectedPeriod] = useState(3);
  const [isCustomDateSelected, setIsCustomDateSelected] = useState(false);

  const timePeriodButtons = [
    {
      id: 1,
      label: t("overview.total"),
      period: { from: null, to: null, custom: false },
    },
    {
      id: 2,
      label: t("overview.lastWeek"),
      period: { from: addDays(today, -7), to: today, custom: false },
    },
    {
      id: 3,
      label: t("overview.last30Days"),
      period: { from: addDays(today, -30), to: today, custom: false },
    },
    {
      id: 4,
      label: t("overview.customDate"),
      period: { from: addDays(today, -14), to: today, custom: true },
    },
  ] as PeriodOption[];

  const justifyTemplate = (option: Option) => {
    return <div className="w-full text-xs lg:text-base"> {option.label}</div>;
  };

  const windowSize = useWindowSize();

  const handlePeriodChange = (e: any) => {
    const selectedOption = e.value as PeriodOption;
    setSelectedPeriod(selectedOption.id);
    setDates([selectedOption.period.from, selectedOption.period.to]);
    setIsCustomDateSelected(selectedOption.period.custom);
  };

  return (
    <div>
      <div className="fixed lg:relative top-[49px] bg-white lg:bg-transparent lg:top-2 justify-evenly w-full z-40">
        <SelectButton
          value={timePeriodButtons.find((o) => o.id === selectedPeriod)}
          onChange={handlePeriodChange}
          unselectable={false}
          itemTemplate={justifyTemplate}
          optionLabel="value"
          options={timePeriodButtons}
          className={`flex  p-2 `}
        />
        {isCustomDateSelected && (
          <div className="border-b-2 fixed lg:relative lg:bg-transparent bg-white w-full">
            <CalendarPicker dates={dates} setDates={setDates}></CalendarPicker>
          </div>
        )}
      </div>

      <div
        className={`m-3 mt-[49px] mb-[49px] lg:mb-0 lg:mt-6 
        ${selectedPeriod === 4 && !windowSize.sm ? "!mt-28" : ""}`}
      >
        {selectedInstallationId && (
          <OverviewCards
            installationId={selectedInstallationId}
            from={dates[0]}
            to={isCustomDateSelected ? dates[1] ?? dates[0] : dates[1]}
          ></OverviewCards>
        )}
      </div>
    </div>
  );
}
