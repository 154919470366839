import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useInstallationsQuery, useUsersQuery } from "../queries/users.query";
import { User } from "../queries/models/users/user";
import { MultiSelect } from "primereact/multiselect";
import { SelectItem } from "primereact/selectitem";
import { Installation } from "../queries/models/users/installation";
import { UserDialog } from "../components/users/UserDialog";
import { classNames } from "primereact/utils";
import { useRolesQuery } from "../queries/roles.query";

export function Users() {
  const { t } = useTranslation();

  const usersQuery = useUsersQuery();
  const rolesQuery = useRolesQuery();
  const installationsQuery = useInstallationsQuery();

  const [selectedUser, setSelectedUser] = useState<User>();
  const [dialogVisible, setDialogVisible] = useState<boolean>(false);

  const installationOptions: SelectItem[] = useMemo(() => {
    return (
      installationsQuery.data?.map((x) => ({
        value: x.id,
        label: x.name,
      })) ?? []
    );
  }, [installationsQuery.data]);

  const roleOptions: SelectItem[] = useMemo(() => {
    return (
      rolesQuery.data?.map((x) => ({
        value: x.id,
        label: x.name,
      })) ?? []
    );
  }, [rolesQuery.data]);

  function showEmptyDialog() {
    setDialogVisible(true);
    setSelectedUser({} as User);
  }

  function showDialog(user: User) {
    setSelectedUser(user);
    setDialogVisible(true);
  }

  return (
    <div className={classNames(usersQuery.isLoading && "blur-sm")}>
      <div className="flex mb-4 mt-3 justify-end">
        <Button
          className="p-button-success mb-4"
          label={t("users.addUser")}
          onClick={showEmptyDialog}
        />
      </div>
      <DataTable
        value={usersQuery.data}
        selectionMode="single"
        onRowClick={(e) => showDialog(e.data as User)}
        removableSort
        filterDisplay="row"
      >
        <Column
          header="ID"
          field="id"
          sortable
          filter
          filterMatchMode="contains"
          showFilterMenu={false}
        />
        <Column
          header={t("sso.email")}
          field="email"
          sortable
          filter
          filterMatchMode="contains"
          showFilterMenu={false}
        />
        <Column
          header={t("configuration.installations")}
          body={(u: User) => u.installations.map((x) => x.name).join(", ")}
          filter
          filterElement={(options) => (
            <MultiSelect
              value={options.value}
              options={installationOptions}
              onChange={(e) => {
                options.filterCallback(e.value, options.index);
                options.filterApplyCallback(e.value, options.index);
              }}
            />
          )}
          filterMatchMode="custom"
          filterField="installations"
          filterFunction={(val: Installation[], filter: number[]) => {
            if (!filter || filter?.length === 0) return true;
            if (val.some((x) => filter.some((y) => y === x.id))) return true;
            return false;
          }}
          showFilterMenu={false}
          sortable
          sortField="installations"
          sortFunction={(e) => {
            return (e.data as User[]).sort((a, b) => {
              let aLen = (a[e.field as keyof typeof a] as [])?.length ?? 0;
              let bLen = (b[e.field as keyof typeof b] as [])?.length ?? 0;
              if (aLen === bLen) return 0;
              return (aLen > bLen ? 1 : -1) * (e.order ?? 0);
            });
          }}
        />
        <Column
          header={t("users.role")}
          field="role.name"
          sortable
          filter
          filterElement={(options2) => (
            <MultiSelect
              value={options2.value}
              options={roleOptions}
              onChange={(e) => {
                options2.filterCallback(e.value, options2.index);
                options2.filterApplyCallback(e.value, options2.index);
              }}
            />
          )}
          filterMatchMode="in"
          filterField="role.id"
          showFilterMenu={false}
        />
      </DataTable>

      <UserDialog
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
        installationOptions={installationOptions}
        roleOptions={roleOptions}
      />
    </div>
  );
}
