import classNames from "classnames";

export function NodeImage({
  icon,
  isCenter,
}: {
  icon: string;
  isCenter?: boolean;
}) {
  return (
    <>
      <div className="flex grow justify-center items-center text-primary m-3">
        {typeof icon === "string" && (
          <div>
            <img
              src={icon}
              alt={icon}
              className={classNames(
                "lg:w-32 md:h-24 sm:h-20 h-16 lg:h-auto",
                isCenter && "lg:w-24 md:h-20 sm:h-20 h-16 lg:h-auto"
              )}
            />
          </div>
        )}
      </div>
    </>
  );
}
