import { AxiosError } from "axios";
import { DataSeries } from "../queries/models/data-analysis/data-series";
import { DeviceAttribute } from "../queries/models/data-analysis/device-attribute.model";
import { Installation } from "../queries/models/data-analysis/installation.model";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class DataAnalysisService extends BaseService {
  async getInstallations(): Promise<Installation[]> {
    const url = `${this.endpointPath}/installations`;

    return this.http.get<Installation[]>(url).then((res) => res.data);
  }

  async getDeviceAttributes(
    installationId: number
  ): Promise<DeviceAttribute[]> {
    const url = `${this.endpointPath}/installations/${installationId}/device-attributes`;

    return this.http.get<DeviceAttribute[]>(url).then((res) => res.data);
  }

  async getFavoriteDataSources(
    installationId: number
  ): Promise<number[]> {
    const url = `${this.endpointPath}/installations/${installationId}/favorites`;

    return this.http.get<number[]>(url).then((res) => res?.data ?? [] as number[]);
  }

  async setFavoriteDataSources(
    installationId: number,
    newIds: number[]
  ): Promise<any> {
    const url = `${this.endpointPath}/installations/${installationId}/favorites`;

    return this.http
      .post<any>(url, newIds)
      .then((res) => {
        if (res?.status === 200) {
          return res.data;
        }
        throw new AxiosError(res?.statusText);
      });
  }

  async getDataSourcesData(
    dataSourceIds: number[],
    from: string,
    to: string
  ): Promise<DataSeries[]> {
    const filtersUrl = `${this.endpointPath}/data/filter-configs`;

    const filtersConfigId = await this.http
      .post<string>(filtersUrl, { filters: dataSourceIds })
      .then((res) => res.data);

    const dataUrl = `${this.endpointPath}/data/${filtersConfigId}?from=${from}&to=${to}`;

    return this.http.get<DataSeries[]>(dataUrl).then((res) => res.data);
  }
}

export const dataAnalysisService = new DataAnalysisService(
  API_ENDPOINTS.DATA_ANALYSIS
);
