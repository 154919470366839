import { SetpointPanel } from "./SetpointPanel";
import {
  useChargersQuery,
  useControlQuery,
  useSetpointsQuery,
} from "../../queries/control.query";
import { useCustomSetpointMutation } from "../../queries/control.query";
import { useToast } from "../ui/ToastContext";
import { ControlChart } from "./ControlChart";
import { useEffect, useMemo, useState } from "react";
import { CenteredLoader } from "../ui/CenteredLoader";
import { useTranslation } from "react-i18next";
import { ControlCard } from "./ControlCard";
import { DeviceAttributeSetpoint } from "../../queries/models/control/device-attribute-setpoint";
import { format } from "date-fns";
import { ControlledDeviceAttribute } from "../../queries/models/control/controlled-device-attribute";
import { useIOConfigurationQuery } from "../../queries/configuration.query";
import { ChargerSetpoint } from "../../queries/models/control/charger-setpoint";

export interface ControlLoadedDataProps {
  installationId: number;
  onCanceled?: (err: unknown) => void;
}

export function ControlLoadedData({
  installationId,
  onCanceled,
}: ControlLoadedDataProps) {
  const { t } = useTranslation();
  const controlQuery = useControlQuery(installationId);
  const chargersQuery = useChargersQuery(installationId);
  const setpointsQuery = useSetpointsQuery(
    installationId,
    controlQuery.isError
  );
  const configurationQuery = useIOConfigurationQuery(installationId);
  const customSetpointMutation = useCustomSetpointMutation();
  const toastContext = useToast();
  const [setpoint, setSetpoint] = useState<number>(0);
  const [evSetpoints, setEvSetpoints] = useState<ChargerSetpoint[]>();

  useEffect(() => {
    if (controlQuery.data?.deviceCommands?.length && configurationQuery.data) {
      setSetpoint(
        controlQuery.data.deviceCommands.find(
          (dc) =>
            dc.deviceAttributeId ===
            configurationQuery.data.controlledDeviceAttributeId
        )?.value ?? 0
      );

      if (chargersQuery.data?.length && controlQuery.data?.deviceCommands) {
        const chargerSetpoints = [];

        for (const charger of chargersQuery.data) {
          const command = controlQuery.data.deviceCommands.find(
            (dc) => dc.deviceAttributeId === charger.setpointDeviceAttributeId
          );

          const setpoint = {
            chargerId: charger.id,
            chargerName: charger.name,
            setpoint: 0,
          } as ChargerSetpoint;

          if (command) {
            setpoint.setpoint = command.value;
          }

          chargerSetpoints.push(setpoint);
        }

        setEvSetpoints(chargerSetpoints);
      }
    }
  }, [
    controlQuery.data?.deviceCommands,
    configurationQuery.data,
    chargersQuery.data,
  ]);

  useEffect(() => {
    if (!controlQuery.isLoading && !controlQuery.data && onCanceled) {
      onCanceled(controlQuery.error);
    }
  }, [
    controlQuery.data,
    controlQuery.isLoading,
    controlQuery.error,
    onCanceled,
  ]);

  const hasEv = useMemo(() => {
    return Boolean(chargersQuery.data?.length);
  }, [chargersQuery.data]);

  const isInAutoMode = useMemo(() => {
    return configurationQuery.data?.isAutoModeEnabled;
  }, [configurationQuery.data?.isAutoModeEnabled]);

  function sendNewSetpoint(deviceAttributeId: number, value: number) {
    customSetpointMutation
      .mutateAsync({
        installationId: installationId,
        deviceAttributeId: deviceAttributeId,
        setpoint: value,
      })
      .then(
        (value) => {
          toastContext.current?.show({
            severity: "success",
            summary: t("control.setpointSetSuccessfully"),
          });
        },
        (reason) => {
          toastContext.current?.show({
            severity: "error",
            summary: t("common.errorOccurred"),
            detail: reason,
          });
        }
      );
  }

  function setEvSetpoint(chargerId: number, setpoint: number) {
    if (!evSetpoints) {
      return;
    }
    const newSetpoints = evSetpoints.map((s) =>
      s.chargerId === chargerId ? { ...s, setpoint: setpoint } : s
    );

    setEvSetpoints([...newSetpoints]);
  }

  function StoredSetpoint({
    deviceAttribute,
  }: {
    deviceAttribute: ControlledDeviceAttribute;
  }) {
    const closestSetpoint = getClosestSetpoint(deviceAttribute.setpoints);
    return closestSetpoint !== null ? (
      <div className="p-1">
        {deviceAttribute.name +
          ": " +
          closestSetpoint.value +
          " (" +
          t("control.calculatedAt") +
          " " +
          format(closestSetpoint.dateCalculated, "dd MMMM, HH:mm") +
          ")"}
      </div>
    ) : (
      <div className="p-1">
        {deviceAttribute.name +
          ": " +
          deviceAttribute.defaultControlValue +
          " (" +
          t("common.default") +
          ")"}
      </div>
    );
  }

  const balanceWithOptimization = controlQuery.data?.totalEnergyCost;

  return (
    <div>
      {!controlQuery.data && (
        <div className="flex flex-row gap-4">
          {!isInAutoMode && (
            <ControlCard>
              <div className="p-3 text-center flex flex-col">
                <div className="text-2xl text-gray-500 font-semibold">
                  {t("control.setSetpoint") + " [W]"}
                </div>
                <SetpointPanel
                  setpoint={setpoint}
                  onChangeSetpoint={setSetpoint}
                  onClickSetSetpoint={(v) =>
                    sendNewSetpoint(
                      configurationQuery.data!.controlledDeviceAttributeId,
                      v
                    )
                  }
                />
              </div>
            </ControlCard>
          )}
          {hasEv && !isInAutoMode && chargersQuery.data && (
            <>
              {chargersQuery.data.map((charger) => (
                <ControlCard>
                  <div className="p-3 text-center flex flex-col">
                    <div className="text-2xl text-gray-500 font-semibold">
                      {t("control.setEvSetpoint") +
                        " [W] (" +
                        charger.name +
                        ")"}
                    </div>
                    <SetpointPanel
                      setpoint={
                        evSetpoints?.find((s) => s.chargerId === charger.id)
                          ?.setpoint ?? 0
                      }
                      onChangeSetpoint={(s) => setEvSetpoint(charger.id, s)}
                      onClickSetSetpoint={(v) =>
                        sendNewSetpoint(charger.setpointDeviceAttributeId, v)
                      }
                    />
                  </div>
                </ControlCard>
              ))}
            </>
          )}
        </div>
      )}
      {controlQuery.isLoading && <CenteredLoader spinner />}
      {controlQuery.data && (
        <>
          <div className="flex flex-wrap">
            {!isInAutoMode && (
              <ControlCard>
                <div className="p-3 text-center">
                  <div className="text-2xl text-gray-500 font-semibold">
                    {t("control.optimalGridUsageSetpointForThisMoment") +
                      " [W]"}
                  </div>
                  <div className="p-3">
                    <SetpointPanel
                      setpoint={setpoint}
                      onChangeSetpoint={setSetpoint}
                      onClickSetSetpoint={(v) =>
                        sendNewSetpoint(
                          configurationQuery.data!.controlledDeviceAttributeId,
                          v
                        )
                      }
                    />
                  </div>
                </div>
              </ControlCard>
            )}
            {hasEv && !isInAutoMode && chargersQuery.data && (
              <>
                {chargersQuery.data.map((charger) => (
                  <ControlCard>
                    <div className="p-3 text-center flex flex-col">
                      <div className="text-2xl text-gray-500 font-semibold">
                        {t("control.setEvSetpoint") +
                          " [W] (" +
                          charger.name +
                          ")"}
                      </div>
                      <SetpointPanel
                        setpoint={
                          evSetpoints?.find((s) => s.chargerId === charger.id)
                            ?.setpoint ?? 0
                        }
                        onChangeSetpoint={(s) => setEvSetpoint(charger.id, s)}
                        onClickSetSetpoint={(v) =>
                          sendNewSetpoint(charger.setpointDeviceAttributeId, v)
                        }
                      />
                    </div>
                  </ControlCard>
                ))}
              </>
            )}
            <ControlCard>
              <div className="p-3 text-center">
                <div className="text-2xl text-gray-500 font-semibold">
                  {t("control.totalEurBalanceForNext24hours")}
                </div>
                <div className="p-3">
                  <div className="text-5xl mb-5 font-semibold m-auto max-w-min whitespace-nowrap">
                    <div
                      className={
                        balanceWithOptimization! <= 0
                          ? "text-price-green"
                          : "text-price-red"
                      }
                    >
                      {balanceWithOptimization!.toFixed(2) + " €"}
                    </div>
                  </div>
                </div>
              </div>
            </ControlCard>
          </div>
          <ControlCard>
            <div className="py-3 text-center">
              <div className="text-2xl text-gray-500 font-semibold ">
                {t("control.optimizationPredictionsChart")}
              </div>
              <div className="w-full h-96 mt-3 portrait-to-landscape portrait-fullscreen-rotated">
                <ControlChart
                  data={controlQuery.data.predictedStates}
                  hasEv={controlQuery.data.hasEv}
                  hasBattery={controlQuery.data.hasBattery}
                  hasPvInstallation={controlQuery.data.hasPvInstallation}
                />
              </div>
              <div className="portrait-fullscreen-dummy" />
            </div>
          </ControlCard>
        </>
      )}
      {controlQuery.isError && (
        <ControlCard>
          <div className="p-3 text-center">
            <div className="text-2xl text-gray-500 font-semibold">
              <div>
                <i className="pi pi-exclamation-triangle !text-7xl " />
              </div>
              {t("control.thereIsProblemWithCalculating")}
            </div>
            {setpointsQuery.data && setpointsQuery.data.length > 0 && (
              <>
                <div className="p-3 text-lg">
                  {t("control.systemIsUsingStoredSetpoints")}:
                </div>
                {setpointsQuery.data!.map((deviceAttribute) => (
                  <StoredSetpoint
                    deviceAttribute={deviceAttribute}
                    key={deviceAttribute.id}
                  />
                ))}
              </>
            )}
          </div>
        </ControlCard>
      )}
    </div>
  );
}

function getClosestSetpoint(
  setpoints: DeviceAttributeSetpoint[]
): DeviceAttributeSetpoint | null {
  const now = new Date();
  // this should match the logic in Setpoint Setter Azure function
  const setpointsWithin2Hours = setpoints.filter(
    (setpoint) =>
      setpoint.dateEffective.getTime() >= now.getTime() - 2 * 60 * 60 * 1000
  );
  if (setpointsWithin2Hours.length === 0) {
    return null;
  }
  const closestSetpoint = setpointsWithin2Hours.reduce((closest, current) => {
    const currentDiff = Math.abs(
      now.getTime() - current.dateEffective.getTime()
    );
    const closestDiff = Math.abs(
      now.getTime() - closest.dateEffective.getTime()
    );
    return currentDiff < closestDiff ? current : closest;
  });
  return closestSetpoint;
}
