export enum Unit {
  none = "",
  euro = "EUR",
  watt = "W",
  amper = "A",
  volt = "V",
  wattHour = "Wh",
  percent = "%",
  state = "bool",
  celsius = "°C",
}

export const SiPrefixableUnits = new Set<Unit>([
  Unit.watt,
  Unit.amper,
  Unit.volt,
  Unit.wattHour,
]);

const SiPrefixes = {
  "": 1,
  k: 1_000,
  M: 1_000_000,
  G: 1_000_000_000,
} as const;

export type SiPrefixSymbol = keyof typeof SiPrefixes;
export type SiPrefixValue = (typeof SiPrefixes)[keyof typeof SiPrefixes];

const AnyPrefix = "?";
export type SiPrefixRange =
  | `${SiPrefixSymbol | typeof AnyPrefix}-${SiPrefixSymbol | typeof AnyPrefix}`
  | SiPrefixSymbol
  | typeof AnyPrefix;

export function toUnit(
  number: number,
  unit: string = "",
  prefixRange: SiPrefixRange = AnyPrefix,
  {
    fractionDigits = 2,
    flexibleFractionDigits = false,
  }: {
    fractionDigits?: number;
    flexibleFractionDigits?: boolean;
  } = {}
): ValueWithUnit {
  if (prefixRange === AnyPrefix && !SiPrefixableUnits.has(unit as Unit)) {
    prefixRange = "-";
  }

  const prefixesValues = Object.entries(SiPrefixes) as [
    SiPrefixSymbol,
    SiPrefixValue
  ][];

  const selectedPrefixes = prefixRange
    .split("-")
    .map((p) => (p === AnyPrefix ? undefined : p));

  const minZoom = SiPrefixes[selectedPrefixes[0] as SiPrefixSymbol];
  const maxZoom =
    selectedPrefixes.length === 1
      ? minZoom
      : SiPrefixes[selectedPrefixes[1] as SiPrefixSymbol];

  const prefix = prefixesValues
    .filter((x) => minZoom === undefined || minZoom <= x[1])
    .filter((x) => maxZoom === undefined || x[1] <= maxZoom)
    .filter((x) => x[1] <= Math.abs(number))
    .reduce((a, b) => (a[1] < b[1] ? b : a), [
      selectedPrefixes[0] ?? "",
      minZoom ?? 1,
    ] as [SiPrefixSymbol, SiPrefixValue]);

  const value = number / prefix[1];

  return new ValueWithUnit(value, `${prefix[0]}${unit}`, {
    fractionDigits,
    flexibleFractionDigits,
  });
}

export class ValueWithUnit extends String {
  public value: string;
  public unit: string;
  public formatted: string;

  constructor(
    value: number,
    unit: string,
    {
      fractionDigits = 2,
      flexibleFractionDigits = false,
    }: {
      fractionDigits?: number;
      flexibleFractionDigits?: boolean;
    } = {}
  ) {
    super();
    this.value = (+value.toFixed(fractionDigits)).toFixed(fractionDigits);
    if (flexibleFractionDigits && fractionDigits > 0) {
      for (let i = 0; i <= fractionDigits; i++) {
        const char = this.value.at(-i - 1);
        if (char !== "0") {
          if (char === ".") i++;
          this.value = this.value.slice(0, this.value.length - i);
          break;
        }
      }
    }
    this.unit = unit;
    this.formatted = `${this.value} ${this.unit}`;
  }

  public toString(): string {
    return this.formatted;
  }

  public at(index: number) {
    return this.formatted.at(index);
  }
}
