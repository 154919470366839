import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/number-utils";
import { OverviewCard } from "./OverviewCard";
import { TooltipButton } from "../ui/TooltipButton";

interface OverviewExpertHouseholdConsumptionProps {
  householdConsumption: number;
  householdConsumptionNoInstallation: number;
  householdConsumptionNoInstallationStaticPrices: number | null;
}

export default function OverviewExpertHouseholdConsumption({
  householdConsumption,
  householdConsumptionNoInstallation,
  householdConsumptionNoInstallationStaticPrices,
}: OverviewExpertHouseholdConsumptionProps) {
  const { t } = useTranslation();
  return (
    <>
      <OverviewCard>
        <div className="flex flex-col relative">
          <div className="flex flex-row justify-evenly">
            <img
              className="w-24 p-1"
              src="/images/house_equipped.png"
              alt="img"
            />
            <div className="w-full pl-5">
              <h2 className="text-lg text-gray-600">
                {t("overview.householdConsumption")}
              </h2>
              <p className="font-semibold pr-2 text-2xl text-gray-600">
                {formatNumber(householdConsumption / 1000)} kWh
              </p>
            </div>
          </div>
          <div className="flex flex-col pt-4">
            <h2 className="text-lg text-gray-600">
              {t("overview.householdConsumptionwithoutInstallation")}
            </h2>
            <p className="font-semibold text-2xl text-price-red">
              {formatNumber(householdConsumptionNoInstallation, 2, 2)} €
            </p>
          </div>
          {householdConsumptionNoInstallationStaticPrices !== null && (
            <div className="flex flex-col pt-2">
              <h2 className="text-base text-gray-500 leading-5">
                {t("overview.householdConsumptionCostUsingStaticPrices")}
              </h2>
              <p className="font-semibold mt-[-3px] text-lg text-gray-500">
                {formatNumber(
                  householdConsumptionNoInstallationStaticPrices,
                  2,
                  2
                )}{" "}
                €
              </p>
            </div>
          )}
          <TooltipButton
            tooltipsMessage={t("overview.householdConsumptionTooltip")}
          ></TooltipButton>
        </div>
      </OverviewCard>
    </>
  );
}
