import { useTranslation } from "react-i18next";
import { useEvChargingSessionsQuery } from "../../queries/ev-charging.query";
import { CenteredLoader } from "../ui/CenteredLoader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { EvChargingSession } from "../../queries/models/ev/ev-charging-session";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { formatNumber } from "../../utils/number-utils";
import { useMemo, useState } from "react";
import { Calendar } from "primereact/calendar";
import { useIOConfigurationQuery } from "../../queries/configuration.query";
import { getCarName } from "../../utils/ev-utils";
import { Button } from "primereact/button";
import axiosRequest from "../../services/request";

interface EvChargingSessionsProps {
  installationId: number;
}

export function EvChargingSessions({
  installationId,
}: EvChargingSessionsProps) {
  const { t } = useTranslation();
  const [from, setFrom] = useState<Date>(startOfMonth(new Date()));
  const [to, setTo] = useState<Date>(endOfMonth(new Date()));

  const { data, isLoading } = useEvChargingSessionsQuery(
    installationId,
    from,
    to
  );

  const configurationQuery = useIOConfigurationQuery(installationId);

  const totalEnergyConsumed = useMemo(() => {
    if (!data) return 0;
    return data.reduce((acc, rowData) => {
      return acc + (rowData.currentKwh - rowData.startKwh);
    }, 0);
  }, [data]);

  function downloadReport() {
    axiosRequest
      .get(
        `ev-charging/${installationId}/cost-report?startDate=${from.toUTCString()}&endDate=${to.toUTCString()}`,
        {
          responseType: "blob",
          headers: {
            Accept: "application/pdf",
          },
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        const contentDisposition = response.headers["content-disposition"];
        let filename = "cost-report.pdf"; // default filename
        if (contentDisposition) {
          const match = contentDisposition.match(/filename=([^"]+)/);
          if (match && match[1]) {
            filename = match[1];
          }
        }

        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }

  return (
    <div>
      <div>
        <div className="flex flex-row justify-between items-center">
          <Calendar
            id="calendar"
            value={from}
            onChange={(e) => {
              setFrom(e.value as Date);
              setTo(endOfMonth(e.value as Date));
            }}
            dateFormat="MM yy"
            view="month"
            className="mb-3 p-inputtext-centered"
          />
          <Button
            label={t("ev.report.downloadButton")}
            onClick={downloadReport}
          />
        </div>
        {(isLoading || configurationQuery.isLoading) && <CenteredLoader />}
        {!isLoading && data && configurationQuery.data && (
          <div>
            <DataTable value={data}>
              {configurationQuery.data.evConfigurations.length > 1 && (
                <Column
                  body={(rowData: EvChargingSession) =>
                    getCarName(
                      rowData.carId,
                      configurationQuery.data.evConfigurations
                    )
                  }
                  header={t("ev.car")}
                />
              )}
              <Column
                field="startDate"
                header={t("ev.chargingStart")}
                body={(rowData: EvChargingSession) =>
                  format(rowData.startDate, "yyyy-MM-dd HH:mm")
                }
              />
              <Column
                header={t("ev.chargingEnd")}
                body={(rowData: EvChargingSession) =>
                  rowData.endDate === null
                    ? t("ev.stillCharging")
                    : format(rowData.endDate, "yyyy-MM-dd HH:mm")
                }
              />

              <Column
                body={(rowData: EvChargingSession) =>
                  formatNumber(rowData.currentKwh - rowData.startKwh, 2) +
                  " kWh"
                }
                header={t("ev.energyConsumed")}
                footer={
                  <span>
                    {t("common.total") +
                      ": " +
                      formatNumber(totalEnergyConsumed, 2) +
                      " kWh"}
                  </span>
                }
              />
            </DataTable>
          </div>
        )}
      </div>
    </div>
  );
}
