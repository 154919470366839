import { Calendar } from "primereact/calendar";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../hooks/use-window-size";

interface CalendarPickerProps {
  dates: any;
  setDates: any;
}

export default function CalendarPicker({
  dates,
  setDates,
}: CalendarPickerProps) {
  const windowSize = useWindowSize();
  const t = useTranslation().t;

  return (
    <>
      <div id="calendar" className="flex flex-wrap m-0">
        <div className="m-2">
          <span>{t("common.dateRange")}:</span>
          <Calendar
            id="range"
            value={dates}
            onChange={(e) => {
              setDates(e.value);
            }}
            selectionMode="range"
            className="mx-2 min-w-max p-inputtext-centered"
            numberOfMonths={windowSize.lg ? 2 : 1}
            readOnlyInput
          />
        </div>
      </div>
    </>
  );
}
