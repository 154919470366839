import { useQuery } from "react-query";
import { dashboardService } from "../services/dashboard.service";
import { DashboardHistoryData } from "./models/dashboard/dashboard-history-data";
import { DashboardLatestData } from "./models/dashboard/dashboard-latest-data";

async function getHistoryDashboardData(installationId: number) {
  return await dashboardService.getHistoryDashboardData(installationId);
}

async function getLatestDashboardData(installationId: number) {
  return await dashboardService.getLatestDashboardData(installationId);
}

export function useHistoryDashboardQuery(
  installationId: number,
  intervalMs: number | undefined = undefined,
  enabled: boolean | undefined = undefined
) {
  return useQuery<DashboardHistoryData>(
    ["dashboard", "history", installationId],
    () => getHistoryDashboardData(installationId),
    {
      refetchInterval: intervalMs,
      enabled: enabled,
    }
  );
}

export function useLatestDashboardQuery(
  installationId: number,
  intervalMs: number | undefined = undefined
) {
  return useQuery<DashboardLatestData>(
    ["dashboard", "latest", installationId],
    () => getLatestDashboardData(installationId),
    {
      refetchInterval: intervalMs,
    }
  );
}
