import { Toast } from "primereact/toast";
import { createContext, useContext } from "react";

export interface ToastContextProviderProps {
  children: React.ReactNode;
  toastRef: React.RefObject<Toast>;
}

const ToastContext = createContext<React.RefObject<Toast>>(
  {} as React.MutableRefObject<Toast>
);

export function ToastContextProvider({
  children,
  toastRef,
}: ToastContextProviderProps) {
  return (
    <ToastContext.Provider value={toastRef}>{children}</ToastContext.Provider>
  );
}

export function useToast() {
  return useContext(ToastContext);
}
