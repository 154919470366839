import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PredictedState } from "../../queries/models/control/predicted-state";
import { Unit } from "../../utils/units";
import { TimeChart, TimeChartData } from "../ui/TimeChart";
import { roundToDecimals } from "../../utils/number-utils";

export interface ControlChartProps {
  data: PredictedState[];
  hasEv?: boolean;
  hasBattery?: boolean;
  hasPvInstallation?: boolean;
}

interface StateConverter {
  selectValue(state: PredictedState): number;
  name: string;
  unit: Unit;
  step?: boolean | "start" | "middle" | "end";
  showBackground?: boolean;
}

export function ControlChart({
  data,
  hasEv = false,
  hasBattery = false,
  hasPvInstallation = false,
}: ControlChartProps) {
  const { t } = useTranslation();

  const stateConverters = useMemo(() => {
    const converters = [
      {
        selectValue: (state: PredictedState) => state.consumption,
        name: "common.consumption",
        unit: Unit.watt,
      },
      {
        selectValue: (state: PredictedState) => state.gridUsage,
        name: "common.gridUsage",
        unit: Unit.watt,
        step: "end",
        showBackground: true,
      },
      {
        selectValue: (state: PredictedState) => state.energyMarketPrice,
        name: "common.energyPrice",
        unit: Unit.euro,
        step: "end",
        showBackground: true,
      },
    ] as StateConverter[];

    if (hasPvInstallation) {
      converters.push({
        selectValue: (state: PredictedState) => state.solarPanelsPower,
        name: "common.solarPanelsPower",
        unit: Unit.watt,
      });
    }
    if (hasBattery) {
      converters.push({
        selectValue: (state: PredictedState) => state.batteryPower,
        name: "common.batteryPower",
        unit: Unit.watt,
        step: "end",
      });
      converters.push({
        selectValue: (state: PredictedState) => state.batteryStateOfCharge,
        name: "common.batteryCharge",
        unit: Unit.percent,
      });
    }
    if (hasEv) {
      converters.push({
        selectValue: (state: PredictedState) =>
          state.evChargers
            .filter((x) => Boolean(x.chargerId))
            .map((x) => roundToDecimals(x.carBatteryPower ?? 0, 2))
            .reduce((a, b) => a + b, 0),
        name: "common.evBatteryPower",
        unit: Unit.watt,
        step: "end",
      });
    }
    return converters;
  }, [hasEv, hasBattery, hasPvInstallation]);

  const timeChartData: TimeChartData[] = useMemo(
    () =>
      stateConverters.map(
        (converter) =>
          ({
            name: t(converter.name),
            points: data.map((x) => ({
              date: x.dateTime,
              value: converter.selectValue(x),
            })),
            unit: converter.unit,
            step: converter.step,
            showBackground: converter.showBackground,
          } as TimeChartData)
      ),
    [data, stateConverters, t]
  );

  return <TimeChart data={timeChartData} />;
}
