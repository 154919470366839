import { useInstallationSelector } from "../components/ui/InstallationContext";
import { RoundedCard } from "../components/ui/RoundedCard";
import { Button } from "primereact/button";
import { useState } from "react";
import { EvChargingSessions } from "../components/ev/EvChargingSessions";
import { useTranslation } from "react-i18next";
import { EvChargingCalendar } from "../components/ev/EvChargingCalendar";

export function EvChargingUser() {
  const { t } = useTranslation();
  const { selectedInstallationId } = useInstallationSelector();

  const [showChargingSessions, setShowChargingSessions] = useState(false);

  return (
    <>
      <div className="m-2">
        {selectedInstallationId && (
          <div className="w-full">
            <EvChargingCalendar />
            {!showChargingSessions && (
              <div className="flex justify-center">
                <Button
                  label={t("ev.showChargingSessions")}
                  onClick={() => setShowChargingSessions(true)}
                />
              </div>
            )}
            {showChargingSessions && (
              <>
                <div className="mt-4 ml-4">
                  <h1 className="text-3xl font-semibold text-primary">
                    {t("ev.chargingSessions")}
                  </h1>
                </div>
                <RoundedCard>
                  <EvChargingSessions installationId={selectedInstallationId} />
                </RoundedCard>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}
