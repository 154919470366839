import { OverviewData } from "../queries/models/overview/overview-data.model";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class OverviewService extends BaseService {
  async getOverviewData(
    installationId: number,
    from: string | null,
    to: string | null
  ): Promise<OverviewData> {
    const url = `${this.endpointPath}/${installationId}?from=${from}&to=${to}`;

    return this.http.get<OverviewData>(url).then((res) => res.data);
  }
}

export const overviewService = new OverviewService(API_ENDPOINTS.OVERVIEW);
