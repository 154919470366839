import { useEffect, useState } from "react";
import {
  useUpdateConsumptionPlanMutation,
  useDeleteConsumptionPlanMutation,
  useCreateConsumptionPlanMutation,
} from "../../queries/consumption-plans.query";
import { addDays, subDays, subMinutes, subMonths } from "date-fns";
import { ConsumptionPlanData } from "../../queries/models/consumption-plans/consumption-plans.model";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ConsumptionPlanType } from "../../queries/models/consumption-plans/consumption-plan-type.enum";
import { PlanDialogForm } from "./PlanDialogForm";
import { ConsumptionPlanRepetitionType } from "../../queries/models/consumption-plans/consumption-plan-repetition-type.enum copy";
import { TimeSchedule } from "../../queries/models/consumption-plans/time-schedule.enum";
import { useTranslation } from "react-i18next";
import { ShowFor } from "../ui/ShowFor";

export interface PlanDialogProps {
  installationId: number;
  event: ConsumptionPlanData;
  isVisible: boolean;
  onHide(): any;
}

export function PlanDialog({
  installationId,
  event,
  isVisible,
  onHide,
}: PlanDialogProps) {
  const { t } = useTranslation();
  const [comment, setComment] = useState<string>(event?.comment ?? "");
  const [type, setType] = useState<ConsumptionPlanType>(
    event?.type ?? ConsumptionPlanType.Low
  );
  const [from, setFrom] = useState<Date | undefined>(event?.from);
  const [to, setTo] = useState<Date | undefined>(event?.to);
  const [repetitionType, setRepetitionType] =
    useState<ConsumptionPlanRepetitionType>(event?.repetitionType);
  const [repetitionValue, setRepetitionValue] = useState<number>(
    event?.repetitionValue ?? 0
  );
  const [repetitionExpiration, setRepetitionExpiration] = useState<
    Date | undefined
  >(event?.repetitionExpiration);
  const [repetitionWeekDays, setRepetitionWeekDays] = useState<TimeSchedule>(
    event?.repetitionWeekDays ?? TimeSchedule.None
  );

  const addConsumptionPlansMutation = useCreateConsumptionPlanMutation();
  const updateConsumptionPlansMutation = useUpdateConsumptionPlanMutation();
  const deleteConsumptionPlansMutation = useDeleteConsumptionPlanMutation();

  useEffect(() => {
    setComment(event?.comment ?? "");
    setType(event?.type ?? ConsumptionPlanType.Low);
    setFrom(event?.from);
    setTo(event?.to);
    setRepetitionType(
      event?.repetitionType ?? ConsumptionPlanRepetitionType.None
    );
    setRepetitionValue(event?.repetitionValue ?? 0);
    setRepetitionExpiration(event?.repetitionExpiration);
    setRepetitionWeekDays(event?.repetitionWeekDays ?? TimeSchedule.None);
  }, [event]);

  function validate() {
    if (!from) return false;
    return true;
  }

  if (!event) return <></>;

  return (
    <Dialog
      visible={isVisible}
      header={event.id ? t("consumptionPlans.edit") : t("consumptionPlans.set")}
      className="max-w-[550px] w-full absolute !max-h-full"
      contentClassName="!p-0"
      onHide={() => {
        onHide();
      }}
    >
      <div className="grid">
        <div className="mb-2 transform duration-150">
          <PlanDialogForm
            type={type}
            setType={setType}
            from={from}
            setFrom={setFrom}
            to={to}
            setTo={setTo}
            repetitionType={repetitionType}
            setRepetitionType={setRepetitionType}
            repetitionValue={repetitionValue}
            setRepetitionValue={setRepetitionValue}
            repetitionExpiration={repetitionExpiration}
            setRepetitionExpiration={setRepetitionExpiration}
            repetitionWeekDays={repetitionWeekDays}
            setRepetitionWeekDays={setRepetitionWeekDays}
          />
        </div>

        <div className="grid grid-flow-col auto-rows-max gap-2 mt-6 mb-6 mx-6">
          <Button
            label={t("common.cancel")}
            className="p-button-outlined"
            onClick={onHide}
          />
          {event.id && (
            <ShowFor permission="ConsumptionPlansEdit">
              <Button
                label={t("common.delete")}
                className="p-button-danger"
                onClick={() => {
                  if (repetitionValue > 0) {
                    return console.log("repetitionValue > 0");
                  }
                  deleteConsumptionPlansMutation
                    .mutateAsync({
                      installationId: installationId,
                      consumptionPlanId: event.id,
                    })
                    .then((res) => {
                      onHide();
                    });
                }}
              />
            </ShowFor>
          )}

          <ShowFor permission="ConsumptionPlansEdit">
            <Button
              label={event.id ? t("common.edit") : t("common.add")}
              onClick={() => {
                if (!validate()) {
                  return;
                }

                const mutator = event.id
                  ? updateConsumptionPlansMutation
                  : addConsumptionPlansMutation;

                function startFullDay(x: Date): Date {
                  x.setHours(0);
                  return subMinutes(x, x.getTimezoneOffset());
                }
                function endFullDay(x: Date): Date {
                  x.setHours(0);
                  const y = addDays(subMinutes(x, x.getTimezoneOffset()), 1);
                  if (y.getFullYear() >= 10000)
                    return subDays(subMonths(new Date(10000, 1), 1), 1);
                  return y;
                }

                mutator
                  .mutateAsync({
                    installationId: installationId,
                    consumptionPlanId: event.id,
                    consumptionPlan: {
                      from: startFullDay(from!),
                      to: endFullDay(to ?? from!),
                      comment: comment,
                      type: type,
                      installationId: installationId,
                      repetitionType: repetitionType,
                      repetitionValue: repetitionValue,
                      repetitionExpiration: repetitionExpiration
                        ? endFullDay(repetitionExpiration)
                        : undefined,
                      repetitionWeekDays: repetitionWeekDays,
                    } as ConsumptionPlanData,
                  })
                  .then((res) => {
                    onHide();
                  });
              }}
            />
          </ShowFor>
        </div>
      </div>
    </Dialog>
  );
}
