import { ConsumptionPlanCalendar } from "../components/consumption-plans/ConsumptionPlanCalendar";
import { CenteredLoader } from "../components/ui/CenteredLoader";
import { useInstallationSelector } from "../components/ui/InstallationContext";

export function ConsumptionPlans() {
  const { selectedInstallationId } = useInstallationSelector();

  return (
    <div className="w-full">
      <div className="m-2 grid grid-cols-1">
        {selectedInstallationId && (
          <div>
            <ConsumptionPlanCalendar installationId={selectedInstallationId} />
          </div>
        )}
        {!selectedInstallationId && <CenteredLoader spinner />}
      </div>
    </div>
  );
}
