import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames } from "primereact/utils";
import { NavLink } from "react-router-dom";

interface SideBarElementProps {
  to: string;
  label: string;
  icon: any;
}

export function SideBarElementMobile({ to, label, icon }: SideBarElementProps) {
  return (
    <li className="grow basis-0">
      <NavLink
        to={to}
        className={({ isActive }) =>
          classNames(
            "text-center flex flex-col items-center no-underline text-sm hover:text-primary ",
            isActive
              ? "text-primary font-semibold hover:text-primary"
              : "text-gray-600"
          )
        }
      >
        <FontAwesomeIcon icon={icon} className="text-xl" />
        {label}
      </NavLink>
    </li>
  );
}
