import { UserInstallationSettings } from "../queries/models/settings/user-installation-settings";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class SettingsService extends BaseService {
  async getSettings(installationId: number): Promise<UserInstallationSettings> {
    const url = `${this.endpointPath}/${installationId}`;
    return this.http.get<UserInstallationSettings>(url).then((res) => res.data);
  }

  async setSettings(installationId: number, data: UserInstallationSettings) {
    const url = `${this.endpointPath}/${installationId}`;
    return this.http.put(url, data);
  }
}

export const settingsService = new SettingsService(API_ENDPOINTS.SETTINGS);
