import { ReactNode } from "react";
import { usePermissions } from "../../hooks/use-permissions";
import { Permissions } from "../../utils/permissions";

export function ShowFor({
  permission,
  children,
}: {
  permission?: Permissions | Permissions[];
  children: ReactNode;
}) {
  const { permissions } = usePermissions();

  const allowed =
    permission === undefined ||
    (Array.isArray(permission)
      ? permission.every((p) => permissions.includes(p))
      : permissions.includes(permission));

  if (allowed) {
    return <>{children}</>;
  }

  return <></>;
}
