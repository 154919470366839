import { TabPanel, TabView } from "primereact/tabview";
import { ChargersConfiguration } from "../components/ev-configuration/ChargersConfiguration";

export function EvConfiguration() {
  return (
    <div>
      <TabView>
        <TabPanel header="Chargers">
          <ChargersConfiguration />
        </TabPanel>
      </TabView>
    </div>
  );
}
