import { AxiosResponse } from "axios";
import { EvChargingSession } from "../queries/models/ev/ev-charging-session";
import {
  EvChargingSessionPlan,
  EvChargingSessionPlanNew,
} from "../queries/models/ev/ev-charging-session-plan";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class EvChargingService extends BaseService {
  async getEvChargingSessions(
    installationId: number,
    from: Date,
    to: Date
  ): Promise<EvChargingSession[]> {
    const url = `${
      this.endpointPath
    }/${installationId}/sessions?from=${from.toISOString()}&to=${to.toISOString()}`;

    return this.http.get<EvChargingSession[]>(url).then((res) => res.data);
  }

  async getOverlappingEvChargingSessions(
    installationId: number,
    from: Date,
    to: Date
  ): Promise<EvChargingSession[]> {
    const url = `${
      this.endpointPath
    }/${installationId}/overlapping-sessions?from=${from.toISOString()}&to=${to.toISOString()}`;

    return this.http.get<EvChargingSession[]>(url).then((res) => res.data);
  }

  async getEvChargingSessionPlans(
    installationId: number,
    from: Date,
    to: Date
  ): Promise<EvChargingSessionPlan[]> {
    const url = `${
      this.endpointPath
    }/${installationId}/plans?from=${from.toISOString()}&to=${to.toISOString()}`;

    return this.http.get<EvChargingSessionPlan[]>(url).then((res) => res.data);
  }

  async getAllEvChargingSessionPlans(
    installationId: number,
    from: Date,
    to: Date
  ): Promise<EvChargingSessionPlan[]> {
    const url = `${
      this.endpointPath
    }/${installationId}/plans/all?from=${from.toISOString()}&to=${to.toISOString()}`;

    return this.http.get<EvChargingSessionPlan[]>(url).then((res) => res.data);
  }

  async createEvChargingSessionPlan(
    installationId: number,
    plan: EvChargingSessionPlanNew
  ): Promise<EvChargingSessionPlan> {
    const url = `${this.endpointPath}/${installationId}/plans`;

    return this.http
      .post<EvChargingSessionPlan>(url, plan)
      .then((res) => res.data);
  }

  async updateEvChargingSessionPlan(
    installationId: number,
    plan: EvChargingSessionPlan
  ): Promise<EvChargingSessionPlan> {
    const url = `${this.endpointPath}/${installationId}/plans/${plan.id}`;

    return this.http
      .put<EvChargingSessionPlan>(url, plan)
      .then((res) => res.data);
  }

  async deleteEvChargingSessionPlan(
    installationId: number,
    planId: number
  ): Promise<AxiosResponse<any, any>> {
    const url = `${this.endpointPath}/${installationId}/plans/${planId}`;

    return this.http.delete(url).then((res) => res.data);
  }
}

export const evChargingService = new EvChargingService(
  API_ENDPOINTS.EV_CHARGING
);
