import { SideBarElementsMobile } from "./side-bar-elements-mobile";
import { MenuElement } from "./side-bar-nav";

export function SideBarNavigationMobile({
  menuElements,
}: {
  menuElements: MenuElement[];
}) {
  return (
    <div className="flex fixed w-full bottom-0 z-50">
      <div className="rounded-t-md shadow-sm p-1 w-full bg-white border-noround-bottom">
        <SideBarElementsMobile menuElements={menuElements} />
      </div>
    </div>
  );
}
