import { Loader, LoaderProps } from "./Loader";

export function CenteredLoader(props: LoaderProps) {
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <Loader {...props} />
      </div>
    </div>
  );
}
