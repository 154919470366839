import { classNames } from "primereact/utils";

interface RoundedCardProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  header?: string;
}

export function RoundedCard({ children, className, header }: RoundedCardProps) {
  return (
    <div className={classNames("shadow-md m-3 p-4 rounded-3xl", className)}>
      {header && (
        <div className="text-primary font-bold text-xl mb-2">{header}</div>
      )}
      {children}
    </div>
  );
}
