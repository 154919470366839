import React from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/number-utils";
import { OverviewCard } from "./OverviewCard";
import { TooltipButton } from "../ui/TooltipButton";

interface OverviewExpertAveragePricesProps {
  averageBuyingPrice: number;
  averageSellingPrice: number;
}

export default function OverviewExpertAveragePrices({
  averageBuyingPrice,
  averageSellingPrice,
}: OverviewExpertAveragePricesProps) {
  const { t } = useTranslation();
  return (
    <>
      <OverviewCard>
        <div className="flex flex-row h-full relative">
          <img
            className="w-24 h-24 p-1"
            src="/images/euro_sign.png"
            alt="img"
          />
          <div>
            <div className="w-full pl-5">
              <h2 className="text-lg text-gray-600">
                {t("overview.averagePriceWhenSystemBuysFromGrid")}
              </h2>
              <p className="font-semibold pr-2 text-2xl text-gray-600">
                {formatNumber(averageBuyingPrice)} €/kWh
              </p>
            </div>
            <div className="w-full pl-5">
              <h2 className="text-lg text-gray-600">
                {t("overview.averagePriceWhenSystemSellsToGrid")}
              </h2>
              <p className="font-semibold pr-2 text-2xl text-gray-600">
                {formatNumber(averageSellingPrice)} €/kWh
              </p>
            </div>
            <TooltipButton
              tooltipsMessage={t("overview.AveragePricesTooltip")}
            ></TooltipButton>
          </div>
        </div>
      </OverviewCard>
    </>
  );
}
