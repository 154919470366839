import { OverviewCard } from "./OverviewCard";
import OverviewExpertFromToGrid from "./OverviewExpertFromToGrid";
import OverviewExpertPeakShaving from "./OverviewExpertPeakShaving";
import OverviewExpertHouseholdConsumption from "./OverviewExpertHouseholdConsumption";
import { useMemo } from "react";
import { roundToDecimals } from "../../utils/number-utils";
import { PieChart } from "../ui/PieCharts";
import { OverviewDetailsData } from "../../queries/models/overview/overview-details-data.model";
import { useTranslation } from "react-i18next";
import QuickGuideButton from "../ui/QuickGuideButton";
import OverviewExpertAveragePrices from "./OverviewExpertAveragePrices";

export interface OverviewDetailsProps {
  data: OverviewDetailsData;
}

export function OverviewDetails({ data }: OverviewDetailsProps) {
  const { t } = useTranslation();
  const consumptionDetails = useMemo(() => {
    if (!data) {
      return [];
    }

    const details = [
      {
        name: t("overview.fromGrid"),
        value: roundToDecimals(data.consumptionFromGrid / 1000, 2),
      },
    ];

    if (data.hasBattery) {
      details.push({
        name: t("overview.fromBattery"),
        value: roundToDecimals(data.consumptionFromBattery / 1000, 2),
      });
    }
    if (data.hasPvInstallation) {
      details.push({
        name: t("overview.fromPV"),
        value: roundToDecimals(data.consumptionFromSolarPanels / 1000, 2),
      });
    }

    return details;
  }, [data, t]);

  const solarPanelsDetails = useMemo(() => {
    if (!data || !data.hasPvInstallation) {
      return [];
    }

    const details = [
      {
        name: t("overview.toGrid"),
        value: roundToDecimals(data.solarPanelsToGrid / 1000, 2),
      },
      {
        name: t("overview.directUse"),
        value: roundToDecimals(data.solarPanelsDirectUse / 1000, 2),
      },
    ];

    if (data.hasBattery) {
      details.push({
        name: t("overview.toBattery"),
        value: roundToDecimals(data.solarPanelsToBattery / 1000, 2),
      });
    }

    if (data.hasEv) {
      details.push({
        name: t("overview.toEv"),
        value: roundToDecimals((data.evFromSolarPanels ?? 0) / 1000, 2),
      });
    }

    return details;
  }, [data, t]);

  const batteryDetails = useMemo(() => {
    if (!data) {
      return [];
    }

    const details = [
      {
        name: t("overview.toGrid"),
        value: roundToDecimals(data.batteryToGrid / 1000, 2),
      },
      {
        name: t("overview.directUse"),
        value: roundToDecimals(data.batteryDirectUse / 1000, 2),
      },
    ];

    if (data.hasEv) {
      details.push({
        name: t("overview.toEv"),
        value: roundToDecimals((data.evFromBattery ?? 0) / 1000, 2),
      });
    }

    return details;
  }, [data, t]);

  const evDetails = useMemo(() => {
    if (!data || !data.hasEv) {
      return [];
    }

    const details = [
      {
        name: t("overview.fromGrid"),
        value: roundToDecimals((data.evFromGrid ?? 0) / 1000, 2),
      },
    ];

    if (data.hasBattery) {
      details.push({
        name: t("overview.fromBattery"),
        value: roundToDecimals((data.evFromBattery ?? 0) / 1000, 2),
      });
    }

    if (data.hasPvInstallation) {
      details.push({
        name: t("overview.fromPV"),
        value: roundToDecimals((data.evFromSolarPanels ?? 0) / 1000, 2),
      });
    }

    return details;
  }, [data, t]);

  return (
    <>
      {data && (
        <div>
          <div className="relative">
            <QuickGuideButton buttonLink="https://app.storylane.io/share/tj9tipu862nm"></QuickGuideButton>
            <h1 className="text-3xl font-semibold text-primary">
              {t("common.details")}
            </h1>
          </div>

          <div className="">
            <div className="flex flex-col md:flex-row gap-4 mb-4 md:flex-wrap">
              <OverviewCard>
                <PieChart
                  data={consumptionDetails}
                  title={t("common.consumption")}
                  legend="bottom"
                  unit="kWh"
                ></PieChart>
              </OverviewCard>
              {data.hasPvInstallation && (
                <OverviewCard>
                  <PieChart
                    data={solarPanelsDetails}
                    title={t("overview.pvProduction")}
                    legend="bottom"
                    unit="kWh"
                  ></PieChart>
                </OverviewCard>
              )}
              {data.hasBattery && (
                <OverviewCard>
                  <PieChart
                    data={batteryDetails}
                    title={t("common.battery")}
                    legend="bottom"
                    unit="kWh"
                  ></PieChart>
                </OverviewCard>
              )}
              {data.hasEv && (
                <OverviewCard>
                  <PieChart
                    data={evDetails}
                    title={t("ev.ev")}
                    legend="bottom"
                    unit="kWh"
                  ></PieChart>
                </OverviewCard>
              )}
            </div>
            <div className="flex flex-col md:flex-row md:flex-wrap gap-4">
              <OverviewExpertFromToGrid
                toGridEuro={data.soldToGridEur}
                toGridKWH={data.soldToGrid / 1000}
                formGridEuro={data.boughtFromGridEur}
                formGridKWH={data.boughtFromGrid / 1000}
              ></OverviewExpertFromToGrid>

              <OverviewExpertPeakShaving
                costOfConsumption={data.capacityTariffWithoutInstallation}
                costOfGridPower={data.capacityTariffWithInstallation}
              ></OverviewExpertPeakShaving>

              <OverviewExpertHouseholdConsumption
                householdConsumption={data.householdConsumption}
                householdConsumptionNoInstallation={
                  data.householdConsumptionCost
                }
                householdConsumptionNoInstallationStaticPrices={
                  data.householdConsumptionCostWithStaticPrices
                }
              ></OverviewExpertHouseholdConsumption>
              <OverviewExpertAveragePrices
                averageBuyingPrice={data.averageBuyingPrice}
                averageSellingPrice={data.averageSellingPrice}
              ></OverviewExpertAveragePrices>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
