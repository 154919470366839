import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { SelectItem } from "primereact/selectitem";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CalculatedDeviceAttributeFactor } from "../../queries/models/configuration/calculated-device-attribute-factor";
import { DeviceAttribute } from "../../queries/models/configuration/device-attribute";
import { Unit } from "../../utils/units";

export interface DeviceAttributeEditFormProps {
  value: DeviceAttribute;
  deviceAttributes: DeviceAttribute[];
  onChange: (v: DeviceAttribute) => void;
}

const unitOptionsExceptions: {
  [key in Unit]?: { exclude?: boolean; label?: string };
} = {
  [Unit.none]: {
    exclude: true,
    label: "none",
  },
};

const unitOptions: SelectItem[] = (Object.keys(Unit) as (keyof typeof Unit)[])
  .filter((x) => !unitOptionsExceptions[Unit[x]]?.exclude)
  .map((x) => ({
    label: unitOptionsExceptions[Unit[x]]?.label ?? `${x} [${Unit[x]}]`,
    value: Unit[x],
  }));

export function DeviceAttributeEditForm({
  value,
  deviceAttributes,
  onChange,
}: DeviceAttributeEditFormProps) {
  const { t } = useTranslation();
  const [name, setName] = useState(value.name);
  const [unit, setUnit] = useState<Unit>(value.unit as Unit);
  const [mqttTopic, setMqttTopic] = useState(value.mqttTopic);
  const [enirisNodeId, setEnirisNodeId] = useState(value.enirisNodeId);
  const [enirisField, setEnirisField] = useState(value.enirisField);
  const [enirisMeasurement, setEnirisMeasurement] = useState(
    value.enirisMeasurement
  );
  const [startDate, setStartDate] = useState(value.measurementStartDate);
  const [isCalculated, setIsCalculated] = useState(value.isCalculated);
  const [isHidden, setIsHidden] = useState(value.isHidden);
  const [isControlled, setIsControlled] = useState(value.isControlled);
  const [defaultControlValue, setDefaultControlValue] = useState(
    value.defaultControlValue
  );
  const [factors, setFactors] = useState<CalculatedDeviceAttributeFactor[]>(
    value.calculatedDeviceAttributeFactors
  );
  const [newFactorDataSourceId, setNewFactorDataSourceId] = useState<number>();

  useEffect(() => {
    if (isCalculated) {
      setMqttTopic("");
      setEnirisNodeId("");
    } else {
      setFactors([]);
    }
  }, [isCalculated]);

  useEffect(() => {
    if (!isControlled) {
      setDefaultControlValue(undefined);
    }
  }, [isControlled]);

  useEffect(() => {
    value.name = name;
    value.unit = unit;
    value.mqttTopic = mqttTopic;
    value.isCalculated = isCalculated;
    value.isHidden = isHidden;
    value.calculatedDeviceAttributeFactors = factors;
    value.measurementStartDate = startDate;
    value.isControlled = isControlled;
    value.defaultControlValue = defaultControlValue;
    value.enirisNodeId = enirisNodeId;
    value.enirisField = enirisField;
    value.enirisMeasurement = enirisMeasurement;

    onChange(value);
  }, [
    name,
    unit,
    mqttTopic,
    isCalculated,
    isHidden,
    factors,
    startDate,
    value,
    isControlled,
    defaultControlValue,
    enirisNodeId,
    enirisField,
    enirisMeasurement,
    onChange,
  ]);

  const allowedDeviceAttributes = useMemo(() => {
    if (!deviceAttributes || !factors) {
      return [];
    }

    return deviceAttributes.filter(
      (a) => !factors.find((f) => f.dataSourceId === a.dataSourceId)
    );
  }, [deviceAttributes, factors]);

  function addComponent() {
    if (newFactorDataSourceId) {
      setFactors([
        ...factors,
        { factor: 1, dataSourceId: newFactorDataSourceId },
      ]);
      setNewFactorDataSourceId(undefined);
    }
  }

  function removeComponent(dataSourceId: number) {
    var index = factors.findIndex((f) => f.dataSourceId === dataSourceId);

    if (index !== -1) {
      factors.splice(index, 1);
      setFactors([...factors]);
    }
  }

  function setFactorValue(
    factor: CalculatedDeviceAttributeFactor,
    value: number
  ) {
    factor.factor = value;

    setFactors([...factors]);
  }

  return (
    <div className="flex flex-col grey-inputs" style={{ width: 600 }}>
      <label className="mt-2">{t("common.name")}</label>
      <InputText value={name} onChange={(v) => setName(v.target.value)} />
      <label className="mt-2">{t("common.unit")}</label>
      <Dropdown
        options={unitOptions}
        value={unit}
        onChange={(v) => setUnit(v.target.value)}
      />

      <label className="mt-2">{t("common.calculated")}</label>
      <InputSwitch
        checked={isCalculated}
        onChange={(v) => setIsCalculated(Boolean(v.target.value))}
      />
      {isCalculated && (
        <div className="ml-5">
          <h3>{t("configuration.calculatedDataSourceComponents")}:</h3>
          {factors.map((f) => (
            <div
              className="flex flex-row my-2 justify-between"
              key={f.dataSourceId}
            >
              <label>
                {
                  deviceAttributes.find(
                    (a) => a.dataSourceId === f.dataSourceId
                  )?.name
                }
              </label>
              <div className="flex flex-row">
                <InputNumber
                  value={f.factor}
                  onChange={(v) => setFactorValue(f, v.value ?? 0)}
                  showButtons
                  step={1}
                  size={6}
                />
                <Button
                  icon="pi pi-trash"
                  className="p-button-text p-button-danger"
                  onClick={() => removeComponent(f.dataSourceId)}
                />
              </div>
            </div>
          ))}
          <h3>Add component</h3>
          <div className="flex flex-row justify-between">
            <Dropdown
              options={allowedDeviceAttributes}
              optionValue="dataSourceId"
              optionLabel="name"
              className="shadow-md mr-2"
              value={newFactorDataSourceId}
              onChange={(v) => setNewFactorDataSourceId(v.target.value)}
            />
            <Button
              icon="pi pi-plus"
              label="Add component"
              onClick={addComponent}
              disabled={!newFactorDataSourceId}
              className="flex-shrink-0"
            />
          </div>
        </div>
      )}
      {!isCalculated && (
        <>
          <label className="mt-2">{t("configuration.mqttTopicAddress")}</label>
          <InputText
            value={mqttTopic}
            onChange={(v) => setMqttTopic(v.target.value)}
          />
          <label className="mt-2">Eniris Node ID</label>
          <InputText
            value={enirisNodeId}
            onChange={(v) => setEnirisNodeId(v.target.value)}
          />
          <label className="mt-2">Eniris Field</label>
          <InputText
            value={enirisField}
            onChange={(v) => setEnirisField(v.target.value)}
          />
          <label className="mt-2">Eniris Measurement</label>
          <InputText
            value={enirisMeasurement}
            onChange={(v) => setEnirisMeasurement(v.target.value)}
          />
        </>
      )}
      <label className="mt-2">{t("configuration.measurementStartDate")}</label>
      <Calendar
        value={startDate}
        selectionMode="single"
        readOnlyInput
        showButtonBar
        onChange={(v) => setStartDate(v.target.value as Date)}
      />
      <label className="mt-2">{t("configuration.isControlled")}</label>
      <InputSwitch
        checked={isControlled}
        onChange={(v) => setIsControlled(Boolean(v.target.value))}
      />

      {isControlled && (
        <>
          <label className="mt-2">
            {t("configuration.defaultControlValue")}
          </label>
          <InputNumber
            value={defaultControlValue}
            onChange={(v) => setDefaultControlValue(v.value ?? 0)}
            step={1}
          />
        </>
      )}
      <label className="mt-2">{t("common.hidden")}</label>
      <InputSwitch
        checked={isHidden}
        onChange={(v) => setIsHidden(Boolean(v.target.value))}
      />
    </div>
  );
}
