import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "primereact/tooltip";
import { useTranslation } from "react-i18next";

interface QuickGuideButtonProps {
  buttonLink: string;
}

export default function QuickGuideButton({
  buttonLink,
}: QuickGuideButtonProps) {
  const { t } = useTranslation();

  const [Visited, SetVisited] = useState(false);

  useEffect(() => {
    const hasVisited = localStorage.getItem("visitedPage") === "true";
    SetVisited(hasVisited);
    if (!hasVisited) {
      localStorage.setItem("visitedPage", "true");
    }
  }, []);

  return (
    <>
      <div className="absolute right-0 justify-end">
        <a target="_blank" rel="Nofollow Related noreferrer " href={buttonLink}>
          <Tooltip
            target=".bg-primary"
            style={{ zIndex: 1 }}
            className="hidden md:block"
          />
          <span
            data-pr-tooltip={t("common.quickGuide")}
            data-pr-position="left"
            className={`bg-primary ${
              Visited ? "" : "animate-bounce-1s"
            } rounded-full hover:shadow-sm hover:scale-110 flex w-8 h-8 justify-center items-center cursor-pointer`}
          >
            <FontAwesomeIcon icon={faQuestion} className="text-white" />
          </span>
        </a>
      </div>
    </>
  );
}
