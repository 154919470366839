import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { SelectItem } from "primereact/selectitem";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { EditUser } from "../../queries/models/users/edit-user";
import { User } from "../../queries/models/users/user";
import {
  useAddUserMutation,
  useDeleteUserMutation,
  useEditUserMutation,
} from "../../queries/users.query";
import { useToast } from "../ui/ToastContext";
import { UserEditForm } from "./UserEditForm";

export interface UserDialogProps {
  selectedUser?: User;
  setSelectedUser(user: User): void;
  visible: boolean;
  onHide(): void;
  installationOptions: SelectItem[];
  roleOptions: SelectItem[];
}

export function UserDialog({
  selectedUser,
  visible,
  installationOptions,
  roleOptions,
  onHide,
  setSelectedUser,
}: UserDialogProps) {
  const { t } = useTranslation();

  const [validForm, setValidForm] = useState<boolean>(false);

  const addUserMutation = useAddUserMutation();
  const editUserMutation = useEditUserMutation();
  const deleteUserMutation = useDeleteUserMutation();
  const queryClient = useQueryClient();

  const toast = useToast();

  const onSave = useCallback(() => {
    if (selectedUser && validForm) {
      confirmDialog({
        message: t("users.saveUserConfirmation"),
        header: t("common.confirmation"),
        icon: "pi pi-question-circle text-green-600",
        accept: () => {
          let editUser: EditUser = {
            email: selectedUser.email,
            roleId: selectedUser.role.id,
            installationIds: selectedUser.installations?.map((x) => x.id) ?? [],
          };

          const mutateOptions = {
            onSuccess: async () => {
              await queryClient.invalidateQueries("users");
              onHide();
              toast.current?.show({
                severity: "success",
                detail: t("common.saveSuccessful"),
              });
            },
            onError: async (error: any) => {
              toast.current?.show({
                severity: "error",
                detail: t(error.response?.data) ?? error.message,
              });
            },
          };

          if (selectedUser.id) {
            editUserMutation.mutateAsync(
              {
                id: selectedUser.id,
                user: editUser,
              },
              mutateOptions
            );
          } else {
            addUserMutation.mutateAsync(editUser, mutateOptions);
          }
        },
      });
    }
  }, [
    addUserMutation,
    editUserMutation,
    onHide,
    queryClient,
    selectedUser,
    t,
    toast,
    validForm,
  ]);

  const onDelete = useCallback(() => {
    if (selectedUser && selectedUser.id) {
      confirmDialog({
        message: t("users.deleteUserConfirmation"),
        header: t("common.confirmation"),
        icon: "pi pi-exclamation-triangle text-yellow-600",
        accept: () => {
          deleteUserMutation.mutateAsync(selectedUser.id, {
            onSuccess: async () => {
              await queryClient.invalidateQueries("users");
              onHide();
              toast.current?.show({
                severity: "success",
                detail: t("common.saveSuccessful"),
              });
            },
            onError: async (error: any) => {
              toast.current?.show({
                severity: "error",
                detail: t(error.response?.data) ?? error.message,
              });
            },
          });
        },
      });
    }
  }, [deleteUserMutation, onHide, queryClient, selectedUser, t, toast]);

  return (
    <>
      <Dialog
        className="max-w-full"
        visible={visible}
        header={t("users.user")}
        onHide={onHide}
      >
        {selectedUser && (
          <div className="flex flex-col">
            <UserEditForm
              user={selectedUser}
              onChange={setSelectedUser}
              installationOptions={installationOptions}
              roleOptions={roleOptions}
              onFormValidation={setValidForm}
            />
            <div className="flex">
              {selectedUser.id && (
                <Button
                  label={t("common.delete")}
                  className="p-button p-button-danger !mt-2 flex-1"
                  onClick={onDelete}
                />
              )}
              <Button
                label={t("common.save")}
                className="p-button p-button-success !mt-2 flex-1"
                disabled={!validForm}
                onClick={onSave}
              />
            </div>
          </div>
        )}
      </Dialog>
      <ConfirmDialog />
    </>
  );
}
