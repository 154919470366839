import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "primereact/tooltip";
import React from "react";

interface TooltipButtonProps {
  tooltipsMessage: string;
}

export function TooltipButton({ tooltipsMessage }: TooltipButtonProps) {
  return (
    <div className="absolute bottom-0 right-0">
      <Tooltip target=".bg-primary" style={{ zIndex: 1 }} />
      <span
        data-pr-tooltip={tooltipsMessage}
        data-pr-position="top"
        data-pr-classname="!text-lg p-2"
        className="bg-primary rounded-full hover:shadow-sm hover:scale-110 flex w-6 h-6 justify-center items-center cursor-pointer"
      >
        <FontAwesomeIcon icon={faQuestion} className="text-white text-xs" />
      </span>
    </div>
  );
}
