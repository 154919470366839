import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useChargersQuery } from "../../queries/charging-admin.query";
import { useInstallationSelector } from "../ui/InstallationContext";
import { Loader } from "../ui/Loader";

export function Chargers() {
  const { selectedInstallationId } = useInstallationSelector();
  const { data, isLoading } = useChargersQuery(selectedInstallationId);

  if (isLoading) {
    return (
      <div className="flex justify-center">
        <Loader spinner />
      </div>
    );
  }

  return (
    <div>
      {data && (
        <DataTable value={data}>
          <Column field="id" header="Id"></Column>
          <Column field="name" header="Name"></Column>
        </DataTable>
      )}
    </div>
  );
}
