import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { SelectItem } from "primereact/selectitem";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { User } from "../../queries/models/users/user";

export interface UserEditFormProps {
  user: User;
  onChange: (v: User) => void;
  installationOptions: SelectItem[];
  roleOptions: SelectItem[];
  onFormValidation: (v: boolean) => void;
}

export function UserEditForm({
  user,
  onChange,
  installationOptions,
  roleOptions,
  onFormValidation,
}: UserEditFormProps) {
  const { t } = useTranslation();

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    onFormValidation(formRef?.current?.checkValidity() ?? false);
  }, [onFormValidation, user]);

  return (
    <form className="flex flex-col max-w-full w-[600px]" ref={formRef}>
      <label className="mt-2">{t("sso.email")}</label>
      <InputText
        type="email"
        value={user.email ?? ""}
        onChange={(e) => onChange({ ...user, email: e.target.value })}
        required
      />

      <label className="mt-2">{t("users.role")}</label>
      <Dropdown
        value={user.role?.id}
        options={roleOptions}
        className="shadow-md"
        onChange={(e) => {
          onChange({
            ...user,
            role: {
              ...user.role,
              id: e.target.value,
            },
          });
        }}
        required
      />

      <label className="mt-2">{t("configuration.installations")}</label>
      <MultiSelect
        value={user.installations?.map((x) => x.id)}
        options={installationOptions}
        onChange={(e) => {
          onChange({
            ...user,
            installations: e.target.value.map((x: number) => ({ id: x })),
          });
        }}
      />
    </form>
  );
}
