import { Device } from "../queries/models/configuration/device";
import { DeviceAttribute } from "../queries/models/configuration/device-attribute";
import { Installation } from "../queries/models/configuration/installation";
import { InstallationToAdd } from "../queries/models/configuration/installation-to-add";
import { IOConfiguration } from "../queries/models/configuration/io-configuration";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class ConfigurationService extends BaseService {
  async getDevices(installationId: number): Promise<Device[]> {
    const url = `${this.endpointPath}/${installationId}/devices`;

    return this.http.get<Device[]>(url).then((res) => res.data);
  }

  async getDevicesForIO(installationId: number): Promise<Device[]> {
    const url = `${this.endpointPath}/${installationId}/io/devices`;

    return this.http.get<Device[]>(url).then((res) => res.data);
  }

  async getIOConfiguration(installationId: number): Promise<IOConfiguration> {
    const url = `${this.endpointPath}/${installationId}/io`;

    return this.http.get<IOConfiguration>(url).then((res) => res.data);
  }

  async saveIOConfiguration(
    installationId: number,
    configuration: IOConfiguration
  ): Promise<IOConfiguration> {
    const url = `${this.endpointPath}/${installationId}/io`;

    return this.http
      .post<IOConfiguration>(url, configuration)
      .then((res) => res.data);
  }

  addDeviceAttribute(
    installationId: number,
    deviceId: number,
    model: DeviceAttribute
  ): Promise<DeviceAttribute> {
    const url = `${this.endpointPath}/${installationId}/devices/${deviceId}/attributes`;

    return this.http.post<DeviceAttribute>(url, model).then((res) => res.data);
  }

  updateDeviceAttribute(
    installationId: number,
    deviceId: number,
    model: DeviceAttribute
  ): Promise<DeviceAttribute> {
    const url = `${this.endpointPath}/${installationId}/devices/${deviceId}/attributes/${model.id}`;

    return this.http.put<DeviceAttribute>(url, model).then((res) => res.data);
  }

  deleteDeviceAttribute(
    installationId: number,
    deviceId: number,
    id: number
  ): Promise<DeviceAttribute> {
    const url = `${this.endpointPath}/${installationId}/devices/${deviceId}/attributes/${id}`;

    return this.http.delete(url).then((res) => res.data);
  }

  getInstallations(): Promise<Installation[]> {
    const url = `${this.endpointPath}/installations`;

    return this.http.get(url).then((res) => res.data);
  }

  addInstallation(model: InstallationToAdd): Promise<Installation> {
    const url = `${this.endpointPath}/installations`;

    return this.http.post<Installation>(url, model).then((res) => res.data);
  }

  updateInstallation(model: Installation): Promise<Installation> {
    const url = `${this.endpointPath}/installations/${model.id}`;

    return this.http.put(url, model).then((res) => res.data);
  }
}

export const configurationService = new ConfigurationService(
  API_ENDPOINTS.CONFIGURATION
);
