import { InputTextarea, InputTextareaProps } from "primereact/inputtextarea";
import { useEffect, useRef, useState } from "react";

export function JsonInputTextarea(props: InputTextareaProps) {
  const [jsonError, setJsonError] = useState("");
  const jsonInputRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    function setCursor(
      textarea: HTMLTextAreaElement,
      line: number,
      column: number
    ) {
      const value = textarea.value;
      let index = 0;

      for (let i = 0; i < line - 1; i++) {
        index = value.indexOf("\n", index) + 1;
      }

      index += column;

      textarea.selectionStart = index;
      textarea.selectionEnd = index;
    }

    function extractPosition(
      message: string
    ): number | { line: number; column: number } | null {
      const chromiumMatch = message.match(/position (\d+)/);
      if (chromiumMatch) {
        return parseInt(chromiumMatch[1], 10);
      }

      const ffMatch = message.match(/at line (\d+) column (\d+)/);
      if (ffMatch) {
        return { line: parseInt(ffMatch[1]), column: parseInt(ffMatch[2]) };
      }

      return null;
    }

    function getLineAndColumn(
      input: string,
      skip: number
    ): { line: number; column: number } {
      let line = 1;
      let column = 0;

      for (let i = 0; i < skip; i++) {
        if (input[i] === "\n") {
          line++;
          column = 0;
        } else {
          column++;
        }
      }

      return { line, column };
    }

    try {
      JSON.parse(props.value as string);
      setJsonError("");
    } catch (error: any) {
      console.info({ error });
      const errorCharPosition = extractPosition(error.message);
      let errorPosition;

      if (errorCharPosition === null) {
        setJsonError(`JSON parsing error`);
        return;
      }

      if (typeof errorCharPosition === "number") {
        errorPosition = getLineAndColumn(
          props.value as string,
          errorCharPosition
        );
      } else {
        errorPosition = errorCharPosition;
        // Firefox sometimes reports error in weird place, not suitable for editing, so we alter it
        errorPosition.column = Math.max(0, errorPosition.column - 1);
      }

      setJsonError(
        `JSON parsing error at line ${errorPosition.line}, column ${errorPosition.column}`
      );

      if (jsonInputRef.current) {
        setCursor(
          jsonInputRef.current as any,
          errorPosition.line,
          errorPosition.column
        );
      }
    }
  }, [props.value]);

  return (
    <>
      <InputTextarea ref={jsonInputRef} {...props} />
      {Boolean(jsonError) && <div className="text-red-600">{jsonError}</div>}
    </>
  );
}
