import { useWindowSize } from "../../hooks/use-window-size";
import classNames from "classnames";
import { NodeTitleAndValue } from "./FlowNodeTitleAndValue";
import { NodeImage } from "./FlowNodeImage";
import { NodeBtnMoreInfo } from "./FlowNodeBtnMoreInfo";
import { NodeSetpoint } from "./FlowNodeSetpoint";

export interface EnergyFlowNodeProps {
  value?: number;
  unit?: string;
  name?: string;
  icon?: string;
  setpoint?: number;
  charge?: number;
  color?: string;
  isConnected?: boolean;
  isEV?: number;
  onSelect?: () => void;
}

export function EnergyFlowNode({
  value,
  unit = "W",
  name,
  icon,
  setpoint,
  charge,
  isConnected,
  isEV,
  onSelect,
}: EnergyFlowNodeProps) {
  const windowSize = useWindowSize();
  return (
    <div
      className={classNames(
        "group z-20 relative whitespace-nowrap m-2 rounded-3xl bg-white shadow-md hover:shadow-lg duration-100 cursor-pointer",
        windowSize.lg ? "text-xl min-w-max w-48 p-4 mx-4" : "text-sm p-3 m-0",
        onSelect ? "select-none hover:scale-105" : "shadow-md"
      )}
      onClick={onSelect}
    >
      {windowSize.lg ? (
        <div className="flex justify-center h-full overflow-hidden">
          {isConnected === !false ? (
            <div className="absolute inset-0 z-10 bg-white opacity-70 rounded-3xl"></div>
          ) : (
            <></>
          )}
          {setpoint != null && (
            <NodeSetpoint
              setpoint={setpoint}
              unit={unit}
              isConnected={isConnected}
              isEV={isEV}
            ></NodeSetpoint>
          )}

          {icon && <NodeImage isCenter={false} icon={icon} />}
          <div className="flex flex-col items-start">
            {name && value != null && (
              <NodeTitleAndValue
                name={name}
                unit={unit}
                value={value}
                charge={charge}
              ></NodeTitleAndValue>
            )}
            <NodeBtnMoreInfo />
          </div>
        </div>
      ) : (
        <div className="flex flex-col h-full">
          <div className="flex justify-center w-full">
            {setpoint != null && (
              <NodeSetpoint
                setpoint={setpoint}
                unit={unit}
                isConnected={isConnected}
                isEV={isEV}
              ></NodeSetpoint>
            )}
            {isConnected === true ? (
              <div className="absolute inset-0 z-10 bg-white opacity-70 rounded-3xl"></div>
            ) : (
              <></>
            )}
          </div>
          {name && value != null && (
            <NodeTitleAndValue
              name={name}
              unit={unit}
              value={value}
              charge={charge}
            ></NodeTitleAndValue>
          )}
          {icon && <NodeImage isCenter={false} icon={icon} />}
          <NodeBtnMoreInfo />
        </div>
      )}
    </div>
  );
}
