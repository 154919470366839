import { useQuery } from "react-query";
import { AdminChargerInfo } from "./models/public-charging/admin-charger-info.model";
import { chargingAdminService } from "../services/charging-admin.service";
import { ChargingUserInfo } from "./models/public-charging/charging-user-info.model";

export function useChargersQuery(installationId: number) {
  return useQuery<AdminChargerInfo[]>(
    ["admin-chargers", installationId],
    () => chargingAdminService.getChargers(installationId),
    { enabled: Boolean(installationId) }
  );
}

export function useUsersQuery(installationId: number) {
  return useQuery<ChargingUserInfo[]>(
    ["charging-users", installationId],
    () => chargingAdminService.getUsers(installationId),
    { enabled: Boolean(installationId) }
  );
}
