import { useEffect, useMemo, useState } from "react";
import { Calendar } from "primereact/calendar";
import { useWindowSize } from "../../hooks/use-window-size";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { ConsumptionPlanType } from "../../queries/models/consumption-plans/consumption-plan-type.enum";
import { SelectItem, SelectItemOptionsType } from "primereact/selectitem";
import { SelectButton } from "primereact/selectbutton";
import { ConsumptionPlanRepetitionType } from "../../queries/models/consumption-plans/consumption-plan-repetition-type.enum copy";
import { TimeSchedule } from "../../queries/models/consumption-plans/time-schedule.enum";
import { useTranslation } from "react-i18next";

const types: SelectItem[] = [
  { value: ConsumptionPlanType.High, label: "common.high" },
  { value: ConsumptionPlanType.Low, label: "common.low" },
  { value: ConsumptionPlanType.Usual, label: "common.usual" },
  { value: ConsumptionPlanType.Vacation, label: "common.vacation" },
];

const repetitionTypes: SelectItem[] = [
  { value: ConsumptionPlanRepetitionType.None, label: "consumptionPlans.once" },
  {
    value: ConsumptionPlanRepetitionType.WeeksOnWeekDays,
    label: "consumptionPlans.weeksOnWeekDays",
  },
];

const weekDaysOptions: SelectItemOptionsType = [
  { name: "common.mondayShort", value: TimeSchedule.Monday },
  { name: "common.tuesdayShort", value: TimeSchedule.Tuesday },
  { name: "common.wednesdayShort", value: TimeSchedule.Wednesday },
  { name: "common.thursdayShort", value: TimeSchedule.Thursday },
  { name: "common.fridayShort", value: TimeSchedule.Friday },
  { name: "common.saturdayShort", value: TimeSchedule.Saturday },
  { name: "common.sundayShort", value: TimeSchedule.Sunday },
];

function flagsToOptions(flags: TimeSchedule): number[] {
  let options: number[] = [];
  for (let i = 0; i < 7; i++) {
    const val = 1 << i;
    if ((val & flags) > 0) options.push(val);
  }
  return options;
}

function optionsToFlags(options: number[]): TimeSchedule {
  if (options.length === 0) return 0;
  return options.reduce((x, y) => x | y);
}

function isOnWeekDays(type: ConsumptionPlanRepetitionType): boolean {
  return (type & ConsumptionPlanRepetitionType.OnWeekDays) > 0;
}

export interface PlanDialogProps {
  type: ConsumptionPlanType;
  setType(type: ConsumptionPlanType): any;
  from?: Date;
  setFrom(from?: Date): any;
  to?: Date;
  setTo(to?: Date): any;
  repetitionType: ConsumptionPlanRepetitionType;
  setRepetitionType(repetitionType: ConsumptionPlanRepetitionType): any;
  repetitionValue: number;
  setRepetitionValue(repetitionValue: number): any;
  repetitionExpiration?: Date;
  setRepetitionExpiration(repetitionExpiration?: Date): any;
  repetitionWeekDays: TimeSchedule;
  setRepetitionWeekDays(repetitionWeekDays: TimeSchedule): any;
}

export function PlanDialogForm({
  type,
  setType,
  from = new Date(),
  setFrom,
  to = new Date(),
  setTo,
  repetitionType,
  setRepetitionType,
  repetitionValue,
  setRepetitionValue,
  repetitionExpiration,
  setRepetitionExpiration,
  repetitionWeekDays,
  setRepetitionWeekDays,
}: PlanDialogProps) {
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const [selectedWeekDays, setSelectedWeekDays] = useState<number[]>(
    flagsToOptions(repetitionWeekDays)
  );

  const typesTranslated: SelectItem[] = useMemo(
    () =>
      types.map(
        (x) => ({ label: t(x.label ?? ""), value: x.value } as SelectItem)
      ),
    [t]
  );
  const repetitionTypesTranslated: SelectItem[] = useMemo(
    () =>
      repetitionTypes.map(
        (x) => ({ label: t(x.label ?? ""), value: x.value } as SelectItem)
      ),
    [t]
  );
  const weekDaysOptionsTranslated: SelectItemOptionsType = useMemo(
    () =>
      weekDaysOptions.map((x) => ({ name: t(x.name ?? ""), value: x.value })),
    [t]
  );

  useEffect(() => {
    if (repetitionType === ConsumptionPlanRepetitionType.None) {
      if (repetitionValue !== 0) setRepetitionValue(0);
      if (repetitionExpiration !== undefined)
        setRepetitionExpiration(undefined);
    } else {
      if (repetitionValue < 1) setRepetitionValue(1);
    }

    if (!isOnWeekDays(repetitionType)) {
      if (repetitionWeekDays > 0) {
        setRepetitionWeekDays(0);
        setSelectedWeekDays([]);
      }
    }
  }, [
    repetitionExpiration,
    repetitionType,
    repetitionValue,
    repetitionWeekDays,
    setRepetitionExpiration,
    setRepetitionType,
    setRepetitionValue,
    setRepetitionWeekDays,
  ]);

  return (
    <>
      <div className="min-w-min mt-3 mx-6">
        <span>{t("common.dateRange")}</span>
        <Calendar
          value={to ? [from, to] : [from]}
          onChange={(e) => {
            if (Array.isArray(e.value)) {
              setFrom(e.value[0]);
              setTo(e.value[1]);
            }
          }}
          selectionMode="range"
          className="min-w-max w-full mt-2"
          inputClassName="shadow-sm"
          numberOfMonths={windowSize.lg ? 2 : 1}
          readOnlyInput
        />
      </div>
      <div className="min-w-min my-4 mx-6">
        <span>{t("logging.type")}</span>
        <div className="inline-block w-full">
          <Dropdown
            value={type}
            options={typesTranslated}
            className="shadow-sm mt-2"
            onChange={(e) => {
              setType(e.value);
            }}
          />
        </div>
      </div>
      <div className="border-t-[1px] my-7 mx-6"></div>

      <div className="bg-gray-100 px-2 mx-4 py-4 rounded-xl">
        <span className="mr-2">{t("consumptionPlans.repetition")}</span>
        <div className="inline-block lg:max-w-full w-full">
          <Dropdown
            value={repetitionType}
            options={repetitionTypesTranslated}
            className="shadow-sm mt-2"
            onChange={(e) => {
              setRepetitionType(e.value);
              if (!isOnWeekDays(repetitionType) && isOnWeekDays(e.value)) {
                const val = 1 << (from.getDay() + 6) % 7;
                setSelectedWeekDays([val]);
                setRepetitionWeekDays(val);
              }
            }}
          />
        </div>
        {repetitionType !== ConsumptionPlanRepetitionType.None && (
          <div>
            <div className="flex items-center">
              <div className="my-2">
                <span className="mr-2">
                  {t("consumptionPlans.repeatEvery")}:
                </span>
                <div className="inline-block my-2 max-w-min mr-1">
                  <InputNumber
                    value={repetitionValue ?? 1}
                    className="shadow-sm border"
                    inputClassName="w-12 text-center"
                    onChange={(e) => {
                      setRepetitionValue(e.value ?? 1);
                    }}
                    min={1}
                  />
                </div>
                <span>
                  {t(
                    {
                      [ConsumptionPlanRepetitionType.Days]: "common.day",
                      [ConsumptionPlanRepetitionType.Weeks]: "common.week",
                      [ConsumptionPlanRepetitionType.Months]: "common.month",
                      [ConsumptionPlanRepetitionType.OnWeekDays]: undefined,
                      [ConsumptionPlanRepetitionType.WeeksOnWeekDays]:
                        "common.week",
                      [ConsumptionPlanRepetitionType.MonthsOnWeekDays]:
                        "common.month",
                    }[repetitionType] + (repetitionValue === 1 ? "" : "s")
                  )}
                </span>
              </div>
            </div>

            {isOnWeekDays(repetitionType) && (
              <div className="mt-2 mb-6">
                <SelectButton
                  value={selectedWeekDays}
                  options={weekDaysOptionsTranslated}
                  onChange={(e) => {
                    setSelectedWeekDays(e.value);
                    setRepetitionWeekDays(optionsToFlags(e.value));
                  }}
                  optionLabel="name"
                  multiple
                />
              </div>
            )}

            <div className="flex my-3">
              <span>{t("consumptionPlans.repetitionExpirationDate")}:</span>
              <InputSwitch
                className="inline-block ml-3"
                checked={repetitionExpiration !== undefined}
                onChange={(e) => {
                  setRepetitionExpiration(e.value ? new Date() : undefined);
                }}
              />
            </div>

            <div className="my-2">
              {repetitionExpiration !== undefined && (
                <Calendar
                  showButtonBar
                  onTodayButtonClick={() => {
                    setFrom(new Date());
                  }}
                  value={repetitionExpiration}
                  onChange={(e) => {
                    if (!Array.isArray(e.value)) {
                      setRepetitionExpiration(
                        e.value ? new Date(e.value) : undefined
                      );
                    }
                  }}
                  className="my-2 min-w-max w-full"
                  inputClassName="shadow-sm"
                  numberOfMonths={windowSize.lg ? 2 : 1}
                  readOnlyInput
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
