import { useWindowSize } from "../../hooks/use-window-size";
import classNames from "classnames";
import { NodeImage } from "./FlowNodeImage";
import { NodeBtnMoreInfo } from "./FlowNodeBtnMoreInfo";

export interface EnergyFlowNodeProps {
  name?: string;
  icon: string;
  onSelect?: () => void;
}

export function EnergyFlowCenterNode({ icon, onSelect }: EnergyFlowNodeProps) {
  const windowSize = useWindowSize();
  return (
    <div
      className={classNames(
        "group z-20 relative whitespace-nowrap m-2 rounded-3xl bg-white shadow-md hover:shadow-lg duration-100 cursor-pointer",
        windowSize.lg ? "text-xl min-w-max w-48 p-4" : "text-sm p-3 m-0",
        onSelect ? "select-none hover:scale-105" : "shadow-md"
      )}
      onClick={onSelect}
    >
      <div>
        <NodeImage icon={icon} isCenter={true} />
        <NodeBtnMoreInfo />
      </div>
    </div>
  );
}
