import FullCalendar, { EventInput } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { useMemo, useState } from "react";
import {
  useGetConsumptionPlanQuery,
  useGetNonoverlappingConsumptionPlanQuery,
} from "../../queries/consumption-plans.query";
import { endOfMonth, startOfMonth } from "date-fns";
import interactionPlugin from "@fullcalendar/interaction";
import { classNames } from "primereact/utils";
import { subDays } from "date-fns";

import { PlanDialog } from "./PlanDialog";
import { ConsumptionPlanType } from "../../queries/models/consumption-plans/consumption-plan-type.enum";
import { useWindowSize } from "../../hooks/use-window-size";
import { useTranslation } from "react-i18next";

interface EventTypeStyles {
  backgroundColor: string;
  textColor?: string;
}

const typeStyles: { [key in ConsumptionPlanType]: EventTypeStyles } = {
  [ConsumptionPlanType.Usual]: {
    backgroundColor: "white",
    textColor: "#404040",
  },
  [ConsumptionPlanType.Low]: {
    backgroundColor: "#5D7",
    textColor: "#404040",
  },
  [ConsumptionPlanType.Vacation]: {
    backgroundColor: "#ACF",
    textColor: "#404040",
  },
  [ConsumptionPlanType.High]: {
    backgroundColor: "#FBA",
    textColor: "#404040",
  },
};

const typesNames: { [key in ConsumptionPlanType]: string } = {
  [ConsumptionPlanType.Usual]: "common.usual",
  [ConsumptionPlanType.Low]: "common.low",
  [ConsumptionPlanType.Vacation]: "common.vacation",
  [ConsumptionPlanType.High]: "common.high",
};

export interface ConsumptionPlanCalendarProps {
  installationId: number;
}

export function ConsumptionPlanCalendar({
  installationId,
}: ConsumptionPlanCalendarProps) {
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const [nodeDialog, setNodeDialog] = useState<boolean>(false);

  const [calendarMonth, setCalendarMonth] = useState(new Date());
  const [selectedEvent, setSelectedEvent] = useState<any>();

  const consumptionPlansQuery = useGetConsumptionPlanQuery(
    installationId,
    startOfMonth(calendarMonth),
    endOfMonth(calendarMonth)
  );

  const nonoverlappingConsumptionPlansQuery =
    useGetNonoverlappingConsumptionPlanQuery(
      installationId,
      startOfMonth(calendarMonth),
      endOfMonth(calendarMonth)
    );

  const calendarEvents: EventInput[] = useMemo(() => {
    const editable = consumptionPlansQuery.data?.map(
      (e) =>
        ({
          id: e.id?.toString(),
          title: t(typesNames[e.type]) + (e.comment ? ": " + e.comment : ""),
          start: e.from,
          end: e.to,
          type: e.type,
          backgroundColor: typeStyles[e.type]?.backgroundColor,
          textColor: typeStyles[e.type]?.textColor,
        } as EventInput)
    );
    const nonoverlapping = nonoverlappingConsumptionPlansQuery.data?.map(
      (e) =>
        ({
          start: e.from,
          end: e.to,
          type: e.type,
          backgroundColor: typeStyles[e.type]?.backgroundColor,
          display: "background",
          className: "cursor-auto",
        } as EventInput)
    );

    let result: EventInput[] = [];
    if (editable) result = result.concat(editable);
    if (nonoverlapping) result = result.concat(nonoverlapping);

    return result;
  }, [consumptionPlansQuery.data, nonoverlappingConsumptionPlansQuery.data, t]);

  return (
    <div className="w-full">
      <div>
        <FullCalendar
          firstDay={1}
          plugins={[dayGridPlugin, interactionPlugin]}
          viewClassNames={classNames(
            "duration-150",
            consumptionPlansQuery.isLoading && "blur-sm"
          )}
          initialView="dayGridMonth"
          height="80vh"
          events={calendarEvents}
          selectLongPressDelay={100}
          select={(e) => {
            setNodeDialog(true);
            setSelectedEvent({
              from: e.start,
              to: subDays(e.end, 1),
            });
          }}
          eventClick={(e) => {
            var selectedEvent = consumptionPlansQuery.data?.filter(
              (x) => x.id === +e.event.id
            )[0];
            if (!selectedEvent) return;
            selectedEvent.from = new Date(selectedEvent.from);
            selectedEvent.to = subDays(new Date(selectedEvent.to), 1);
            selectedEvent.repetitionExpiration =
              selectedEvent.repetitionExpiration
                ? subDays(new Date(selectedEvent.repetitionExpiration), 1)
                : undefined;
            setNodeDialog(true);
            setSelectedEvent(selectedEvent);
          }}
          datesSet={(e) => {
            setCalendarMonth(e.start);
          }}
          showNonCurrentDates={false}
          selectable
          defaultAllDay
          displayEventTime={false}
          eventBorderColor="#FFF0"
          eventClassNames={
            "shadow-sm hover:shadow-md transition duration-100 cursor-pointer p-1 px-2 mb-2"
          }
        />
      </div>
      <PlanDialog
        installationId={installationId}
        event={selectedEvent}
        isVisible={nodeDialog}
        onHide={() => {
          setNodeDialog(false);
          consumptionPlansQuery.refetch();
          nonoverlappingConsumptionPlansQuery.refetch();
        }}
      />

      <div
        className={classNames(
          "grid px-3 py-1 w-full",
          windowSize.lg ? "grid-cols-4" : "grid-cols-2"
        )}
      >
        {Object.keys(typeStyles).map((x, i) => (
          <div
            className="flex mb-1 sm:mt-2 mt-1 text-sm font-normal text-gray-700"
            key={i}
          >
            <div
              className="w-10 rounded border-[1px] border-solid border-gray-300 inline-block mr-2 h-full"
              style={{
                backgroundColor:
                  typeStyles[+x as ConsumptionPlanType]?.backgroundColor,
              }}
            ></div>
            {t(typesNames[+x as ConsumptionPlanType])}
          </div>
        ))}
      </div>
    </div>
  );
}
