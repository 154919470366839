import { useTranslation } from "react-i18next";
import { ShowFor } from "../ui/ShowFor";
import { SideBarElementDesktop } from "./side-bar-element-desktop";
import { MenuElement } from "./side-bar-nav";

export function SideBarElementsDesktop({
  menuElements,
}: {
  menuElements: MenuElement[];
}) {
  const { t } = useTranslation();
  return (
    <ul className="list-none align-self-center w-full">
      {menuElements.map((x, i) => (
        <ShowFor key={i} permission={x.permission}>
          <SideBarElementDesktop to={x.to} label={t(x.label)} icon={x.icon} />
        </ShowFor>
      ))}
    </ul>
  );
}
