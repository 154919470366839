import { DashboardHistoryData } from "../queries/models/dashboard/dashboard-history-data";
import { DashboardLatestData } from "../queries/models/dashboard/dashboard-latest-data";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class DashboardService extends BaseService {
  async getHistoryDashboardData(
    installationId: number
  ): Promise<DashboardHistoryData> {
    const url = `${this.endpointPath}/${installationId}`;

    return this.http.get<DashboardHistoryData>(url).then((res) => res.data);
  }

  async getLatestDashboardData(
    installationId: number
  ): Promise<DashboardLatestData> {
    const url = `${this.endpointPath}/${installationId}/latest`;

    return this.http.get<DashboardLatestData>(url).then((res) => res.data);
  }
}

export const dashboardService = new DashboardService(API_ENDPOINTS.DASHBOARD);
