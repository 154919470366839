import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { OverviewSummaryData } from "../../queries/models/overview/overview-summary-data.model";
import { roundToDecimals, formatNumber } from "../../utils/number-utils";
import { PieChart } from "../ui/PieCharts";
import { OverviewCard } from "./OverviewCard";
import OverviewCO2Savings from "./OverviewCO2Savings";
import OverviewPeakShaving from "./OverviewPeakShaving";
import OverviewTotal from "./OverviewTotal";
import QuickGuideButton from "../ui/QuickGuideButton";
import EVCharging from "./EVCharging";

export interface OverviewSummaryProps {
  data: OverviewSummaryData;
}

export function OverviewSummary({ data }: OverviewSummaryProps) {
  const { t } = useTranslation();
  const householdUsageData = useMemo(() => {
    return [
      {
        name: t("overview.fromGrid"),
        value: roundToDecimals(
          (data.householdPowerFromGrid + (data.evPowerFromGrid ?? 0)) / 1000.0,
          2
        ),
      },
      {
        name: t("overview.fromInstallation"),
        value: roundToDecimals(
          (data.householdPowerFromInstallation +
            (data.evPowerFromInstallation ?? 0)) /
            1000.0,
          2
        ),
      },
    ];
  }, [data, t]);

  return (
    <>
      {data && (
        <div className="mb-2">
          <div>
            <div className="">
              <div className="flex flex-col">
                <div className="mt-4 relative">
                  <QuickGuideButton buttonLink="https://app.storylane.io/share/cas2aqsyok5u"></QuickGuideButton>
                  <h1 className="text-3xl font-semibold text-primary">
                    {t("overview.summary")}
                  </h1>
                </div>
                <div className="flex flex-col-reverse md:flex-row-reverse md:flex-wrap gap-4 mb-4">
                  <div className="content-evenly flex flex-col justify-evenly 2xl:min-w-[40%]">
                    <OverviewTotal
                      evSavings={data.evSavingsEur}
                      allTimeSavings={data.allTimeSavings}
                      allTimeSavingsComparedToStaticPricesEur={
                        data.allTimeSavingsComparedToStaticPricesEur
                      }
                      totalSavingsComparedToStaticPricesEur={
                        (data.householdSavingsComparedToStaticPricesEur ?? 0) +
                        (data.evSavingsEur ?? 0)
                      }
                      householdSavingsComparedToStaticPricesEur={
                        data.householdSavingsComparedToStaticPricesEur
                      }
                      householdSavingsEur={data.householdSavingsEur}
                    ></OverviewTotal>
                    <OverviewCO2Savings
                      CO2={data.tonnesOfCO2SavedTotal}
                      trees={data.treesEquivalentTotal}
                    ></OverviewCO2Savings>
                    <OverviewPeakShaving
                      costOfConsumption={data.consumptionCapacityTariffCost}
                      costOfGridPower={data.gridPowerCapacityTariffCost}
                    ></OverviewPeakShaving>
                    {data.evSavingsEur != null && data.evSavingsKwh != null && (
                      <EVCharging
                        EvSavingsEur={data.evSavingsEur}
                        EvSavingsKwh={data.evSavingsKwh}
                      ></EVCharging>
                    )}
                  </div>
                  <OverviewCard>
                    <div className="flex flex-col justify-content-center !h-full justify-between">
                      <PieChart data={householdUsageData} unit="kWh"></PieChart>
                      <div className="flex text-lg m-4 text-gray-600">
                        <p>{t("overview.householdUsage")}</p>
                        <p className="pl-2 font-semibold">
                          {formatNumber(
                            (data.householdPowerFromGrid +
                              data.householdPowerFromInstallation +
                              (data.evPowerFromGrid ?? 0) +
                              (data.evPowerFromInstallation ?? 0)) /
                              1000.0
                          )}
                          kWh
                        </p>
                      </div>
                    </div>
                  </OverviewCard>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
