import { useMutation, useQuery } from "react-query";
import { configurationService } from "../services/configuration.service";
import { Device } from "./models/configuration/device";
import { DeviceAttribute } from "./models/configuration/device-attribute";
import { Installation } from "./models/configuration/installation";
import { IOConfiguration } from "./models/configuration/io-configuration";
import { InstallationToAdd } from "./models/configuration/installation-to-add";

export function useDevicesQuery(installationId: number | undefined) {
  return useQuery<Device[]>(
    ["devices", installationId],
    () => configurationService.getDevices(installationId!),
    { enabled: Boolean(installationId) }
  );
}

export function useIoDevicesQuery(installationId: number | undefined) {
  return useQuery<Device[]>(
    ["io-devices", installationId],
    () => configurationService.getDevicesForIO(installationId!),
    { enabled: Boolean(installationId) }
  );
}

export function useIOConfigurationQuery(installationId: number | undefined) {
  return useQuery<IOConfiguration>(
    ["io", installationId],
    () => configurationService.getIOConfiguration(installationId!),
    { enabled: Boolean(installationId) }
  );
}

export function useIOConfigurationMutation(installationId: number) {
  return useMutation((data: IOConfiguration) =>
    configurationService.saveIOConfiguration(installationId, data)
  );
}

export function useCreateDeviceAttributeMutation(
  installationId?: number,
  deviceId?: number
) {
  return useMutation((data: DeviceAttribute) =>
    configurationService.addDeviceAttribute(
      installationId ?? 0,
      deviceId ?? 0,
      data
    )
  );
}

export function useUpdateDeviceAttributeMutation(
  installationId?: number,
  deviceId?: number
) {
  return useMutation((data: DeviceAttribute) =>
    configurationService.updateDeviceAttribute(
      installationId ?? 0,
      deviceId ?? 0,
      data
    )
  );
}

export function useDeleteDeviceAttributeMutation(
  installationId?: number,
  deviceId?: number
) {
  return useMutation((id: number) =>
    configurationService.deleteDeviceAttribute(
      installationId ?? 0,
      deviceId ?? 0,
      id
    )
  );
}

export function useInstallationsQuery() {
  return useQuery<Installation[]>(["configuration-installations"], () =>
    configurationService.getInstallations()
  );
}

export function useCreateInstallationMutation() {
  return useMutation((data: InstallationToAdd) =>
    configurationService.addInstallation(data)
  );
}

export function useUpdateInstallationMutation() {
  return useMutation((data: Installation) =>
    configurationService.updateInstallation(data)
  );
}
