import { useMemo } from "react";
import { TimeChart, TimeChartData } from "../ui/TimeChart";
import { EnergyFlowNodeDefinition } from "./EnergyFlow";
import { useTranslation } from "react-i18next";
import { useHistoryDashboardQuery } from "../../queries/dashboard.query";
import { Loader } from "../ui/Loader";

export interface DashboardChartProps {
  nodes: EnergyFlowNodeDefinition[];
  installationId: number;
}

export function DashboardChart({ installationId, nodes }: DashboardChartProps) {
  const { t } = useTranslation();

  const historyDashboardQuery = useHistoryDashboardQuery(installationId, 30000);

  const timeChartData: TimeChartData[] = useMemo(() => {
    if (!historyDashboardQuery.data) return [];

    return nodes.map((node) => ({
      name: t(node.name ?? ""),
      points: node.historySelector!(historyDashboardQuery.data).map(
        (point) => ({
          date: point.date,
          value: point.value,
        })
      ),
      unit: node.historyUnit,
      color: node.color,
    }));
  }, [historyDashboardQuery.data, nodes, t]);

  if (
    historyDashboardQuery.isLoading ||
    (historyDashboardQuery.isFetched && historyDashboardQuery.isError)
  ) {
    return (
      <div className="flex h-full">
        <div className="m-auto">
          <Loader spinner />
        </div>
      </div>
    );
  }

  return <TimeChart data={timeChartData} />;
}
