export enum ConsumptionPlanRepetitionType {
  None = 0,
  Days = 1,
  Weeks = 2,
  Months = 3,

  OnWeekDays = 8,
  WeeksOnWeekDays = Weeks | OnWeekDays, // 10
  MonthsOnWeekDays = Months | OnWeekDays, // 11
}
