import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { ContentWithLabel } from "../../ui/ContentWithLabel";
import { EvConfiguration } from "../../../queries/models/configuration/ev-configuration";

interface EvConfigurationProps {
  configuration: EvConfiguration;
  showName: boolean;
  onChange: (v: EvConfiguration) => void;
}

export function EvConfigurationForm({
  configuration,
  showName = false,
  onChange,
}: EvConfigurationProps) {
  const { t } = useTranslation();
  const setConfigurationProperty: <T extends keyof EvConfiguration>(
    property: T,
    value: EvConfiguration[T]
  ) => void = (property, value) => {
    onChange({
      ...configuration!,
      [property]: value,
    });
  };

  return (
    <>
      {showName && (
        <ContentWithLabel label={t("common.name") + ":"}>
          <div className="p-inputgroup mb-3">
            <InputText
              value={configuration.name}
              onChange={(e) => setConfigurationProperty("name", e.target.value)}
            />
          </div>
        </ContentWithLabel>
      )}
      <ContentWithLabel label={`${t("ev.maxChargingPower")}:`}>
        <div className="p-inputgroup mb-3">
          <InputNumber
            value={configuration.maxChargingPower}
            min={0}
            defaultValue={0}
            onChange={(e) =>
              setConfigurationProperty("maxChargingPower", e.value!)
            }
          />
          <span className="p-inputgroup-addon">W</span>
        </div>
      </ContentWithLabel>
      <ContentWithLabel label={`${t("ev.capacity")}:`}>
        <div className="p-inputgroup mb-3">
          <InputNumber
            value={configuration.capacity}
            min={0}
            defaultValue={0}
            onChange={(e) => setConfigurationProperty("capacity", e.value!)}
          />
          <span className="p-inputgroup-addon">Wh</span>
        </div>
      </ContentWithLabel>
    </>
  );
}
