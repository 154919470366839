import { useState } from "react";
import { DevicesMultiSelect } from "../components/data-charts/DevicesMultiSelect";
import { DataAnalysisComponent } from "../components/data-charts/DataAnalysisComponent";
import { useInstallationSelector } from "../components/ui/InstallationContext";
import CalendarPicker from "../components/ui/CalendarPicker";
import QuickGuideButton from "../components/ui/QuickGuideButton";

export function DataCharts() {
  const { selectedInstallationId } = useInstallationSelector();
  const [dataSourcesIds, setDataSourcesIds] = useState<number[]>([]);

  const [dates, setDates] = useState<Date | Date[] | undefined>([
    new Date(),
    new Date(),
  ]);

  return (
    <div>
      <div className="relative">
        <div className="absolute top-3 right-3">
          <QuickGuideButton buttonLink="https://app.storylane.io/share/erryuylvohqj"></QuickGuideButton>
        </div>
        <CalendarPicker dates={dates} setDates={setDates}></CalendarPicker>
      </div>
      {selectedInstallationId && (
        <>
          <DevicesMultiSelect
            installationId={selectedInstallationId}
            selectedDataSources={dataSourcesIds}
            onSelectedDataSourceChange={setDataSourcesIds}
          />
          {dataSourcesIds.length !== 0 && Array.isArray(dates) && dates[0] && (
            <div className="w-full">
              <DataAnalysisComponent
                dataSourceIds={dataSourcesIds}
                from={dates[0]}
                to={dates[1] ?? dates[0]}
                installationId={selectedInstallationId}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
