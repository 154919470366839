import { useMemo, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import {
  useCreateDeviceAttributeMutation,
  useDeleteDeviceAttributeMutation,
  useDevicesQuery,
  useUpdateDeviceAttributeMutation,
} from "../queries/configuration.query";
import { commaSeparate } from "../utils/array-utils";
import { DeviceAttribute } from "../queries/models/configuration/device-attribute";
import clone from "just-clone";
import { DeviceAttributeEditForm } from "../components/configuration/DeviceAttributeEditForm";
import { useQueryClient } from "react-query";
import { ShowFor } from "../components/ui/ShowFor";
import { useInstallationSelector } from "../components/ui/InstallationContext";
import { useTranslation } from "react-i18next";
import { CalculatedDeviceAttributeFactor } from "../queries/models/configuration/calculated-device-attribute-factor";
import { useToast } from "../components/ui/ToastContext";
import { ConditionalCheckmark } from "../components/ui/ConditionalCheckmark";
import { set } from "date-fns";
import { Device } from "../queries/models/configuration/device";

export function Devices() {
  const { t } = useTranslation();
  const { selectedInstallationId } = useInstallationSelector();
  const [selectedDeviceId, setSelectedDeviceId] = useState<
    number | undefined
  >();
  const [selectedDeviceAttribute, setSelectedDeviceAttribute] = useState<
    DeviceAttribute | undefined
  >();
  const [deviceAttributeDialogVisible, setDeviceAttributeDialogVisible] =
    useState(false);

  const devicesQuery = useDevicesQuery(selectedInstallationId ?? 0);
  const deviceAttributeSaveMutation = useUpdateDeviceAttributeMutation(
    selectedInstallationId,
    selectedDeviceId
  );

  const deviceAttributeCreateMutation = useCreateDeviceAttributeMutation(
    selectedInstallationId,
    selectedDeviceId
  );

  const deviceAttributeDeleteMutation = useDeleteDeviceAttributeMutation(
    selectedInstallationId,
    selectedDeviceId
  );

  const queryClient = useQueryClient();

  const toast = useToast();

  const selectedDevice = useMemo(() => {
    return devicesQuery.data?.find((d) => d.id === selectedDeviceId);
  }, [devicesQuery.data, selectedDeviceId]);

  const deviceAttributesForDropdown = useMemo(() => {
    return (
      devicesQuery.data
        ?.flatMap((d) => d.attributes)
        .filter((a) => a.id !== selectedDeviceId) ?? []
    );
  }, [devicesQuery.data, devicesQuery.dataUpdatedAt, selectedDeviceId]);

  function onDeviceAttributeSelect(deviceAttribute: DeviceAttribute) {
    setSelectedDeviceAttribute(clone(deviceAttribute));
    setDeviceAttributeDialogVisible(true);
  }

  function showAddDeviceAttributeDialog() {
    setSelectedDeviceAttribute({
      name: "",
      unit: "",
      mqttTopic: "",
      enirisNodeId: "",
      enirisField: "",
      enirisMeasurement: "",
      measurementStartDate: set(new Date(), {
        minutes: 0,
        seconds: 0,
        milliseconds: 0,
      }),
      isCalculated: false,
      isHidden: false,
      calculatedDeviceAttributeFactors: [] as CalculatedDeviceAttributeFactor[],
    } as DeviceAttribute);

    setDeviceAttributeDialogVisible(true);
  }

  function hideDeviceAttributeDialog() {
    setSelectedDeviceAttribute(undefined);
    setDeviceAttributeDialogVisible(false);
  }

  async function saveDeviceAttribute() {
    if (selectedDeviceAttribute) {
      if (!selectedDeviceAttribute.id) {
        await deviceAttributeCreateMutation.mutateAsync(
          selectedDeviceAttribute
        );
        await queryClient.invalidateQueries("devices");
        hideDeviceAttributeDialog();
      } else {
        deviceAttributeSaveMutation.mutate(selectedDeviceAttribute, {
          onSuccess: async () => {
            await queryClient.invalidateQueries("devices");
            hideDeviceAttributeDialog();
          },
          onError: (error: any) => {
            console.info("Error!", error);
            toast.current?.show({
              severity: "error",
              detail: t(error.response?.data) ?? error.message,
            });
          },
        });
      }
    }
  }

  async function deleteDeviceAttribute(id: number) {
    confirmDialog({
      message: t("configuration.deleteDeviceAttributeConfirmation"),
      accept: () => doDeleteDeviceAttribute(id),
    });
  }

  async function doDeleteDeviceAttribute(id: number) {
    await deviceAttributeDeleteMutation.mutateAsync(id);
    queryClient.invalidateQueries("devices");
  }

  return (
    <div className="m-2">
      <div>
        <div>
          <h2>{t("configuration.devices")}</h2>
        </div>
        {!devicesQuery.isLoading && devicesQuery.data && (
          <div>
            <DataTable
              value={devicesQuery.data}
              selectionMode="single"
              selection={selectedDevice}
              onSelectionChange={(e) =>
                setSelectedDeviceId((e.value as Device | undefined)?.id)
              }
              dataKey="id"
            >
              <Column field="name" header={t("configuration.deviceName")} />
              <Column
                field="attributes"
                header={t("configuration.attributes")}
                body={(rowData) => commaSeparate(rowData.attributes, "name")}
              />
            </DataTable>
          </div>
        )}

        {selectedDevice && !devicesQuery.isLoading && (
          <div>
            <h2 className="text-lg mt-4">
              Device edit - {selectedDevice.name}
            </h2>
            <ShowFor permission="ConfigurationEdit">
              <div>
                <Button
                  label={t("configuration.addDeviceAttribute")}
                  className="p-button p-button-success !my-2 !mb-3"
                  onClick={showAddDeviceAttributeDialog}
                />
              </div>
            </ShowFor>
            <DataTable
              value={selectedDevice.attributes}
              selection={selectedDeviceAttribute}
              onRowSelect={(rowData) => onDeviceAttributeSelect(rowData.data)}
              selectionMode="single"
            >
              <Column field="id" header="Id" />
              <Column field="name" header={t("common.name")} />
              <Column field="unit" header={t("common.unit")} />
              <Column
                field="mqttTopic"
                header={t("configuration.mqttTopicAddress")}
              />
              <Column field="enirisNodeId" header="Eniris Node ID" />
              <Column field="enirisField" header="Eniris Field" />
              <Column field="enirisMeasurement" header="Eniris Measurement" />
              <Column
                header={t("common.calculated")}
                body={(rowData) => (
                  <ConditionalCheckmark show={rowData.isCalculated} />
                )}
              />
              <Column
                header={t("common.hidden")}
                body={(rowData) => (
                  <ConditionalCheckmark show={rowData.isHidden} />
                )}
              />
              <Column
                header=""
                body={(rowData) => (
                  <ShowFor permission="ConfigurationEdit">
                    <Button
                      icon="pi pi-trash"
                      className="p-button-secondary"
                      onClick={() => deleteDeviceAttribute(rowData.id)}
                    ></Button>
                  </ShowFor>
                )}
              />
            </DataTable>
          </div>
        )}
      </div>

      <Dialog
        visible={deviceAttributeDialogVisible}
        header={t("configuration.deviceAttribute")}
        onHide={() => hideDeviceAttributeDialog()}
      >
        {selectedDeviceAttribute && (
          <div className="flex flex-col">
            <DeviceAttributeEditForm
              value={selectedDeviceAttribute}
              deviceAttributes={deviceAttributesForDropdown}
              onChange={(v) => setSelectedDeviceAttribute(v)}
            />
            <ShowFor
              permission={["ConfigurationEdit", "DeleteDeviceAttribute"]}
            >
              <Button
                label={t("common.save")}
                className="p-button p-button-success !mt-2"
                onClick={saveDeviceAttribute}
              />
            </ShowFor>
          </div>
        )}
      </Dialog>
      <ConfirmDialog />
    </div>
  );
}
