import { useCallback, useMemo, useState } from "react";
import FullCalendar, {
  DateSelectArg,
  EventClickArg,
  EventContentArg,
  EventDropArg,
  EventInput,
  formatDate,
  FormatterInput,
} from "@fullcalendar/react";
import interactionPlugin, {
  DateClickArg,
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import { classNames } from "primereact/utils";
import {
  addHours,
  differenceInHours,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  endOfMonth,
  isSameDay,
  startOfMonth,
} from "date-fns";
import {
  useAllEvChargingSessionPlansQuery,
  useOverlappingEvChargingSessionsQuery,
} from "../../queries/ev-charging.query";
import { useInstallationSelector } from "../ui/InstallationContext";
import {
  EvChargingPlanDialog,
  EvChargingSessionEvent,
} from "./EvChargingPlanDialog";
import { useWindowSize } from "../../hooks/use-window-size";
import { InputSwitch } from "primereact/inputswitch";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMicrochip, faUser } from "@fortawesome/free-solid-svg-icons";
import { useIOConfigurationQuery } from "../../queries/configuration.query";
import { useChargersQuery } from "../../queries/ev-configuration.query";

/**
 * Changes 2 dates to string of date range "HH:mm - HH:mm"
 * leaving only parts of date that change in given range
 * @param start first date of range, empty results in "... - HH:mm"
 * @param end second date of range, empty results in "HH:mm - ..."
 * @returns date range string
 */
function formatDateRange(start?: Date | null, end?: Date | null): string {
  const s = start ? new Date(start) : new Date();
  const e = end ? new Date(end) : new Date();
  const dD = !isSameDay(s, e);
  const dW = differenceInWeeks(s, e) !== 0;
  const dM = differenceInMonths(s, e) !== 0;
  const dY = differenceInYears(s, e) !== 0;

  const format: FormatterInput = {
    hour: "2-digit",
    hour12: false,
    minute: "2-digit",
    weekday: dD && !dW && !dM && !dY ? "long" : undefined,
    day: dW || dM || dY ? "2-digit" : undefined,
    month: dM || dY ? "2-digit" : undefined,
    year: dY ? "numeric" : undefined,
  };

  return `${start ? formatDate(start, format) : "..."} - ${
    end ? formatDate(end, format) : "..."
  }`.replaceAll("24:", "00:");
}

export function EvChargingCalendar() {
  const { t } = useTranslation();
  const { selectedInstallationId } = useInstallationSelector();
  const windowSize = useWindowSize();

  const [showSkipPredictionZones, setShowSkipPredictionZones] = useState(false);

  const [calendarDateRange, setCalendarDateRange] = useState([
    startOfMonth(new Date()),
    endOfMonth(new Date()),
  ]);
  const { data: sessionsData, isLoading: sessionsLoading } =
    useOverlappingEvChargingSessionsQuery(
      selectedInstallationId,
      calendarDateRange[0],
      calendarDateRange[1]
    );
  const { data: allSessionPlansData, isLoading: allSessionPlansLoading } =
    useAllEvChargingSessionPlansQuery(
      selectedInstallationId,
      calendarDateRange[0],
      calendarDateRange[1]
    );

  const { data: configurationData, isLoading: configurationLoading } =
    useIOConfigurationQuery(selectedInstallationId);

  const { data: chargersData, isLoading: chargersLoading } = useChargersQuery(
    selectedInstallationId
  );

  const isLoading =
    sessionsLoading ||
    allSessionPlansLoading ||
    configurationLoading ||
    chargersLoading;

  const sessionsAsEvents: EventInput[] = useMemo(() => {
    return (
      sessionsData?.map<EventInput>((x) => ({
        id: x.id,
        start: x.startDate,
        end: x.endDate ?? new Date(),
        backgroundColor: "#0A4",
        borderColor: "#444",
        className:
          x.endDate === null
            ? "bg-gradient-to-b from-yellow-600 to-yellow-500"
            : "bg-gradient-to-b from-green-600 to-green-500",
        extendedProps: {
          reference: x,
        },
      })) ?? []
    );
  }, [sessionsData]);

  const plannedSessionsAsEvents: EventInput[] = useMemo(() => {
    return (
      allSessionPlansData
        ?.filter((x) => !x.removed)
        .map<EventInput>((x) => ({
          id: x.id.toString(),
          start: x.start,
          end: x.end,
          backgroundColor: "#0A49",
          borderColor: "#4449",
          classNames: [
            (x.predicted ? "opacity-50" : "opacity-80") +
              " bg-gradient-to-b from-green-600 to-green-400",
          ],
          editable: true,
          extendedProps: {
            reference: x,
          },
        })) ?? []
    );
  }, [allSessionPlansData]);

  const skipPredictionZonesAsEvents: EventInput[] = useMemo(() => {
    if (!showSkipPredictionZones) return [];
    return (
      allSessionPlansData
        ?.filter((x) => x.predicted === x.removed)
        .map<EventInput>((x) => ({
          id: x.id.toString(),
          start: x.start,
          end: x.end,
          display: "background",
          backgroundColor: "red",
          className: "!cursor-auto",
          extendedProps: {
            reference: x,
            isSkipZoneEvent: true,
          },
        })) ?? []
    );
  }, [allSessionPlansData, showSkipPredictionZones]);

  const [dialogVisible, setDialogVisible] = useState<boolean>(false);
  const [selectedEvent, setSelectedEvent] = useState<EvChargingSessionEvent>();

  const dialogAction = useCallback(
    (
      e:
        | EventClickArg
        | DateSelectArg
        | DateClickArg
        | EventDropArg
        | EventResizeDoneArg
    ) => {
      if (!configurationData || !chargersData) return;

      const defaultsInNewPlan = {
        startChargePercent: 0,
        minChargePercent: 90,
        id: undefined,
        removed: false,
        predicted: false,
        carId: configurationData.evConfigurations[0].id,
        chargerId: chargersData[0].id,
      } as const;

      let planEvent: EvChargingSessionEvent;

      if ("event" in e) {
        // event click / drag / resize
        planEvent = structuredClone(e.event.extendedProps.reference);
        if ("delta" in e || "startDelta" in e) {
          // event drag / resize
          if ("predicted" in planEvent) {
            // event is plan
            planEvent.end = e.event.end!;
            planEvent.start = e.event.start!;
          }
        }
      } else if ("date" in e) {
        // date click
        planEvent = {
          ...defaultsInNewPlan,
          start: e.date,
          end: addHours(e.date, 1),
        };
      } else {
        // date select
        planEvent = {
          ...defaultsInNewPlan,
          start: e.start,
          end: e.end,
        };
      }

      setSelectedEvent(planEvent);
      setDialogVisible(true);
    },
    [configurationData, chargersData]
  );

  function getEventLength(x: EventContentArg) {
    if (x.event.start === null || x.event.end === null) return 0;

    return differenceInHours(x.event.end, x.event.start);
  }

  function getEventContent(x: EventContentArg) {
    const evEvent = x.event.extendedProps?.reference as
      | EvChargingSessionEvent
      | undefined;

    if (x.event.extendedProps?.isSkipZoneEvent) return;

    const title =
      evEvent !== undefined && !("predicted" in evEvent)
        ? `${formatDateRange(evEvent.startDate, evEvent.endDate)} [${(
            evEvent.currentKwh - evEvent.startKwh
          ).toFixed(2)} kwh]`
        : formatDateRange(x.event.start, x.event.end);

    const eventLength = getEventLength(x);

    return (
      <div>
        {evEvent &&
          "predicted" in evEvent &&
          !evEvent.removed &&
          evEvent?.predicted && (
            <div className="absolute top-0 right-0 text-center flex gap-1 items-center">
              <span>AI</span>
              <FontAwesomeIcon
                icon={evEvent?.predicted ? faMicrochip : faUser}
                color="white"
                className="h-4"
              />
            </div>
          )}
        <div
          className={classNames(
            "absolute left-0 top-0 z-99 flex",
            eventLength <= 1.5 ? "flex-row gap-2" : "flex-col"
          )}
        >
          <div>{title}</div>
          {Boolean(evEvent) &&
            Boolean(configurationData) &&
            configurationData!.evConfigurations.length > 1 && (
              <div>
                {
                  configurationData!.evConfigurations.find(
                    (x) => x.id === evEvent!.carId
                  )?.name
                }
              </div>
            )}
          {Boolean(evEvent) &&
            Boolean(chargersData) &&
            chargersData!.length > 1 && (
              <div>
                {chargersData!.find((x) => x.id === evEvent!.chargerId)?.name}
              </div>
            )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="w-full">
        <FullCalendar
          plugins={[interactionPlugin, timeGridPlugin, dayGridPlugin]}
          initialView="timeGridThreeDays"
          views={{
            timeGridThreeDays: {
              type: "timeGrid",
              duration: { days: 3 },
              buttonText: "3 days",
            },
          }}
          datesSet={(x) =>
            setCalendarDateRange([startOfMonth(x.start), endOfMonth(x.end)])
          }
          viewClassNames={classNames("duration-150", isLoading && "blur-sm")}
          eventInteractive={true}
          height={windowSize.lg ? "90vh" : "100vh"}
          slotDuration={{
            hours: 1,
          }}
          allDaySlot={false}
          slotLabelFormat={{
            hour: "2-digit",
            hour12: false,
            minute: "2-digit",
          }}
          slotLabelContent={(info) => {
            info.text = info.text.replaceAll("24:", "00:");
          }}
          eventMinHeight={30}
          dayHeaderFormat={{
            weekday: "long",
            day: "2-digit",
            month: "long",
          }}
          nowIndicator
          displayEventTime={false}
          expandRows
          slotLaneClassNames={(x) => {
            return [
              (x.date?.getHours() ?? 0) % 2 ? "bg-[#CCC4]" : "",
              "hover:bg-[#DEF4]",
            ];
          }}
          selectable
          selectLongPressDelay={200}
          selectMirror
          slotEventOverlap={false}
          eventClassNames="p-2 cursor-pointer"
          eventBackgroundColor="#0A49"
          eventBorderColor="#4449"
          events={[
            ...sessionsAsEvents,
            ...plannedSessionsAsEvents,
            ...skipPredictionZonesAsEvents,
          ]}
          eventClick={dialogAction}
          select={dialogAction}
          dateClick={dialogAction}
          eventDrop={dialogAction}
          eventResize={dialogAction}
          eventResizableFromStart
          eventContent={getEventContent}
        />

        <div className="flex flex-row-reverse">
          <div className="flex items-center my-1">
            <div
              className="pr-1 cursor-pointer select-none"
              onClick={() => setShowSkipPredictionZones((v) => !v)}
            >
              {t("ev.showPredictionSkipZones")}
            </div>
            <InputSwitch
              checked={showSkipPredictionZones}
              onChange={(x) => setShowSkipPredictionZones(x.value ?? false)}
            />
          </div>
        </div>
      </div>

      <EvChargingPlanDialog
        installationId={selectedInstallationId}
        event={selectedEvent}
        isVisible={dialogVisible}
        onHide={() => {
          setDialogVisible(false);
        }}
      />
    </>
  );
}
