import React from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../../utils/number-utils";
import { OverviewCard } from "./OverviewCard";

interface OverviewCO2SavingsProps {
  trees: number;
  CO2: number;
}

export default function OverviewCO2Savings({
  trees,
  CO2,
}: OverviewCO2SavingsProps) {
  const { t } = useTranslation();
  return (
    <>
      <OverviewCard>
        <div className="flex flex-row items-center">
          <div>
            <img className="w-24 p-1" src="/images/trees.png" alt="img" />
          </div>
          <div className="flex flex-row items-center w-full">
            <div>
              <p className="text-xl font-semibold text-primary">
                {formatNumber(trees, 0)} {t("overview.trees")}
              </p>
              <p className="text-base text-gray-600 ">
                {formatNumber(CO2)} {t("overview.tonsOfCO2")}
              </p>
            </div>
            <div className="pl-2 ml-auto">
              <h2 className="text-primary text-xl font-semibold">
                {t("overview.savedInTotal")}
              </h2>
            </div>
          </div>
        </div>
      </OverviewCard>
    </>
  );
}
