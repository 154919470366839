import { ControlLoadedData } from "../components/control/ControlLoadedData";
import { useInstallationSelector } from "../components/ui/InstallationContext";

export function Control() {
  const { selectedInstallationId } = useInstallationSelector();

  return (
    <div className="m-2">
      {selectedInstallationId && (
        <ControlLoadedData installationId={selectedInstallationId} />
      )}
    </div>
  );
}
