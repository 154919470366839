import { useAuth } from "oidc-react";
import { useMemo } from "react";
import { Permissions } from "../utils/permissions";

export function usePermissions() {
  const auth = useAuth();

  function hasPermission(permission: Permissions) {
    return permissions.some((p) => p === permission);
  }

  const permissions = useMemo(() => {
    const permissionsString = auth.userData?.profile.permissions as string;

    if (!permissionsString) return [] as string[];

    return permissionsString.split(",");
  }, [auth.userData]);

  return { permissions, hasPermission };
}
