import { useMutation, useQuery } from "react-query";
import { controlService } from "../services/control.service";
import { OptimizationResult } from "./models/control/optimization-result";

async function getOptimizationResult(installationId: number) {
  return await controlService.getOptimizationResult(installationId);
}

async function getSimulatedOptimizationResult(
  installationId: number,
  startDate: Date
) {
  return await controlService.getSimulatedOptimizationResult(
    installationId,
    startDate
  );
}

export function useControlQuery(installationId: number) {
  return useQuery<OptimizationResult>(["control", installationId], () =>
    getOptimizationResult(installationId)
  );
}

export function useSimulatedControlQuery(
  installationId: number,
  startDate: Date,
  enabled: boolean
) {
  return useQuery<OptimizationResult>(
    ["control", installationId, startDate],
    () => getSimulatedOptimizationResult(installationId, startDate),
    { enabled: enabled }
  );
}

export function useSetpointsQuery(installationId: number, enabled: boolean) {
  return useQuery(
    ["setpoints", installationId],
    () => controlService.getSetpoints(installationId),
    { enabled: enabled }
  );
}

export function useChargersQuery(installationId: number) {
  return useQuery(
    ["cntrol-chargers", installationId],
    () => controlService.getChargers(installationId),
    { enabled: Boolean(installationId) }
  );
}

export function useCustomSetpointMutation() {
  return useMutation(
    (data: {
      installationId: number;
      deviceAttributeId: number;
      setpoint: number;
    }) =>
      controlService.setCustomSetpoint(
        data.installationId,
        data.deviceAttributeId,
        data.setpoint
      )
  );
}
