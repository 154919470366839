import { Dropdown } from "primereact/dropdown";
import { useInstallationsQuery } from "../../queries/data-analysis.query";

export interface InstallationSelectorProps {
  selectedInstallationId: number;
  onInstallationIdChange: (installationId: number) => void;
}
export function InstallationSelector({
  selectedInstallationId,
  onInstallationIdChange,
}: InstallationSelectorProps) {
  const { data } = useInstallationsQuery();

  return (
    <div>
      {data && (
        <Dropdown
          options={data}
          onChange={(e) => onInstallationIdChange(e.value)}
          value={selectedInstallationId}
          optionLabel="name"
          optionValue="id"
          className="mr-2 !w-56 shadow-md"
        />
      )}
    </div>
  );
}
